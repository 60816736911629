/**
 * The order of imports here is important, since the regular vehicles (LB, PB etc..)
 * are "catch alls", where as RV/CARAVAN are of LB/PB types but with special codes
 * Do not modify this import order if you don't understand the implications.
 */
import rvVehicle from "./rvVehicle";
import caravanVehicle from "./caravanVehicle";
import personalVehicle from "./personalVehicle";
import veteranVehicle from "./veteranVehicle";
import lightTruckVehicle from "./lightTruckVehicle";
import motorcycleVehicle from "./motorcycleVehicle";
import wagonVehicle from "./wagonVehicle";
import notCoveredVehicle from "./notCoveredVehicle";

const vehicleObject = {
  caravanVehicle,
  rvVehicle,
  veteranVehicle,
  lightTruckVehicle,
  motorcycleVehicle,
  wagonVehicle,
  personalVehicle,
  notCoveredVehicle,
};

export default vehicleObject;
