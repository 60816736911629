import { fromJS, Set } from "immutable";
import { userConstants } from "../constants";
import { updateMap } from "./functions";

const {
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  FETCH_USER_VEHICLE_DATA_REQUEST,
  FETCH_USER_VEHICLE_DATA_SUCCESS,
  FETCH_USER_VEHICLE_DATA_FAILURE,
  UPDATE_USER_VEHICLE_DATA_REQUEST,
  UPDATE_USER_VEHICLE_DATA_SUCCESS,
  UPDATE_USER_VEHICLE_DATA_FAILURE,
  FETCH_USER_FELLOW_APPLICANTS_DATA_REQUEST,
  FETCH_USER_FELLOW_APPLICANTS_DATA_SUCCESS,
  FETCH_USER_FELLOW_APPLICANTS_FAILURE,
} = userConstants;

const initialState = fromJS({
  updating: {},
  user: {},
  fellowApplicantsIds: Set(),
  fellowApplicants: {},
  registeredVehiclesIds: Set(),
  customRegisteredVehiclesIds: Set(),
  registeredVehiclesTypes: Set(),
  registeredVehicles: {},
  statusMessage: {},
  keyTabs: [],
  error: "",
});

const user = (state = initialState, action) => {
  const {
    userData,
    vehicles,
    vehicle,
    statusMessage,
    error,
    type,
    keyTabs,
    fellowApplicants,
    regNo,
  } = action;
  let newState = state;
  switch (type) {
    case GET_USER_DATA_REQUEST:
      return updateMap(newState, "updating", "fetchUser", true);
    case UPDATE_USER_DATA_REQUEST:
      return updateMap(newState, "updating", "updateProfile", true);
    case FETCH_USER_VEHICLE_DATA_REQUEST:
      return updateMap(newState, "updating", "fetchVehicles", true);
    case UPDATE_USER_VEHICLE_DATA_REQUEST:
      return updateMap(newState, "updating", "updateVehicles", true);
    case FETCH_USER_FELLOW_APPLICANTS_DATA_REQUEST:
      return updateMap(newState, "updating", "fetchUserFellowApplicants", true);
    case GET_USER_DATA_SUCCESS:
      newState = updateMap(newState, "updating", "fetchUser", false);
      newState = newState.set("user", fromJS(userData));
      newState = newState.set("keyTabs", userData.keyTabs, true);
      return newState;
    case UPDATE_USER_DATA_SUCCESS:
      newState = updateMap(newState, "updating", "updateProfile", false);
      newState = updateMap(
        newState,
        "statusMessage",
        "updateUser",
        statusMessage
      );
      Object.keys(userData).forEach((key) => {
        const value = userData[key];
        newState = updateMap(newState, "user", key, value);
      });
      return newState;
    case FETCH_USER_VEHICLE_DATA_SUCCESS:
      vehicles.forEach((vehicle) => {
        newState = newState.update("registeredVehiclesIds", (idList) =>
          idList.add(vehicle.data.regno)
        );
        newState = newState.update("registeredVehiclesTypes", (idList) =>
          idList.add(vehicle.type)
        );
        newState = updateMap(
          newState,
          "registeredVehicles",
          vehicle.data.regno,
          vehicle
        );
      });
      newState = updateMap(newState, "updating", "fetchVehicles", false);
      return newState;
    case UPDATE_USER_VEHICLE_DATA_SUCCESS:
      newState = newState.update("registeredVehiclesIds", (idList) =>
        idList.add(vehicle.data.regno)
      );
      newState = newState.update("registeredVehiclesTypes", (idList) =>
        idList.add(vehicle.type)
      );
      newState = updateMap(
        newState,
        "registeredVehicles",
        vehicle.data.regno,
        vehicle
      );
      newState = updateMap(newState, "updating", "updateVehicles", false);
      return newState;
    case FETCH_USER_FELLOW_APPLICANTS_DATA_SUCCESS:
      newState = updateMap(newState, "updating", "fellowApplicants", false);
      action.fellowApplicants.forEach((fellowApplicant) => {
        const { socialNumber } = fellowApplicant;
        newState = newState.update("fellowApplicantsIds", (idList) =>
          idList.add(`${socialNumber}`)
        );
        newState = updateMap(
          newState,
          "fellowApplicants",
          socialNumber,
          fellowApplicant
        );
      });

      return newState;
    case FETCH_USER_VEHICLE_DATA_FAILURE:
      newState = updateMap(newState, "updating", "fetchVehicles", false);
      newState = newState.set("error", error);
      return newState;
    case UPDATE_USER_VEHICLE_DATA_FAILURE:
      newState = updateMap(newState, "updating", "updateVehicles", false);
      // todo: add additional state to handle custom vehicle regNo
      newState = newState.update("customRegisteredVehiclesIds", (idList) =>
        idList.add(regNo)
      );
      return newState;
    case GET_USER_DATA_FAILURE:
      newState = updateMap(newState, "updating", "fetchUser", false);
      newState = newState.set("error", error);
      return newState;
    case UPDATE_USER_DATA_FAILURE:
      newState = updateMap(newState, "updating", "updateProfile", false);
      newState = newState.set("error", statusMessage);
      return newState;
    case FETCH_USER_FELLOW_APPLICANTS_FAILURE:
      newState = updateMap(
        newState,
        "updating",
        "fetchUserFellowApplicants",
        false
      );
      newState = newState.set("error", error);
      return newState;
    default:
      return newState;
  }
};

export default user;
