import { vehicleConstants } from "../../../../../constants";

const { VETERAN_VEHICLE, PERSONAL_VEHICLE } = vehicleConstants;

export default {
  types: ["V01", "VS"],
  coverage: [VETERAN_VEHICLE, PERSONAL_VEHICLE],
  isCoInsurable: true,
  recommendationTitle: "Vägassistans Veteran & Bil",
  recommendationText:
    "Teckna vår Vägassistans Bil så får du ett personligt försäkringsskydd vilket betyder att vi hjälper dig oavsett om du kör din egen bil, vännens bil eller en hyrbil på vinterresan. Genom att kombinera flera försäkringar hos oss får du som kund 30% rabatt på den totala försäkringspremien.",
};
