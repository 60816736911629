import { env } from "../helpers/config";
import base_api from "./themes/api";

function getMyBaseURLfromWindow() {
  return `${window.location.protocol}//${window.location.host}`;
}

const base_config = base_api;
const my_base_url = getMyBaseURLfromWindow();
let bucketUrl =
  "https://s3.eu-central-1.amazonaws.com/minasidor.prod.claim-images";

if (env.isDevelopment()) {
  console.log("Load api config", base_config);
  bucketUrl =
    "https://s3.eu-central-1.amazonaws.com/minasidor.dev.claim-images";
}

const config = {
  MY_BASE_URL: my_base_url,
  WP_BASE_URL: base_config.WP,
  WP_URL: `${base_config.WP}/wp-json/wp/v2`,
  WP_CUSTOM_FIELDS_URL: `${base_config.WP}/wp-json/acf/v3`,
  SUCCESS_CODE_200: 200,
  SUCCESS_CODE_201: 201,
  WP_ASSETS: `${base_config.WP}/wp-content/themes/wp_assistansbolaget/assets/img`,
  ETRACK_URL: base_config.ETRACK,
  POLICY_LINK: base_config.POLICY_LINK,
  AG_AB: "https://ag.assistansbolaget.nu",
  NETS: {
    // todo: These should be from config
    NETS_ACCEPT: `${my_base_url}/teckna/minasidor/accept`,
    NETS_CANCEL: `${my_base_url}/teckna/minasidor/cancel`,
    COMPANY_ACCOUNT_NUMBER: "60010514048",
    NETS_ENDPOINT: `https://pvu.nets.no/ecsa/start`,
    NETS_RETURN_URL: (acceptUrl, rejectUrl, paymentPeriod, customerCode) =>
      `${base_config.API}/nets/callback?acceptUrl=${escape(
        acceptUrl
      )}&rejectUrl=${escape(rejectUrl)}&customerCode=${customerCode}&country=${
        base_config.COUNTRY_CODE
      }&paymentPeriod=${paymentPeriod}`,
  },
  AUTH_API: {
    BANKID: {
      AUTHENTICATE: `${base_config.API}/v1/bankid,auth`,
      SIGN: `${base_config.API}/v1/bankid,sign`,
      COLLECT: `${base_config.API}/v1/bankid,collect`,
      CANCEL: `${base_config.API}/v1/bankid,cancel`,
    },
  },
  API: {
    LOGIN: {
      REQUEST_SMS_TOKEN: `${base_config.API}/login/requestsmstoken?country=${base_config.COUNTRY_CODE}`,
      LOGIN_WITH_SMS_TOKEN: `${base_config.API}/login/loginwithsmstoken?country=${base_config.COUNTRY_CODE}`,
      TOKEN: `${base_config.API}/login/token`,
    },
    SMS: {
      SEND_APP_LINK: `${base_config.API}/sms/sendapplink?country=${base_config.COUNTRY_CODE}`,
    },
    CAMPAIGN: {
      VALIDATE_CAMPAIGN_CODE: (campaignCode) =>
        `${base_config.API}/campaign/${campaignCode}?country=${base_config.COUNTRY_CODE}`,
    },
    USER: {
      RELATIONS: `${base_config.API}/User/relations`,
      INSURANCES: `${base_config.API}/User/insurances?country=${base_config.COUNTRY_CODE}`,
      COINSURANCES: `${base_config.API}/User/coinsurances?country=${base_config.COUNTRY_CODE}`,
      ORDER_KEY_TABS: `${base_config.API}/User/orderkeytabs`,
      LOGOUT: `${base_config.API}/User/logout`,
      ME: `${base_config.API}/User/me`,
      FELLOW_APPLICANTS: `${base_config.API}/User/fellowApplicants`,
      POLICY_DATA: `${base_config.API}/User/insurancepolicydata?orderNumber=`,
      INSURANCE_POLICY: `${base_config.API}/User/insurancepolicy?orderNumber=`,
      LOOKUP_VEHICLE(customerCode) {
        return `${base_config.API}/User/lookup/${customerCode}/vehicles?country=${base_config.COUNTRY_CODE}`;
      },
      /**
       * @param {Number} paymentStatus Status - 0 = Ej Betald
       */
      INVOICES(paymentStatus) {
        let query = "";
        if (paymentStatus === 0) {
          query += "?paymentStatus=Ej%20betald";
        }
        return `${base_config.API}/User/invoices${query}`;
      },
    },
    SCRIVE: {
      ACCEPT: `${base_config.API}/Scrive/accept`,
      REJECT: `${base_config.API}/Scrive/reject`,
      SIGN: `${base_config.API}/Scrive/sign?country=${base_config.COUNTRY_CODE}`,
    },
    INSURANCE: {
      CLAIM: `${base_config.API}/insurance/claim-insurance`,
      PRICEINFO: (articleCode, to) =>
        `${base_config.API}/insurance/priceinfo?country=${base_config.COUNTRY_CODE}&articleCode=${articleCode}&to=${to}`,
      VEHICLE: (regnr) =>
        `${base_config.API}/vehicle/cw-vehicle-insurance/${regnr}/`,
      FILE_UPLOAD_URL: `${base_config.API}/file/upload`,
      LOOKUP_VEHICLE(regNo) {
        return `${base_config.API}/Vehicle/lookup/${regNo}?country=${base_config.COUNTRY_CODE}`;
      },
      KLARNA_START_SESSION: `${base_config.API}/v1/klarna-open-banking-requests,start-account-details-flow`,
      KLARNA_FETCH_DATA: (sessionId, flowId) =>
        `${base_config.API}/v1/klarna-open-banking-requests,fetch-data?sessionId=${sessionId}&flowId=${flowId}`,
      CHECK_ENDABLE_INSURANCES: `${base_config.API}/user/active-insurances`,
      CONTROL_END_INSURANCES: `${base_config.API}/user/control-end-insurance`,
      TERMINATE_INSURANCE_REASONS: `${base_config.API}/user/terminate-reasons`,
      END_INSURANCES: `${base_config.API}/user/end-insurances`,
    },
  },
  AWS_BUCKET: bucketUrl,
};

export default config;
