import { vehicleConstants } from "../../../../../constants";

const { MOTORCYCLE } = vehicleConstants;

export default {
  articleCode: "300002",
  shadowType: "SJF300002",
  types: ["SJF"],
  coverage: [MOTORCYCLE],
  isCoInsurable: false,
};
