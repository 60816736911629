import insurances from "../../services/handlers/insurances/types/insurances";
import { insuranceTypeConstants, vehicleConstants } from "../../constants";
import { insuranceSelectors } from "../../selectors";

const { getInsuranceIcon } = insuranceSelectors;
const {
  INSURANCE_CAR_TYPE,
  INSURANCE_LIGHT_TRUCK_TYPE,
  INSURANCE_VETERAN_CAR_TYPE,
  INSURANCE_MC_TYPE,
  INSURANCE_CARAVAN_TYPE,
  INSURANCE_MOBILE_HOME_TYPE,
  INSURANCE_TRAILER_TYPE,
} = insuranceTypeConstants;
const {
  PERSONAL_VEHICLE,
  VETERAN_VEHICLE,
  LIGHT_TRUCK,
  MOTORCYCLE,
  WAGON_VEHICLE,
  CARAVAN_VEHICLE,
  RV_VEHICLE,
} = vehicleConstants;

export function findInsuranceTypeByVehicleType(vehicleType) {
  for (const key in insurances) {
    const insuranceType = insurances[key];
    if (
      insuranceType.coverage.includes(vehicleType) &&
      insuranceType.recommendationText
    ) {
      return insuranceType;
    }
  }
}

export function getInsuranceIconByVehicleType(vehicleType) {
  switch (vehicleType) {
    case PERSONAL_VEHICLE:
      return getInsuranceIcon(INSURANCE_CAR_TYPE);
    case LIGHT_TRUCK:
      return getInsuranceIcon(INSURANCE_LIGHT_TRUCK_TYPE);
    case VETERAN_VEHICLE:
      return getInsuranceIcon(INSURANCE_VETERAN_CAR_TYPE);
    case MOTORCYCLE:
      return getInsuranceIcon(INSURANCE_MC_TYPE);
    case CARAVAN_VEHICLE:
      return getInsuranceIcon(INSURANCE_CARAVAN_TYPE);
    case RV_VEHICLE:
      return getInsuranceIcon(INSURANCE_MOBILE_HOME_TYPE);
    case WAGON_VEHICLE:
      return getInsuranceIcon(INSURANCE_TRAILER_TYPE);
    default:
      return "";
  }
}

export function findInsuranceForVehicle(userInsurances, vehicle) {
  if (!vehicle.get("isInsurable")) {
    return false;
  }

  let activeInsurance = null;
  const vehicleType = vehicle.get("type");
  userInsurances.some((userInsurance) =>
    userInsurance.some((insurance) => {
      if (
        insurance.get("isActive") &&
        !insurance.get("articleType").includes("SJF")
      ) {
        if (insurance.get("coverage").includes(vehicleType)) {
          activeInsurance = insurance;
          return true;
        }
        return false;
      }
      return false;
    })
  );
  return activeInsurance;
}

export function findDeductibleForVehicle(userInsurances, vehicle) {
  if (!vehicle.get("isInsurable")) {
    return false;
  }
  const vehicleType = vehicle.get("type");
  let deductibleInsurance = null;
  userInsurances.some((userInsurance) =>
    userInsurance.some((insurance) => {
      if (
        insurance.get("isActive") &&
        insurance.get("articleType").includes("SJF")
      ) {
        if (insurance.get("coverage").includes(vehicleType)) {
          deductibleInsurance = insurance;
          return true;
        }
        return false;
      }
      return false;
    })
  );
  return deductibleInsurance;
}

export function isVehicleTypeInsured(userInsurances, vehicleType) {
  const vehicle = new Map([
    ["isInsurable", true],
    ["type", vehicleType],
  ]);
  return !!findInsuranceForVehicle(userInsurances, vehicle);
}
