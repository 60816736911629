import { fromJS } from "immutable";
import { FETCH_ERROR_AB_SUCCESS } from "../actions/abError.actions";

const initialState = fromJS({
  errors: {},
});

const abError = (state = initialState, action) => {
  const { data, type } = action;
  let newState = state;
  switch (type) {
    case FETCH_ERROR_AB_SUCCESS:
      newState = newState.set("errors", data);
      return newState;

    default:
      return newState;
  }
};

export default abError;
