import classNames from "classnames";
import React from "react";
import ListComponent from "../helper/ListComponent";
import ShoppingCardContainer from "./ShoppingCardContainer";

function ShopList({ itemIds }) {
  return (
    <div className={classNames("container")}>
      <ListComponent
        ids={itemIds}
        className="shop-items"
        component={ShoppingCardContainer}
        direction="column"
        isInsuranceCard={Boolean(true)}
      />
    </div>
  );
}

export default ShopList;
