const holder = {};

export function formatString(text, ...args) {
  const regex = /{.[a-zA-Z_-]*}/;
  let returnString = text;
  for (let i = 0; i < args.length; i++) {
    returnString = returnString.replace(regex, args[i]);
  }
  return returnString;
}

function parseRegisterModuleLang(lang_file) {
  const sign_insurance = lang_file.sign_insurances;
  const { private_web } = lang_file;
  return {
    register: {
      actions: {
        campaign_code_error_empty_cart:
          sign_insurance.insurance_page.combo_box.empty_cart_text,
        campaign_code_error_cannot_combine:
          sign_insurance.insurance_page.combo_box
            .campaign_code_cannot_combine_text,
        campaign_code_error_no_result:
          sign_insurance.insurance_page.combo_box.campaign_code_invalid_text,
      },
      campaign_code: {
        field_label: sign_insurance.insurance_page.combo_box.campaign_code_text,
        add_button: {
          text: sign_insurance.insurance_page.combo_box.campaign_button.text,
        },
      },
      preview_register: {
        collapse_button: {
          is_collapsed_text:
            sign_insurance.insurance_page.combo_box.shrink_cart_text,
          not_collapsed_text:
            sign_insurance.insurance_page.combo_box.expand_cart_text,
        },
      },
      reducer: {
        mutual_exclusion: {
          exclude_veteran_text:
            sign_insurance.insurance_page.combo_box.exclusion_veteran_car_text,
          exclude_truck_text:
            sign_insurance.insurance_page.combo_box.exclusion_truck_car_text,
        },
        check_campaign_code: {
          invalid_code_text:
            sign_insurance.insurance_page.combo_box.campaign_code_error_text,
          campaign_code_invalid_insurance_text:
            sign_insurance.insurance_page.combo_box
              .campaign_code_invalid_insurance_text,
        },
      },
      register: {
        chosen_insurances_text:
          sign_insurance.insurance_page.combo_box.chosen_insurances_text,
      },
      register_buttons: {
        next_button: {
          standard_text:
            private_web.sign_insurance_page.steps.next_page_button.text,
          final_step_text:
            sign_insurance.payment_page.sign_insurance_button.text,
        },
        previous_button: {
          text: private_web.sign_insurance_page.steps.previous_page_button.text,
        },
      },
      register_item: {
        currency_per_month_text: lang_file.misc.payment_per_month_text,
      },
      register_summation: {
        currency_per_month_text: lang_file.misc.payment_per_month_text,
        campaign_code_text:
          sign_insurance.insurance_page.combo_box.campaign_code_text,
        combo_discount_text:
          sign_insurance.insurance_page.combo_box.combo_discount_text,
      },
      total: {
        total_text: sign_insurance.insurance_page.combo_box.cart_total_text,
        currency_per_month_text: lang_file.misc.payment_per_month_text,
      },
      notification: {
        insurance_period:
          sign_insurance.insurance_page.combo_box.insurance_period,
        notification_text:
          sign_insurance.insurance_page.combo_box.notification_text,
      },
    },
  };
}

function parseComponentModuleLang(lang_file) {
  const sign_insurance = lang_file.sign_insurances;
  return {
    card: {
      shopping_card: {
        price_month_text: lang_file.misc.payment_per_month_text,
        annual_fee_text:
          sign_insurance.insurance_page.insurance_display.annual_fee_text,
        currency_text: lang_file.misc.display_currency,
        currency_per_month_text: lang_file.misc.payment_per_month_text,
        currency_per_year_text: lang_file.misc.payment_per_year_text,
      },
      shopping_card_add_button: {
        add_text:
          sign_insurance.insurance_page.insurance_display.add_button.add_text,
        already_owned_text:
          sign_insurance.insurance_page.insurance_display.add_button
            .already_owned_text,
      },
      shopping_card_expandable: {
        insurances_terms_text:
          sign_insurance.insurance_page.insurance_display.insurances_terms_text,
      },
      shopping_card_read_more_button: {
        close_text:
          sign_insurance.insurance_page.insurance_display.expand_button
            .shrink_text,
        read_more_text:
          sign_insurance.insurance_page.insurance_display.expand_button
            .expand_text,
      },
    },
    form: {
      complete_insurance_form: {
        first_name_text: sign_insurance.user_info_page.form.first_name,
        last_name_text: sign_insurance.user_info_page.form.last_name,
        address_text: sign_insurance.user_info_page.form.address,
        zipcode_text: sign_insurance.user_info_page.form.zipcode,
        city_text: sign_insurance.user_info_page.form.city,
        telephone_number_text:
          sign_insurance.user_info_page.form.telephone_number,
        email_text: sign_insurance.user_info_page.form.email,
        recommendation_text:
          sign_insurance.user_info_page.form.recommendation_text,
      },
      helper: {
        required_field_text: sign_insurance.user_info_page.form.required_field,
        bad_phone_number:
          sign_insurance.user_info_page.required_fields_errors.bad_phone_number,
        bad_email:
          sign_insurance.user_info_page.required_fields_errors.bad_email,
        bad_social_number:
          sign_insurance.user_info_page.required_fields_errors
            .bad_social_number,
      },
      initial_insurance_form: {
        form_label: sign_insurance.user_info_page.retrieval_form.input_text,
        input_format: sign_insurance.user_info_page.retrieval_form.input_format,
      },
      terms: {
        required_text: sign_insurance.user_info_page.terms.required_text,
        terms_text: sign_insurance.user_info_page.terms.terms_html,
      },
    },
    payment: {
      payment_methods_list: {
        list: sign_insurance.payment_page.payment_methods.map((method) => ({
          name: method.name,
          paymentMethod: method.pyramid_paymentMethod,
          description: method.description,
          icon: method.icon,
          provider: method.provider,
          notificationText: method.notificationText,
          amountTextPlaceHolder: method.amountTextPlaceHolder,
          amountText: method.amountText,
          paymentPeriod: method.paymentPeriod.map((period) => ({
            name: period.name,
            identifier: period.period_months,
          })),
        })),
      },
      payment: {
        info_text: sign_insurance.payment_page.payment_info.text,
        info_description: sign_insurance.payment_page.payment_info.description,
      },
    },
    receipt: {
      accept: {
        private_site: {
          success_direct_debit_text:
            sign_insurance.receipt_page.accepted.private_site
              .success_direct_debit_text,
          success_other_payment_text:
            sign_insurance.receipt_page.accepted.private_site.success_text,
          thank_you_direct_debit_text:
            sign_insurance.receipt_page.accepted.private_site
              .thank_you_direct_debit,
          thank_you_other_payment_text:
            sign_insurance.receipt_page.accepted.private_site.thank_you_text,
        },
        public_site: {
          success_direct_debit_text:
            sign_insurance.receipt_page.accepted.general
              .success_direct_debit_text,
          success_already_direct_debit_text:
            sign_insurance.receipt_page.accepted.general
              .success_direct_debit_already_registered_text,
          success_invoice_text:
            sign_insurance.receipt_page.accepted.general.success_invoice_text,
          success_card_payment_text:
            sign_insurance.receipt_page.accepted.general
              .success_card_payment_text,
          thank_you_text:
            sign_insurance.receipt_page.accepted.general.thank_you_text,
        },
      },
      cancel: {
        private_site: {
          title: sign_insurance.receipt_page.cancel.private.title,
          cancel_direct_debit_text:
            sign_insurance.receipt_page.cancel.private.cancel_text_direct_debit,
        },
        public_site: {
          title: sign_insurance.receipt_page.cancel.public.title,
          cancel_card_payment_text:
            sign_insurance.receipt_page.cancel.public
              .cancel_text_card_payment_text,
          cancel_direct_debit_text:
            sign_insurance.receipt_page.cancel.public.cancel_text_direct_debit,
        },
        info_text: sign_insurance.receipt_page.cancel.info_text,
      },
      receipt: {
        contact_text: sign_insurance.receipt_page.footer.contact_html,
        bread_text: {
          title: sign_insurance.receipt_page.footer.bread_text.title,
          text: sign_insurance.receipt_page.footer.bread_text.text,
        },
        login_button: {
          text: sign_insurance.receipt_page.footer.login_button.text,
        },
      },
    },
  };
}

function parseComponents(lang_file) {
  const { private_web } = lang_file;
  return {
    banner: {
      banner_direct_debit: {
        content_text: private_web.home_page.banner_direct_debit.text,
        button: {
          text: private_web.home_page.banner_direct_debit.button_text,
        },
      },
    },
    benefit: {
      benefit_card: {
        promo_code_text: private_web.benefit_page.benefit_card.promo_code_text,
        book_link_text: private_web.benefit_page.benefit_card.booking_text,
        read_more_text: {
          normal_text:
            private_web.benefit_page.benefit_card.read_more_text.normal_text,
          underscored_text:
            private_web.benefit_page.benefit_card.read_more_text
              .underscored_text,
        },
      },
      benefits: {
        heading_text: private_web.home_page.benefits_component.header_text,
        readmore_text: private_web.home_page.benefits_component.read_more_text,
      },
    },
    footer: {
      footer: {
        cookie_info_text: private_web.footer.cookie_text,
        sitemap_info_text: private_web.footer.sitemap_text,
        magazine_text: private_web.footer.magazine_text,
      },
    },
    helper: {
      read_more_card: {
        img_alt: private_web.home_page.benefits_component.read_more_text,
      },
    },
    info_card: {
      info_card_container: {
        family_insurance_card: {
          heading_text:
            private_web.home_page.header_shortcuts.shortcut_1
              .family_insurance_version.title_text,
          button_text:
            private_web.home_page.header_shortcuts.shortcut_1
              .family_insurance_version.button_text,
          content_text:
            private_web.home_page.header_shortcuts.shortcut_1
              .family_insurance_version.description_text,
        },
        invoice_card: {
          heading_text:
            private_web.home_page.header_shortcuts.shortcut_1.invoice_version
              .title_text,
          button_text:
            private_web.home_page.header_shortcuts.shortcut_1.invoice_version
              .button_text,
          content_text: {
            incoming_invoice_text:
              private_web.home_page.header_shortcuts.shortcut_1.invoice_version
                .description_text.next_invoice_text,
            my_invoices_text:
              private_web.home_page.header_shortcuts.shortcut_1.invoice_version
                .description_text.my_invoices_text,
          },
        },
        sign_insurance_card: {
          heading_text:
            private_web.home_page.header_shortcuts.shortcut_2
              .sign_insurance_version.title_text,
          button_text:
            private_web.home_page.header_shortcuts.shortcut_2
              .sign_insurance_version.button_text,
          content_text:
            private_web.home_page.header_shortcuts.shortcut_2
              .sign_insurance_version.description_text,
        },
        direct_debit_card: {
          heading_text:
            private_web.home_page.header_shortcuts.shortcut_2
              .direct_debit_version.title_text,
          button_text:
            private_web.home_page.header_shortcuts.shortcut_2
              .direct_debit_version.button_text,
          content_text:
            private_web.home_page.header_shortcuts.shortcut_2
              .direct_debit_version.description_text,
        },
        invite_friend_card: {
          heading_text:
            private_web.home_page.header_shortcuts.shortcut_3
              .invite_friend_version.title_text,
          button_text:
            private_web.home_page.header_shortcuts.shortcut_3
              .invite_friend_version.button_text,
          content_text:
            private_web.home_page.header_shortcuts.shortcut_3
              .invite_friend_version.description_text,
        },
        direct_claim_card: {
          heading_text:
            private_web.home_page.header_shortcuts.shortcut_1
              .claim_insurance_version.title_text,
          button_text:
            private_web.home_page.header_shortcuts.shortcut_1
              .claim_insurance_version.button_text,
          content_text:
            private_web.home_page.header_shortcuts.shortcut_1
              .claim_insurance_version.description_text,
        },
      },
    },
    insurance: {
      insurance_card: {
        insurance_period_text:
          private_web.my_insurances_page.insurance_card.insurance_period_text,
        insurance_number_text:
          private_web.my_insurances_page.insurance_card.insurance_number_text,
        co_insurance_card: {
          title_text:
            private_web.my_insurances_page.insurance_card.co_insurance_promo
              .title_text,
          description_text:
            private_web.my_insurances_page.insurance_card.co_insurance_promo
              .description.text,
          cost: private_web.my_insurances_page.insurance_card.co_insurance_promo
            .description.cost,
          annual_fee_text: lang_file.misc.payment_per_year_text,
          button: {
            text: private_web.my_insurances_page.insurance_card
              .co_insurance_promo.add_co_insurance_text,
          },
        },
        insurance_terms_text:
          private_web.my_insurances_page.insurance_card.insurance_terms_text,
        total_anual_payment_text:
          private_web.my_insurances_page.insurance_card
            .total_anual_payment_text,
        insurance_letters_text:
          private_web.my_insurances_page.insurance_card.insurance_letters_text,
        currency_display_text: lang_file.misc.display_currency,
      },
      insurance_card_simple: {
        button: {
          order_key_ring_text:
            private_web.home_page.insurance_card.button.order_keyrings_text,
          add_co_insurance_text:
            private_web.home_page.insurance_card.button.add_co_insurance_text,
        },
      },
      insurance: {
        heading_text: private_web.my_insurances_page.insurance_card.title_text,
      },
      show_co_insured: {
        title_text:
          private_web.my_insurances_page.insurance_card.co_insurance_card
            .title_text,
        terms_text:
          private_web.my_insurances_page.insurance_card.co_insurance_card
            .terms_text,
        annual_fee_text: lang_file.misc.payment_per_year_text,
      },
      show_co_insured_suggestion: {
        title_text:
          private_web.my_insurances_page.insurance_card
            .co_insurance_suggestions_card.title_text,
        button: {
          text: private_web.my_insurances_page.insurance_card
            .co_insurance_suggestions_card.add_co_insurance_text,
        },
      },
      summation_card: {
        insured_text: private_web.my_insurances_page.summary.insured_text,
        customer_identifier:
          private_web.my_insurances_page.summary.customer_identifier,
        customer_code: private_web.my_insurances_page.summary.customer_code,
        banner_direct_debit: {
          heading_text: private_web.home_page.banner_direct_debit.title,
        },
      },
      summations: {
        title_text: private_web.my_insurances_page.summary.title_text,
      },
      vehicle_card: {
        has_insurance_text:
          private_web.home_page.vehicle_card.card.has_insurance_text,
        and_deductible_text:
          private_web.home_page.vehicle_card.card.and_deductible_text,
        missing_insurance_text:
          private_web.home_page.vehicle_card.card.missing_insurance_text,
        ineligible_vehicle_text:
          private_web.home_page.vehicle_card.card.ineligible_vehicle_text,
        button: {
          text: private_web.home_page.vehicle_card.card.sign_button_text,
        },
      },
      vehicles: {
        title_text: private_web.home_page.vehicle_card.title_text,
        button: {
          text: private_web.home_page.vehicle_card.recommendation
            .sign_button_text,
        },
      },
    },
    invoice: {
      invoice_card: {
        title_text: private_web.invoice_page.invoice_card.invoice_text,
        heading_text: private_web.home_page.banner_direct_debit.title,
        content_text: private_web.home_page.banner_direct_debit.text_2,
      },
      invoice_insurance_card: {
        insurance_text:
          private_web.invoice_page.invoice_card.insurance_card.insurances_text,
        insurance:
          private_web.invoice_page.invoice_card.insurance_card.insurance,
        invoice_cost_text:
          private_web.invoice_page.invoice_card.insurance_card
            .invoice_cost_text,
        ocr_text: private_web.invoice_page.invoice_card.insurance_card.ocr_text,
        to_pay_text:
          private_web.invoice_page.invoice_card.insurance_card.to_pay_text,
        currency_display_text: lang_file.misc.display_currency,
        insurance_period:
          private_web.invoice_page.invoice_card.insurance_card.insurance_period,
        insurance_period_date:
          private_web.invoice_page.invoice_card.insurance_card
            .insurance_period_date,
        insurance_pre_text:
          private_web.invoice_page.invoice_card.insurance_card
            .insurance_pre_text,
      },
      invoice_personal_card: {
        insuree_text:
          private_web.invoice_page.invoice_card.personal_card.insuree_text,
        issue_date_text:
          private_web.invoice_page.invoice_card.personal_card.issue_date_text,
        customer_identifier:
          private_web.invoice_page.invoice_card.personal_card
            .customer_identifier,
        customer_code:
          private_web.invoice_page.invoice_card.personal_card.customer_code,
        due_date_text:
          private_web.invoice_page.invoice_card.personal_card.due_date_text,
        insurance_code:
          private_web.invoice_page.invoice_card.personal_card.insurance_code,
        insurance_period:
          private_web.invoice_page.invoice_card.personal_card.insurance_period,
        payment_method:
          private_web.invoice_page.invoice_card.personal_card.payment_method,
        payment_period:
          private_web.invoice_page.invoice_card.personal_card.payment_period,
        payment_status:
          private_web.invoice_page.invoice_card.personal_card.payment_status,
      },
      key_tab: {
        title_text: private_web.invoice_page.key_tab_card.title,
        description_text: private_web.invoice_page.key_tab_card.description,
        status_text: private_web.invoice_page.key_tab_card.status.status_text,
        status: {
          key_tab_no_insurance_text:
            private_web.invoice_page.key_tab_card.status
              .key_tab_no_insurance_status,
          insurance_no_key_tab_text:
            private_web.invoice_page.key_tab_card.status.insurance_no_key_tab,
          active_key_tab_text:
            private_web.invoice_page.key_tab_card.status.active_key_tab,
        },
        key_tab_number_text:
          private_web.invoice_page.key_tab_card.key_tab_number,
        annual_fee_text: private_web.invoice_page.key_tab_card.annual_fee,
        tabs_text: private_web.invoice_page.key_tab_card.key_tabs_text_short,
        order_key_tabs_button: {
          text: private_web.invoice_page.key_tab_card.order_more_text,
        },
        max_key_tabs_warning_text:
          private_web.invoice_page.key_tab_card.max_key_tabs_warning,
        curreny_text: lang_file.misc.display_currency,
        unit_text: lang_file.misc.unit_each,
      },
    },
    login: {
      sms: {
        sms_login: {
          description_text: private_web.login_page.sms_modal.description,
          label_text: private_web.login_page.sms_modal.label_text,
        },
        sms_login_form: {
          placeholder: private_web.login_page.sms_modal.placeholder,
          submit_button: {
            text: private_web.login_page.sms_modal.send_sms_button.text,
          },
        },
        sms_token_form: {
          placeholder: private_web.login_page.sms_modal.placeholder_token,
          submit_button: {
            text: private_web.login_page.sms_modal.login_button.text,
          },
        },
      },
      bankid: {
        bankid_login_form: {
          title_text: private_web.login_page.bankid_modal.title_text,
          description_text: private_web.login_page.bankid_modal.description,
          description_text_mobile:
            private_web.login_page.bankid_modal.description_mobile,
          label_text: private_web.login_page.bankid_modal.label_text,
          placeholder: private_web.login_page.bankid_modal.placeholder,
          tips_text_intro: private_web.login_page.bankid_modal.tips_text_intro,
          tips_text: private_web.login_page.bankid_modal.tips_text,
          help_text_intro: private_web.login_page.bankid_modal.help_text_intro,
          help_text: private_web.login_page.bankid_modal.help_text,
          ready_to_use_text:
            private_web.login_page.bankid_modal.ready_to_use_text,
          ready_login_text:
            private_web.login_page.bankid_modal.ready_login_text,
          qr_code_text: private_web.login_page.bankid_modal.qr_code_text,
          submit_button: {
            text: private_web.login_page.bankid_modal.login_button.text,
          },
          cancel_button: {
            text: private_web.login_page.bankid_modal.mobile_cancel_button.text,
          },
        },
        bankid_mobile_login: {
          description_text:
            private_web.login_page.bankid_modal.mobile_step.description,
          label_text:
            private_web.login_page.bankid_modal.mobile_step.label_text,
          open_app_button: {
            text: private_web.login_page.bankid_modal.mobile_step
              .open_application_text,
          },
          default_login_button: {
            text: private_web.login_page.bankid_modal.mobile_step
              .default_login_text,
          },
        },
      },
      disabled_login: {
        description_text: private_web.login_page.disabled_modal.description,
        label_text: private_web.login_page.disabled_modal.label_text,
      },
    },
    snack_bar: {
      insurances: {
        add_co_insured: {
          add_self: private_web.snack_bar.insurances.add_co_insured.add_self,
          add_success:
            private_web.snack_bar.insurances.add_co_insured.add_success,
        },
        order_keytabs: {
          success_1: private_web.snack_bar.insurances.order_keytabs.success_1,
          success_2: private_web.snack_bar.insurances.order_keytabs.success_2,
        },
      },
      update_user: {
        success: private_web.snack_bar.update_user.success,
      },
      sms_login: {
        login_fail: private_web.snack_bar.sms_login.login_fail,
      },
      sms_invite: {
        success: private_web.snack_bar.sms_invite.success,
        failure: private_web.snack_bar.sms_invite.failure,
      },
    },
    modal: {
      bank_id_modal: {
        title: private_web.popup_modals.bankid_modal.title,
        description: private_web.popup_modals.bankid_modal.description,
        open_app_text: private_web.popup_modals.bankid_modal.open_app_text,
        open_app_link_text:
          private_web.popup_modals.bankid_modal.open_app_link_text,
      },
      co_insured_modal: {
        title: private_web.popup_modals.co_insurance_modal.title,
        title_family_member:
          private_web.popup_modals.co_insurance_modal.title_family_member,
        description: private_web.popup_modals.co_insurance_modal.description,
        description_family_member:
          private_web.popup_modals.co_insurance_modal.description_family_member,
        disclaimer: private_web.popup_modals.co_insurance_modal.disclaimer_text,
        pricing: {
          annual_price:
            private_web.popup_modals.co_insurance_modal.pricing.annual_price,
          remaining_price:
            private_web.popup_modals.co_insurance_modal.pricing.remaining_price,
          period_text:
            private_web.popup_modals.co_insurance_modal.pricing.period,
          to: private_web.popup_modals.co_insurance_modal.pricing.to,
        },
        submit_button: {
          text: private_web.popup_modals.co_insurance_modal.submit_text,
        },
        form: {
          customer_code: {
            text: private_web.popup_modals.co_insurance_modal.form.customer_code
              .text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.customer_code
                .placeholder,
          },
          first_name: {
            text: private_web.popup_modals.co_insurance_modal.form.first_name
              .text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.first_name.text,
          },
          last_name: {
            text: private_web.popup_modals.co_insurance_modal.form.last_name
              .text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.last_name
                .placeholder,
          },
          address: {
            text: private_web.popup_modals.co_insurance_modal.form.address.text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.address
                .placeholder,
          },
          zipcode: {
            text: private_web.popup_modals.co_insurance_modal.form.zipcode.text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.zipcode
                .placeholder,
          },
          city: {
            text: private_web.popup_modals.co_insurance_modal.form.city.text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.city.placeholder,
          },
          born: {
            text: private_web.popup_modals.co_insurance_modal.form.born.text,
            placeholder:
              private_web.popup_modals.co_insurance_modal.form.born.placeholder,
          },
          required_field:
            private_web.popup_modals.co_insurance_modal.form.required_field,
        },
      },
      invite_friend_modal: {
        title: private_web.popup_modals.invite_friend_modal.title,
        description: private_web.popup_modals.invite_friend_modal.description,
        submit_placeholder:
          private_web.popup_modals.invite_friend_modal.submit_placeholder,
        submit_button: {
          text: private_web.popup_modals.invite_friend_modal.submit_text,
        },
      },
      missing_email_modal: {
        title: private_web.popup_modals.missing_email.title,
        description: private_web.popup_modals.missing_email.description,
        submit_placeholder:
          private_web.popup_modals.missing_email.submit_placeholder,
        submit_button: {
          text: private_web.popup_modals.missing_email.submit_text,
        },
      },
      order_key_tabs_modal: {
        title: private_web.popup_modals.order_key_tabs_modal.title,
        description: private_web.popup_modals.order_key_tabs_modal.description,
        item_count_text:
          private_web.popup_modals.order_key_tabs_modal.item_count_text,
        submit_button: {
          text: private_web.popup_modals.order_key_tabs_modal.submit_text,
        },
      },
      add_new_vehicle_modal: {
        title: private_web.popup_modals.add_new_vehicle_modal.title,
        description: private_web.popup_modals.add_new_vehicle_modal.description,
        button: private_web.popup_modals.add_new_vehicle_modal.button,
      },
    },
    navbar: {
      hamburger_menu_icon: {
        menu_text: private_web.nav_bar.top.menu_text,
      },
      logout: {
        logout_text: private_web.nav_bar.top.logout_text,
      },
      side_bar: {
        home_text: private_web.nav_bar.side_bar.home,
        my_settings_text: private_web.nav_bar.side_bar.my_settings,
        my_insurances_text: private_web.nav_bar.side_bar.my_insurances,
        my_invoices_text: private_web.nav_bar.side_bar.my_invoices,
        new_claim_text: private_web.nav_bar.side_bar.new_claim,
        my_benefits_text: private_web.nav_bar.side_bar.my_benefits,
        sign_insurance_text: private_web.nav_bar.side_bar.sign_insurance,
        contact_customer_support_text:
          private_web.nav_bar.side_bar.contact_customer_support,
      },
      top_header: {
        title_text: private_web.nav_bar.top.public_site_link_text,
        alt: private_web.nav_bar.top.site_name,
      },
    },
    constants: {
      error_constants: {
        unknown_error_text: private_web.errors.unknown_error,
        session_error: private_web.errors.session_error,
        customer_suspended: private_web.errors.customer_suspended,
        add_co_insured_error: private_web.errors.add_co_insured_error,
      },
    },
    magazine: {
      magazine_card: {
        issue_text: private_web.home_page.magazine_card.card.issue_text,
        read_more_text: private_web.home_page.magazine_card.card.read_more_text,
      },
      magazines: {
        heading_text: private_web.home_page.magazine_card.title_text,
        read_more_button: {
          text: private_web.home_page.magazine_card.read_more_button_text,
        },
      },
    },
  };
}

function parseHelpers(lang_file) {
  const { private_web } = lang_file;
  return {
    validation: {
      invalid_social_number: private_web.errors.invalid_social_number,
      invalid_email: private_web.errors.invalid_email,
      invalid_phone_number: private_web.errors.invalid_phone_number,
      invalid_num_characters: private_web.errors.invalid_num_characters,
      mandatory_field: private_web.errors.mandatory_field,
    },
  };
}

function parseViews(lang_file) {
  const { private_web } = lang_file;
  return {
    home_page: {
      heading_text: private_web.home_page.title,
    },
    customer_service: {
      index: {
        heading_text: private_web.customer_support_page.title_text,
        greeting_text: private_web.customer_support_page.description,
        open_times_text: private_web.customer_support_page.open_times_text,
        schedule_text: private_web.customer_support_page.schedule_text,
        phone_number: private_web.customer_support_page.phone_number,
      },
    },
    claim: {
      index: {
        heading_text: private_web.claim_page.title,
        greeting_text: private_web.claim_page.description,
      },
      contact_info: {
        header: private_web.claim_page.contact_info.header,
        details: private_web.claim_page.contact_info.details,
        mail: private_web.claim_page.contact_info.mail,
        phone: private_web.claim_page.contact_info.phone,
        update: private_web.claim_page.contact_info.update,
      },
      claim_form: {
        footer_text: private_web.claim_page.footer,
        submit_button: private_web.claim_page.button,
      },
      claim_form_response: {
        success_title: private_web.claim_page.questions.response.success.header,
        success_description:
          private_web.claim_page.questions.response.success.body,
        failure_title: private_web.claim_page.questions.response.failure.header,
        failure_description:
          private_web.claim_page.questions.response.failure.body,
        back_button: private_web.claim_page.questions.response.back_button,
        sending_data: private_web.claim_page.questions.response.sending_data,
        failure_no_active_insurance:
          private_web.claim_page.questions.response.failure.no_insurance,
      },
      questions: {
        question_legal_confirmation: {
          title_text: private_web.claim_page.questions.question_legal.title,
          description_text_applying:
            private_web.claim_page.questions.question_legal.applying,
          description_text_consent:
            private_web.claim_page.questions.question_legal.consent,
        },
        question_insurance_type: {
          title_text:
            private_web.claim_page.questions.question_insurance_type.title,
          description_text:
            private_web.claim_page.questions.question_insurance_type
              .description,
          alternative_claim:
            private_web.claim_page.questions.question_insurance_type
              .alternatives.claim,
          information_text:
            private_web.claim_page.questions.question_insurance_type
              .information,
        },
        question_vehicles: {
          title_text: private_web.claim_page.questions.question_vehicles.title,
          description_text:
            private_web.claim_page.questions.question_vehicles.description,
          add_new_vehicle_button:
            private_web.home_page.add_new_vehicle_button.description,
          highlight_car_text:
            private_web.claim_page.questions.question_vehicles
              .highlight_car_text,
        },
        question_date_of_accident: {
          title_text:
            private_web.claim_page.questions.question_date_of_accident.title,
          description_text:
            private_web.claim_page.questions.question_date_of_accident
              .description,
          error_message:
            private_web.claim_page.questions.question_date_of_accident
              .error_message,
        },
        question_confirm_insurance_company: {
          title_text:
            private_web.claim_page.questions.question_confirm_insurance_company
              .title,
          description_text:
            private_web.claim_page.questions.question_confirm_insurance_company
              .description,
          alternative_confirm:
            private_web.claim_page.questions.question_confirm_insurance_company
              .alternatives.confirm,
          alternative_decline:
            private_web.claim_page.questions.question_confirm_insurance_company
              .alternatives.decline,
          question_confirm_prefix:
            private_web.claim_page.questions.question_confirm_insurance_company
              .confirm_question_prefix,
          question_confirm_suffix:
            private_web.claim_page.questions.question_confirm_insurance_company
              .confirm_question_suffix,
          question_insurance_company_name:
            private_web.claim_page.questions.question_confirm_insurance_company
              .question_insurance_company_name,
          fetching_insurance:
            private_web.claim_page.questions.question_confirm_insurance_company
              .fetching_insurance,
          could_not_fetch_insurance:
            private_web.claim_page.questions.question_confirm_insurance_company
              .could_not_fetch_insurance,
        },
        question_damage_type: {
          title_text:
            private_web.claim_page.questions.question_damage_type.title,
          description_text:
            private_web.claim_page.questions.question_damage_type.description,
          category:
            private_web.claim_page.questions.question_damage_type.category,
          options:
            private_web.claim_page.questions.question_damage_type.options,
          warning_glas:
            private_web.claim_page.questions.question_damage_type.error_message,
        },
        question_cost: {
          title_text: private_web.claim_page.questions.question_cost.title,
          description_text:
            private_web.claim_page.questions.question_cost.description,
          error_message:
            private_web.claim_page.questions.question_cost.error_message,
        },
        question_collision: {
          title_text: private_web.claim_page.questions.question_collision.title,
          description_text:
            private_web.claim_page.questions.question_collision.description,
          question_responsible:
            private_web.claim_page.questions.question_collision.responsible,
          warning: private_web.claim_page.questions.question_collision.warning,
        },
        question_bank: {
          title_text: private_web.claim_page.questions.question_bank.title,
          description_text:
            private_web.claim_page.questions.question_bank.description,
          description_bank_text:
            private_web.claim_page.questions.question_bank.description_bank,
          description_swish_text:
            private_web.claim_page.questions.question_bank.description_swish,
          indicated_account:
            private_web.claim_page.questions.question_bank.indicated_account,
          bank_name: private_web.claim_page.questions.question_bank.name,
          clearing: private_web.claim_page.questions.question_bank.clearing,
          account: private_web.claim_page.questions.question_bank.account,
          owner: private_web.claim_page.questions.question_bank.owner,
          swish: private_web.claim_page.questions.question_bank.swish,
          fetch_klarna_button:
            private_web.claim_page.questions.question_bank.fetch_klarna_button,
          error_message:
            private_web.claim_page.questions.question_bank.error_message,
          error_message_swish:
            private_web.claim_page.questions.question_bank.error_message_swish,
          error_message_klarna:
            private_web.claim_page.questions.question_bank.error_message_klarna,
        },
        question_add_attachment: {
          title_text:
            private_web.claim_page.questions.question_add_attachment.title,
          description_text:
            private_web.claim_page.questions.question_add_attachment
              .description,
          button_text:
            private_web.claim_page.questions.question_add_attachment.button,
          error_message:
            private_web.claim_page.questions.question_add_attachment
              .error_message,
          error_message_files:
            private_web.claim_page.questions.question_add_attachment
              .error_message_files,
          error_message_size:
            private_web.claim_page.questions.question_add_attachment
              .error_message_size,
          attachment_3: {
            title:
              private_web.claim_page.questions.question_add_attachment
                .attachment_3.title,
            description:
              private_web.claim_page.questions.question_add_attachment
                .attachment_3.description,
          },
          attachment_2: {
            title:
              private_web.claim_page.questions.question_add_attachment
                .attachment_2.title,
            description:
              private_web.claim_page.questions.question_add_attachment
                .attachment_2.description,
          },
          attachment_1: {
            title:
              private_web.claim_page.questions.question_add_attachment
                .attachment_1.title,
            description:
              private_web.claim_page.questions.question_add_attachment
                .attachment_1.description,
          },
        },
        question_add_additional_info: {
          title_text:
            private_web.claim_page.questions.question_add_additional_info.title,
          description_text:
            private_web.claim_page.questions.question_add_additional_info
              .description,
          error_message:
            private_web.claim_page.questions.question_add_additional_info
              .error_message,
        },
      },
    },
    details: {
      details_form: {
        save_button: {
          text: private_web.my_settings_page.form.submit_text,
        },
        email: {
          text: private_web.my_settings_page.form.email.email_text,
          placeholder_text:
            private_web.my_settings_page.form.email.placeholder_text,
        },
        phone: {
          text: private_web.my_settings_page.form.phone.phone_text,
          placeholder_text:
            private_web.my_settings_page.form.phone.placeholder_text,
        },
        born: {
          text: private_web.my_settings_page.form.born.born_text,
          placeholder_text:
            private_web.my_settings_page.form.born.placeholder_text,
        },
      },
      index: {
        title: private_web.my_settings_page.title,
      },
      user_details: {
        name_text: private_web.my_settings_page.form.name,
        address_text: private_web.my_settings_page.form.address,
        city_text: private_web.my_settings_page.form.city,
        customer_identifier_text:
          private_web.my_settings_page.form.customer_identifier,
        zip_code_text: private_web.my_settings_page.form.zip_code,
        country_text: private_web.my_settings_page.form.country.country_text,
        country: private_web.my_settings_page.form.country.country,
      },
    },
    insurances: {
      index: {
        title: private_web.my_insurances_page.title,
      },
    },
    invoice_page: {
      index: {
        heading_text: private_web.invoice_page.title_text,
        payment_method_override_text:
          private_web.invoice_page.direct_debit_promo,
        cancel_direct_debit: {
          text: private_web.invoice_page.cancel_direct_debit.text,
          telephone_number:
            private_web.invoice_page.cancel_direct_debit.telephone_number,
        },
        info_text: {
          text: private_web.invoice_page.info_text.title,
          description: private_web.invoice_page.info_text.description,
        },
        apply_direct_debit_button: {
          text: private_web.invoice_page.apply_direct_debit_text,
        },
      },
    },
    login_page: {
      index: {
        heading_text: private_web.login_page.title_text,
      },
    },
    shop_page: {
      index: {
        title: private_web.sign_insurance_page.title,
        steps: {
          choose_insurance_text:
            private_web.sign_insurance_page.steps.step_1.text,
          user_info_text: private_web.sign_insurance_page.steps.step_2.text,
        },
      },
    },
  };
}
function parseMeta(meta_strings) {
  return {
    title: meta_strings.private_web.meta.title,
    description: meta_strings.private_web.meta.description,
    favicon: meta_strings.private_web.meta.favicon,
  };
}

export function bootstrap(lang_file) {
  holder.comp = parseComponentModuleLang(lang_file);
  holder.reg = parseRegisterModuleLang(lang_file);
  holder.meta = parseMeta(lang_file);
  holder.customer = {
    components: parseComponents(lang_file),
    helpers: parseHelpers(lang_file),
    views: parseViews(lang_file),
  };

  holder.formatString = formatString;
  return holder;
}

export default holder;
