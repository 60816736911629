import React from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/helper/LoadingSpinner";
import Button from "../../components/helper/Button";
import { apiConstants } from "../../constants";
import lang from "../../helpers/lang-constants";
import { insuranceActions } from "../../actions";

const checkActiveInsurance = (errorResponse) => {
  return (
    errorResponse.statusMessage ===
    "Ingen aktiv försäkring: Det gick inte att hitta någon försäkringsrad kopplad till personnumret"
  );
};

function ClaimFormResponse(props) {
  const language = lang.customer.views.claim.claim_form_response;
  const noActiveInsurance = checkActiveInsurance(props?.response);
  const loader = props.loading ? (
    <span className="wrapper flex row wrap justify-center align-center">
      <LoadingSpinner isLoading={props.loading} size={50} />
      <br />
      {language.sending_data}
    </span>
  ) : null;
  let message = "";

  if (props.response.statusCode === apiConstants.SUCCESS_CODE_200) {
    message = (
      <div>
        <span style={{ fontSize: "3em", color: "green" }}>
          <i className="fal fa-badge-check" />
        </span>
        <h1>{language.success_title}</h1>
        <p>{language.success_description}</p>
      </div>
    );
  } else if (!props.loading) {
    message = (
      <div>
        <span style={{ fontSize: "3em", color: "red" }}>
          <i className="fal fa-times-circle" />
        </span>
        <h1>{language.failure_title}</h1>
        {noActiveInsurance ? (
          <p>{language.failure_no_active_insurance}</p>
        ) : (
          <>
            <p>{language.failure_description}</p>
            <Button
              type="button"
              className="button button-secondary"
              style={{ margin: "auto" }}
              onClick={() =>
                props.postClaimInsurance(props?.response?.formContent)
              }
            >
              skicka igen
            </Button>
            <br />
          </>
        )}
        <Button
          type="button"
          className="button button-primary"
          style={{ margin: "auto" }}
          onClick={() => props.showResponse(false)}
        >
          {language.back_button}
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
    >
      {loader}
      {message}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  postClaimInsurance: (formState) =>
    dispatch(insuranceActions.postClaimInsurance(formState)),
});

export default connect(null, mapDispatchToProps)(ClaimFormResponse);
