import React from "react";
import { connect } from "react-redux";
import Button from "../helper/Button";
import LoadingSpinner from "../helper/LoadingSpinner";
import { modalConstants } from "../../constants";
import lang from "../../helpers/lang-constants";
import BaseModal from "./BaseModal";
import { userActions } from "../../actions";

const { MODAL_NOT_FOCUSED } = modalConstants;
const language = lang.customer.components.modal.add_new_vehicle_modal;
class AddNewVehicleModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      newVehicle: {
        regNo: "",
        enableSubmit: false,
      },
    };
  }

  render() {
    const { closeModal, updateUserVehicleData } = this.props;
    const saveNewVehicle = () => {
      updateUserVehicleData(this.state.newVehicle.regNo);
      closeModal();
    };
    const onChangeHandler = (event) => {
      const { value } = event.target;
      if (value.length > 0) {
        this.setState(() => ({
          newVehicle: { regNo: value, enableSubmit: true },
        }));
      } else {
        this.setState(() => ({
          newVehicle: { regNo: value, enableSubmit: false },
        }));
      }
    };

    return (
      <div
        ref={this.targetRef}
        className="modal content new-vehicle"
        tabIndex={MODAL_NOT_FOCUSED}
      >
        <h4 className="dialog-title flex row justify-between">
          {language.title}
          <i
            className="fal fa-times-circle modal-close color-blue"
            onClick={closeModal}
            onKeyPress={closeModal}
            tabIndex={0}
            role="button"
          />
        </h4>
        <div className="dialog-content">
          <span className="content-description">{language.description}</span>
          <input
            name="vehicle"
            type="string"
            placeholder="ABC123"
            onChange={onChangeHandler}
            value={this.state.newVehicle.regNo}
          />
        </div>
        <div className="dialog-actions flex justify-center">
          <Button
            type="button"
            onClick={saveNewVehicle}
            disabled={!this.state.newVehicle.enableSubmit}
          >
            <LoadingSpinner text={language.button} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUserVehicleData: (regNo) =>
    dispatch(userActions.updateUserVehicleData(regNo)),
});

export default connect(null, mapDispatchToProps)(AddNewVehicleModal);
