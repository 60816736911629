import React from "react";
import FormCard from "../../../components/formCard/FormCard";
import RadioInput from "../../../components/helper/RadioInput";

import lang from "../../../helpers/lang-constants";

const language = lang.customer.views.claim.questions.question_insurance_type;

function QuestionInsuranceType(props) {
  // Todo: Handle radiobuttons values somehow smarter...
  const value = "självriskförsäkring";
  const onChangeHandler = () => {
    props.setState(value);

    props.showNextQuestion(props.name);
  };

  const isSelected = props.state === value;

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <RadioInput
        value={value}
        selected={props.state}
        text={language.alternative_claim}
        onChange={onChangeHandler}
      />
      {isSelected && (
        <p style={{ fontStyle: "italic" }}>{language.information_text}</p>
      )}
    </FormCard>
  );
}

export default QuestionInsuranceType;
