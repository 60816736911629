import React, { useEffect } from "react";
import { connect } from "react-redux";
import FormCard from "../../../components/formCard/FormCard";
import { userSelectors } from "../../../selectors";
import { insuranceActions, userActions } from "../../../actions";
import lang from "../../../helpers/lang-constants";
import VehicleClaimCard from "../../../components/claim/VehicleClaimCard";
import AddNewVehicleButton from "../../../components/claim/AddNewVehicleButton";
import CustomVehicleClaimCard from "../../../components/claim/CustomVehicleClaimCard";

const language = lang.customer.views.claim.questions.question_vehicles;

const {
  getUpdating,
  getRegisteredVehiclesIds,
  getCustomRegisteredVehiclesIds,
  getRegisteredVehiclesTypes,
} = userSelectors;

function QuestionVehicle(props) {
  const { isLoading, vehiclesIds, customVehicleIds } = props;

  useEffect(() => {
    const { fetchUserVehicles } = props;
    fetchUserVehicles();
  }, []);

  const onClickVehicleHandler = (regNr) => {
    props.setState(regNr);
    props.fetchVehicleInsurance(regNr);
    props.showNextQuestion(props.name);
  };

  const vehicleList = vehiclesIds.map((id, i) => {
    return (
      <VehicleClaimCard
        id={id}
        key={id}
        onClick={() => onClickVehicleHandler(id)}
        isSelected={id === props.state}
      />
    );
  });

  const customVehicleList = customVehicleIds.map((id, i) => {
    return (
      <CustomVehicleClaimCard
        id={id}
        key={id}
        onClick={() => onClickVehicleHandler(id)}
        isSelected={id === props.state}
      />
    );
  });
  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <div className="vehicle">
        {vehiclesIds.size > 0 && vehicleList}
        {customVehicleList}
        <AddNewVehicleButton />
      </div>
      {vehiclesIds.size > 0 && (
        <p style={{ fontStyle: "italic" }}>{language.highlight_car_text}</p>
      )}
    </FormCard>
  );
}

const mapStateToProps = (state) => ({
  isLoading: getUpdating(state, "fetchVehicles"),
  customVehicleIds: getCustomRegisteredVehiclesIds(state),
  vehiclesIds: getRegisteredVehiclesIds(state),
  vehicleTypes: getRegisteredVehiclesTypes(state),
});
const mapDispatchToProps = (dispatch) => ({
  fetchUserVehicles: () => dispatch(userActions.fetchUserVehicleData()),
  fetchVehicleInsurance: (regNr) =>
    dispatch(insuranceActions.fetchVehicleInsurance(regNr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionVehicle);
