import { fromJS } from "immutable";
import { hamburgerConstants } from "../constants";

const { OPEN_HAMBURGER, CLOSE_HAMBURGER } = hamburgerConstants;

const initialState = fromJS({
  isOpen: false,
});

const hamburger = (state = initialState, action) => {
  const { type } = action;
  const newState = state;
  switch (type) {
    case OPEN_HAMBURGER:
      return newState.set("isOpen", !state.get("isOpen", false));
    case CLOSE_HAMBURGER:
      return newState.set("isOpen", false);
    default:
      return newState;
  }
};

export default hamburger;
