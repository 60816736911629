import { fromJS } from "immutable";
import { policyConstants } from "../constants";
import { updateMap } from "./functions";

const {
  ADD_INSURANCE_POLICY_DATA_REQUEST,
  ADD_INSURANCE_POLICY_DATA_SUCCESS,
  ADD_INSURANCE_POLICY_DATA_FAILURE,
  GET_INSURANCE_POLICY_REQUEST,
  GET_INSURANCE_POLICY_SUCCESS,
  GET_INSURANCE_POLICY_FAILURE,
} = policyConstants;

const initialState = fromJS({
  updating: {},
  error: "",
  current: {},
  upcoming: {},
  dateActive: {},
  payload: {},
  filename: {},
  loading: {
    current: false,
    upcoming: false,
  },
});

const insurancePolicy = (state = initialState, action) => {
  const { type, data, orderNumber, payload } = action;
  let newState = state;
  switch (type) {
    case ADD_INSURANCE_POLICY_DATA_REQUEST:
      newState = newState.set("updating", false);
      return newState;
    case ADD_INSURANCE_POLICY_DATA_SUCCESS:
      newState = updateMap(newState, "current", orderNumber, data.current);
      newState = updateMap(newState, "upcoming", orderNumber, data.upcoming);
      newState = updateMap(
        newState,
        "dateActive",
        orderNumber,
        data.dateActive
      );
      return newState;

    case ADD_INSURANCE_POLICY_DATA_FAILURE:
      newState = newState.set("updating", false);
      return newState;
    case GET_INSURANCE_POLICY_REQUEST:
      newState = updateMap(newState, "loading", "current", true);
      newState = newState.set("updating", true);
      return newState;
    case GET_INSURANCE_POLICY_SUCCESS:
      // newState = updateMap(newState, "blob", orderNumber, payload);
      newState = newState.set("loading", initialState.get("current"));
      newState = updateMap(newState, "loading", "current", false);
      newState = newState.set("updating", false);
      newState = newState.set("blob", payload);
      return newState;

    case GET_INSURANCE_POLICY_FAILURE:
      newState = newState.set("loading", initialState.get("current"));
      newState = newState.set("updating", false);
      return newState;
    default:
      return newState;
  }
};

export default insurancePolicy;
