import React from "react";
import lang from "../../helpers/lang-constants";

const { period_text, annual_price, remaining_price } =
  lang.customer.components.modal.co_insured_modal.pricing;

function CoInsuredPrice({ annualPrice, remainingPrice, period }) {
  return (
    <div className="co-insured-price">
      <div className="co-insured-price-container">
        <span className="co-insured-price-heading">{period_text}: </span>
        {period}
      </div>
      <div className="co-insured-price-container">
        <span className="co-insured-price-heading">{annual_price}: </span>
        {annualPrice}
      </div>
      <div className="co-insured-price-container">
        <span className="co-insured-price-heading">{remaining_price}: </span>
        {remainingPrice}
      </div>
    </div>
  );
}

export default CoInsuredPrice;
