import React from "react";
import { connect } from "react-redux";
import bankIdLogoSmall from "../../../assets/images/BankID_logo.svg";

function BankIdQrCodeLoader() {
  return (
    <div className="qr-code-loader">
      <div className="qr-loading-phone">
        <div className="loader-top-border" />
        <img className="bankid-logo" src={bankIdLogoSmall} alt="bankid logo" />
      </div>
      <div className="loading-dots-container">
        <div className="dots">
          <div className="dot-1 dot" />
          <div className="dot-2 dot" />
          <div className="dot-3 dot" />
          <div className="dot-4 dot" />
          <div className="dot-5 dot" />
        </div>
      </div>
    </div>
  );
}

export default connect()(BankIdQrCodeLoader);
