import React from "react";
import { connect } from "react-redux";
import { modalActions } from "../../actions";

import lang from "../../helpers/lang-constants";

const language = lang.customer.views.claim.questions.question_vehicles;

function AddNewVehicleButton({ dispatch }) {
  const onClickHandler = () => {
    dispatch(modalActions.openModal("addNewVehicleModal"));
  };

  return (
    <div
      className="vehicle-claim-card"
      role="carousel-button"
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
    >
      <div className="vehicle-claim-background-image">
        <span
          className="vehicle-claim-add-new-vehicle-symbol"
          alt="{language.add_new_vehicle}"
        >
          +
        </span>
      </div>
      <div className="vehicle-claim-regnr">
        {language.add_new_vehicle_button}
      </div>
    </div>
  );
}

export default connect()(AddNewVehicleButton);
