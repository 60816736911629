import React from "react";
import classNames from "classnames";

function InlineBanner({ heading, content, children, icon, ...rest }) {
  return (
    <div
      className={classNames(
        "banner flex align-center",
        Object.prototype.hasOwnProperty.call(rest, "className")
          ? rest.className
          : ""
      )}
    >
      <div className="flex column banner-content justify-center align-center">
        <h3>{heading}</h3>
        <span className="banner-span">{content}</span>
        <div className="children-content">{children}</div>
      </div>
      <i className={icon} />
    </div>
  );
}

export default InlineBanner;
