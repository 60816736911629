import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import {
  routesConstants,
  apiConstants,
  externalRoutesConstants,
} from "../../constants";
import HamburgerMenu from "./HamburgerMenu";
import Logout from "./Logout";
import PrimaryNav from "./PrimaryNav";
import logoAssistans from "../../assets/images/logo.png";
import logoViking from "../../assets/images/logo_viking.png";
import logoSbf from "../../assets/images/logo_sb.png";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";
import TopHeaderMenuItem from "./TopHeaderMenuItem";

const { HOME, LOGIN, ROADSIDE_ASSISTANCE } = routesConstants;
const { EXT_ROADSIDE_ASSISTANCE } = externalRoutesConstants;
const { WP_ASSETS } = apiConstants;
const language = lang.customer.components.navbar.top_header;

function getLogo() {
  let logo;
  if (themes.isNorway()) {
    logo = logoViking;
  } else if (themes.isAssistans()) {
    logo = logoAssistans;
  } else if (themes.isSbf()) {
    logo = logoSbf;
  } else {
    logo = logoAssistans;
  }
  return logo;
}

function TopHeader({ ...props }) {
  const isLogin = props.history.location.pathname.includes(LOGIN);
  const showNewMenu = !themes.isSbf() && isLogin;
  return (
    <header>
      <div id="top-bar" className="top-bar flex row fixed align-center">
        <div
          className={classNames(
            "container flex row align-center justify-between",
            { "new-top-bar": showNewMenu }
          )}
        >
          <NavLink to={HOME} activeClassName="site-logo">
            <img src={getLogo()} alt={language.site_name} />
          </NavLink>
          <ul
            className={classNames("flex row justify-end align-center", {
              "menu2-top-row": showNewMenu,
            })}
          >
            {!themes.isSbf() && !isLogin && (
              <li className="hide-at-menu-break">
                <a
                  href={ROADSIDE_ASSISTANCE}
                  rel="noopener noreferrer"
                  className={classNames("nav-link color-white", { isLogin })}
                >
                  <i className="fas fa-map-marker-alt" aria-hidden="true" />
                  {language.title_text}
                </a>
              </li>
            )}
            {showNewMenu && (
              <TopHeaderMenuItem
                href={EXT_ROADSIDE_ASSISTANCE}
                title="Väghjälp"
                iconClasses="fas fa-map-marker-alt"
              />
            )}
            {showNewMenu && (
              <TopHeaderMenuItem
                href={LOGIN}
                title="Logga in"
                iconClasses="fas fa-lock-alt"
                isUnderlined="true"
              />
            )}
            {!isLogin && (
              <li className="hide-at-menu-break">
                <Logout />
              </li>
            )}
            {!isLogin && (
              <li>
                <HamburgerMenu isLogin={isLogin} />
              </li>
            )}
          </ul>
        </div>
      </div>
      {isLogin && <PrimaryNav />}
    </header>
  );
}

export default TopHeader;
