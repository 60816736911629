import React from "react";

export function ExternalMenuItem(props) {
  return (
    <li>
      <a
        href={props.href}
        rel="noopener"
        activeClassName="active"
        className="nav-link-primary-nav"
      >
        {props.title}
      </a>
    </li>
  );
}

export default ExternalMenuItem;
