import classNames from "classnames";
import React from "react";

export function TopHeaderMenuItem(props) {
  return (
    <li>
      <a
        href={props.href}
        rel="noopener"
        className={classNames("nav-link nav-golden-link", {
          underlined: props.isUnderlined,
        })}
      >
        <i className={props.iconClasses} aria-hidden="true" />
        {props.title}
      </a>
    </li>
  );
}

export default TopHeaderMenuItem;
