import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as shop } from "register";
import { paymentReducer } from "sign-insurance-components";
import { reducer as step } from "step-counter";
import { userConstants } from "../constants";
import authentication from "./authentication.reducer";
import banner from "./banner.reducer";
import benefits from "./benefits.reducer";
import footer from "./footer.reducer";
import hamburger from "./hamburger.reducer";
import insurances from "./insurances.reducer";
import invoices from "./invoices.reducer";
import magazines from "./magazines.reducer";
import modal from "./modal.reducer";
import snackbar from "./snackbar.reducer";
import user from "./user.reducer";
import family from "./family.reducer";
import policy from "./policy.reducer";
import abError from "./abError.reducer";

const { USER_LOGOUT } = userConstants;

const appReducer = combineReducers({
  authentication,
  insurances,
  user,
  abError,
  benefits,
  form,
  magazines,
  invoices,
  modal,
  shop,
  step,
  banner,
  footer,
  snackbar,
  hamburger,
  payment: paymentReducer,
  family,
  policy,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    const { footer } = state;
    state = { footer };
  }
  return appReducer(state, action);
};

export default rootReducer;
