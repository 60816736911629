import classNames from "classnames";
import React from "react";
import HorizontalPromotionalBanner from "../../components/banner/HorizontalPromotionalBanner";
import Benefits from "../../components/benefit/Benefits";
import UserWelcomeMessage from "../../components/helper/UserWelcomeMessage";
import InsuranceCardSimple from "../../components/insurance/InsuranceCardSimple";
import BenefitCardSimple from "../../components/benefit/BenefitCardSimple";
import Insurances from "../../components/insurance/Insurances";
import Vehicles from "../../components/insurance/Vehicles";
import Magazines from "../../components/magazine/Magazines";
import InfoCardContainer from "../../components/info-card/InfoCardContainer";
import { themes } from "../../helpers/config";
import lang from "../../helpers/lang-constants";

const language = lang.customer.views.home_page;

function HomePage() {
  return (
    <div className={classNames("container")}>
      <UserWelcomeMessage heading={language.heading_text} />
      <InfoCardContainer />
      <Insurances simpleCard={Boolean(true)} component={InsuranceCardSimple} />
      {themes.isAssistans() && <Vehicles />}
      <HorizontalPromotionalBanner className="horizontal-promotional-banner" />
      <Benefits component={BenefitCardSimple} />
      <Magazines />
    </div>
  );
}

export default HomePage;
