import { React, useState } from "react";
import FormCard from "../../../components/formCard/FormCard";
import RadioInput from "../../../components/helper/RadioInput";
import lang from "../../../helpers/lang-constants";

const language = lang.customer.views.claim.questions.question_collision;

function QuestionCollision(props) {
  const [collisionResponse, setCollisionResponse] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const onChangeRadiobuttonHandler = (value) => {
    if (value === "nej") {
      props.setState("nej");
      props.showNextQuestion(props.name);
      setCollisionResponse("nej");
    } else if (value === "ja") {
      setCollisionResponse("ja");
    }
  };

  const onChangeCategoryHandler = (event) => {
    const { value } = event.target;
    if (value === "Ej avgjort ännu") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      props.setState(value);
      props.showNextQuestion(props.name);
    }
  };

  const categories = [
    { title: "Ej avgjort ännu", value: "Ej avgjort ännu" },
    { title: "Ja", value: "ja" },
    { title: "Nej", value: "nej" },
  ];

  const missingResponse = "Välj...";

  const category = categories.map((alternative) => (
    <option value={alternative.value} key={alternative.title}>
      {alternative.title}
    </option>
  ));

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title="Kollisionsinfo"
      description={language.description_text}
    >
      <div className="collision-container">
        <div className="radio-container">
          <RadioInput
            value="ja"
            selected={collisionResponse}
            text="Ja"
            onChange={onChangeRadiobuttonHandler}
          />
          <RadioInput
            value="nej"
            selected={collisionResponse}
            text="Nej"
            onChange={onChangeRadiobuttonHandler}
          />
        </div>
        {collisionResponse === "ja" && (
          <div className="collision-responsible-container">
            <label htmlFor="custom-insurance-company-input">
              {language.question_responsible}
            </label>
            <label htmlFor="QuestionDamageTypeCategory">
              <select
                className="damage-type-select"
                name="QuestionDamageTypeCategory"
                id="QuestionDamageTypeCategory"
                onChange={onChangeCategoryHandler}
                defaultValue="initialValue"
              >
                <option value="initialValue" disabled hidden>
                  {missingResponse}
                </option>
                {category}
              </select>
            </label>
          </div>
        )}
        {showWarning && (
          <h2 className="collision-warning">{language.warning}</h2>
        )}
      </div>
    </FormCard>
  );
}

export default QuestionCollision;
