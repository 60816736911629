import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import {
  actions as stepActions,
  selectors as stepSelectors,
} from "step-counter";
import Register from "register";
import {
  CompleteInsuranceForm,
  InitialInsuranceForm,
  InsuranceFormBackground,
} from "sign-insurance-components";
import { insuranceSelectors } from "../../selectors";

const { getStep } = stepSelectors;
const { getUpdating } = insuranceSelectors;

function SignInsuranceFormContainer({ userData }) {
  return (
    <div className={classNames("container", "sign-insurance-form")}>
      <InsuranceFormBackground title="Dina uppgifter">
        <InitialInsuranceForm
          isLoggedIn={Boolean(true)}
          initialCustomerCode={userData.get("customerCode")}
          disabled
        />
        <CompleteInsuranceForm userData={userData} />
      </InsuranceFormBackground>
    </div>
  );
}

const mapStateToProps = (state) => ({
  signing: getUpdating(state, "signNewInsurance"),
  step: getStep(state),
});

export default connect(mapStateToProps)(SignInsuranceFormContainer);
