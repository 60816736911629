import { errorConstants } from "../constants";

function handleResponse(response) {
  return response.json().then((data) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(data);
    }
    if (response.status < 500) {
      const error = new Error(data.details || data);
      error.response = response;
      return Promise.reject(error);
    }
    return Promise.reject(new Error(errorConstants.GENERIC_ERROR_MESSAGE));
  });
}

const baseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "https://ab.dev.assi.nu/v1";
  }
  return "https://ab.assi.nu/v1";
};

const getErrorStatus = () => {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${baseUrl()}/information,get-information-type/EXTERNAL_MESSAGE`,
    requestOptions
  ).then(handleResponse);
};

export default {
  getErrorStatus,
};
