import React from "react";
import RowContainer from "../helper/RowContainer";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";
import { apiConstants } from "../../constants";

const ONE_YEAR = 12;
const language = lang.customer.components.insurance.show_co_insured;

function ShowCoInsured({ coInsureds }) {
  const setCustomerCode = (applicant) => {
    if (themes.isAssistans()) {
      return `${applicant.slice(0, 6)} - ****`;
    }
    return applicant;
  };

  return (
    <div className="flex column">
      <RowContainer className="co-insured-header">
        <span itemType="leftTitle" className="label-bold">
          {language.title_text}
        </span>
      </RowContainer>
      {coInsureds.map((coInsured, i) => {
        if (!coInsured.get("isActive", false)) {
          return;
        }
        return (
          <div
            className="container"
            key={coInsured.getIn(["fellowApplicant", "socialNumber"], "")}
          >
            <RowContainer className="co-insured-border row align-second-end-phone">
              <span
                itemType="leftContent"
                className="content"
              >{`${coInsured.getIn(
                ["fellowApplicant", "firstName"],
                ""
              )} ${coInsured.getIn(["fellowApplicant", "lastName"])}`}</span>
              <span
                itemType="centerContent"
                className="content align-text-right"
              >
                {setCustomerCode(
                  coInsured.getIn(["fellowApplicant", "socialNumber"], "")
                )}
              </span>
              <span
                itemType="rightContent"
                className="content align-text-right"
              >{`${Math.round(
                coInsured.get("monthPriceIncludingVAT") * ONE_YEAR
              )} ${language.annual_fee_text}`}</span>
            </RowContainer>
            {coInsured.get("termsAndConditions").length > 0 && (
              <RowContainer className="row align-second-end-phone co-insured-border">
                <span
                  itemType="leftContent"
                  className="flex row align-center co-insurance-terms"
                >
                  <a
                    href={apiConstants.POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {language.terms_text}{" "}
                    {coInsured.get("termsAndConditions", "")}
                    <i className="fas fa-chevron-right" />
                  </a>
                </span>
              </RowContainer>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ShowCoInsured;
