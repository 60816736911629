import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { routesConstants } from "../../constants";
import { userSelectors, insuranceSelectors } from "../../selectors";
import lang, { formatString } from "../../helpers/lang-constants";
import {
  findInsuranceForVehicle,
  getInsuranceIconByVehicleType,
  findDeductibleForVehicle,
} from "./utils";

const language = lang.customer.components.insurance.vehicle_card;

const { SHOP } = routesConstants;
const { getVehicle } = userSelectors;
const { getUserInsurances } = insuranceSelectors;

function VehicleCard({
  vehicleIcon,
  activeInsurance,
  isInsurable,
  vehicle,
  activeDeductible,
}) {
  let vehicleStatusText = language.missing_insurance_text;
  let backgroundStyle = {};
  let vehicleStatusStyle = {};
  let iconStyle = {};

  if (activeInsurance) {
    if (activeDeductible) {
      vehicleStatusText = `${formatString(
        language.has_insurance_text,
        activeInsurance.get("productName")
      )} ${formatString(
        language.and_deductible_text,
        activeDeductible.get("productName")
      )}`;
    } else {
      vehicleStatusText = formatString(
        language.has_insurance_text,
        activeInsurance.get("productName")
      );
    }
  } else if (!isInsurable) {
    iconStyle = { color: "rgba(0, 0, 0, 0.15)" };
    vehicleStatusText = language.ineligible_vehicle_text;
  } else {
    vehicleStatusText = language.missing_insurance_text;
    vehicleStatusStyle = { color: "#f37676" };
    backgroundStyle = { backgroundColor: "#ffebeb" };
  }

  return (
    <div
      className="vehicle-card card flex column align-center"
      style={backgroundStyle}
    >
      <div className="vehicle-background-image flex justify-center">
        <i className={`${vehicleIcon} vehicle-icon`} style={iconStyle} />
      </div>
      <span className="vehicle-regnr align-text-center">
        {vehicle.getIn(["data", "regno"], "")}
      </span>
      <span
        className="vehicle-info-text align-always-center"
        style={vehicleStatusStyle}
      >
        {vehicleStatusText}
      </span>
      {!activeInsurance && isInsurable && (
        <Link to={SHOP}>
          <button
            type="button"
            className={classNames(
              "vehicle-button button flex align-center justify-center button-primary"
            )}
          >
            {language.button.text}
          </button>
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = (state, { id }) => {
  const vehicle = getVehicle(state, id);
  const insurances = getUserInsurances(state);
  return {
    vehicle,
    vehicleIcon: getInsuranceIconByVehicleType(vehicle.get("type")),
    activeInsurance: findInsuranceForVehicle(insurances, vehicle),
    isInsurable: vehicle.get("isInsurable"),
    activeDeductible: findDeductibleForVehicle(insurances, vehicle),
  };
};

export default connect(mapStateToProps)(VehicleCard);
