import Parser from "html-react-parser";
import React from "react";
import { connect } from "react-redux";
import { benefitSelectors } from "../../selectors";
import lang from "../../helpers/lang-constants";

const { getBenefit } = benefitSelectors;
const language = lang.customer.components.benefit.benefit_card;

function BenefitCard({ item }) {
  const code = item.get("code");
  const affiliateLink = item.get("affiliateLink", "");
  return (
    <div className="benefit benefit-card-container flex">
      <div
        className="benefit-image background-cover background-center"
        style={{ backgroundImage: `url(${item.get("url", "")})` }}
      />
      <div className="benefit benefit-description flex-grow flex column">
        <h4>{item.getIn(["title", "rendered"], "title")}</h4>
        <span className="description-text">
          {Parser(item.get("description", ""))}
        </span>
        {code.length >= 1 && (
          <span className="read-more-link">
            <semi-bold>{language.promo_code_text}</semi-bold>: {code}
          </span>
        )}
        {affiliateLink && (
          <span className="read-more-link">
            <a
              className="gold-underscore "
              href={affiliateLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language.book_link_text}
            </a>
          </span>
        )}

        <span>
          <a
            href={item.get("link", "")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {language.read_more_text.normal_text}{" "}
            <span className="gold-underscore">
              {language.read_more_text.underscored_text}
            </span>
          </a>
        </span>
      </div>
    </div>
  );
}

const mapStateToProps = (state, { id }) => ({
  item: getBenefit(state, id),
});

export default connect(mapStateToProps)(BenefitCard);
