import React from "react";
import InlineBanner from "./InlineBanner";
import Button from "../helper/Button";
import lang from "../../helpers/lang-constants";

const language = lang.customer.components.banner.banner_direct_debit;

const btnClick = () => {
  window.open("https://ag.assistansbolaget.nu/");
};
function BannerDirectDebit({ heading, content, signDirectDebit }) {
  return (
    <div className="direct-debit-banner">
      <InlineBanner
        heading={heading}
        content={content ? `${content}` : `${language.content_text}`}
        icon="icon-autogiro gold-icon"
      >
        <Button
          type="button"
          className="button button-primary sign-insurance"
          onClick={() => btnClick()}
        >
          {language.button.text}
        </Button>
      </InlineBanner>
    </div>
  );
}

export default BannerDirectDebit;
