import { env } from "../../../helpers/config";
import { COUNTRY_CODES } from "../constants";
import BaseApi from "../base_urls.skeleton";

const base_urls = new BaseApi();
const country_code = COUNTRY_CODES.SE;

if (env.isDevelopment()) {
  base_urls.API = "http://api.test-cluster.assistansbolaget.nu";
  base_urls.AUTH_API = "http://auth.test-cluster.assistansbolaget.nu";
  base_urls.WP = "http://sbf.test-cluster.assistansbolaget.nu";
  base_urls.ETRACK =
    "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=850e5ad6-891c-434b-bdac-426b7eb4f806&catondetail=1&dc=58663";
  base_urls.COUNTRY_CODE = country_code;
} else if (env.isStaging()) {
  base_urls.API = "http://api-prod.test-cluster.assistansbolaget.nu";
  base_urls.AUTH_API = "http://auth-prod.test-cluster.assistansbolaget.nu";
  base_urls.WP = "http://sbf.test-cluster.assistansbolaget.nu";
  base_urls.ETRACK =
    "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=850e5ad6-891c-434b-bdac-426b7eb4f806&catondetail=1&dc=58663";
  base_urls.COUNTRY_CODE = country_code;
} else if (env.isProduction()) {
  base_urls.API = "http://api.test-cluster.assistansbolaget.nu";
  base_urls.AUTH_API = "https://auth-cluster.assistansbolaget.nu";
  base_urls.WP = "http://sbf.test-cluster.assistansbolaget.nu";
  base_urls.ETRACK =
    "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=850e5ad6-891c-434b-bdac-426b7eb4f806&catondetail=1&dc=58663";
  base_urls.COUNTRY_CODE = country_code;
}

export default base_urls;
