import React from "react";

function FormCard(props) {
  const { showList = [], name, showTitle = true } = props;
  const showState = showList.find((item) => item.name === name)?.show;
  const order = showList.find((item) => item.name === name)?.index + 1;

  return (
    <div className={`form-card${showState ? "" : " disabled"}`}>
      {showTitle && (
        <h4>
          {order}. {props.title}
        </h4>
      )}
      <h6>{props.description}</h6>
      {props.children}
    </div>
  );
}

export default FormCard;
