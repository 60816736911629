const authHeader = (contentType = "application/json") => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return {
      Authorization: `Bearer ${user.token}`,
      "Content-Type": contentType,
      withCredentials: true,
    };
  }
  return {};
};

export default authHeader;
