import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { smsActions } from "../../../actions";
import lang from "../../../helpers/lang-constants";
import SmsLoginForm from "./SmsLoginForm";
import SmsTokenForm from "./SmsTokenForm";
import { authSelectors } from "../../../selectors";

const language = lang.customer.components.login.sms.sms_login;
const { getLoading, isSmsTokenSent } = authSelectors;

function SmsLogin({
  requestSmsToken,
  loadState,
  isSmsTokenSent,
  smsAuthenticate,
}) {
  const isSmsLoading = loadState.get("requestToken", false);
  const isTokenLoading = loadState.get("smsAuth", false);
  return (
    <div className="content flex column">
      <span>
        {language.description_text}
        <br />
        <br />
        {language.label_text}
      </span>
      {!isSmsTokenSent && (
        <SmsLoginForm
          requestSmsToken={requestSmsToken}
          isLoading={isSmsLoading}
        />
      )}
      {isSmsTokenSent && (
        <SmsTokenForm
          isLoading={isTokenLoading}
          smsAuthenticate={smsAuthenticate}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loadState: getLoading(state),
  isSmsTokenSent: isSmsTokenSent(state),
});

const mapDispatchToProps = (dispatch) => {
  const { requestSmsToken, smsAuthenticate } = smsActions;
  return {
    requestSmsToken: () => dispatch(requestSmsToken()),
    smsAuthenticate: () => dispatch(smsAuthenticate()),
  };
};

SmsLogin.propTypes = {
  requestSmsToken: PropTypes.func.isRequired,
  smsAuthenticate: PropTypes.func.isRequired,
  isSmsTokenSent: PropTypes.bool.isRequired,
  loadState: ImmutablePropTypes.mapContains({
    bankIdAuth: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsLogin);
