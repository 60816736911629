import React, { Component } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  insuranceActions,
  userActions,
  familyActions,
  modalActions,
} from "../../actions";
import { insuranceSelectors } from "../../selectors";
import ListComponent from "../helper/ListComponent";
import LoadingSpinner from "../helper/LoadingSpinner";
import Heading from "../helper/Heading";
import lang from "../../helpers/lang-constants";
import policyActions from "../../actions/policy.actions";
import Button from "../helper/Button";

const { getUpdating, getItemIds, hasOnlyKeyTabInsurance } = insuranceSelectors;

const language = lang.customer.components.insurance.insurance;

class Insurances extends Component {
  componentDidMount() {
    const { fetchInsurances, fetchUserData, fetchFamilyInHouseHold } =
      this.props;
    fetchInsurances();
    fetchUserData();
    fetchFamilyInHouseHold();
  }

  componentDidUpdate(prevProps) {
    const { addInsurancePolicyData, orderNumbers } = this.props;
    if (prevProps.orderNumbers !== orderNumbers) {
      addInsurancePolicyData(orderNumbers);
    }
  }

  render() {
    const {
      isLoading,
      itemsIds,
      component: Component,
      simpleCard = false,
      isOnlyKeyTabs,
      openEndInsuranceModal,
      location,
    } = this.props;

    const isInsurancePage = location.pathname.includes("minaforsakringar");
    return (
      <div
        className={classNames("container insurances", {
          "insurance-card-simple-full-width": simpleCard,
        })}
      >
        {itemsIds.size === 0 && isLoading && (
          <div>
            <Heading
              heading={language.heading_text}
              isPrimary={Boolean(false)}
            />
            <LoadingSpinner isLoading={isLoading} size={25} />
          </div>
        )}
        {itemsIds.size > 0 && !isOnlyKeyTabs && (
          <>
            <ListComponent
              ids={itemsIds}
              component={Component}
              direction="column"
              isPrimaryHeading={false}
              heading={language.heading_text}
              isLoading={isLoading}
            />
            {isInsurancePage && (
              <Button
                type="button"
                itemType="rightContent"
                btnType="end-insurance"
                onClick={() => openEndInsuranceModal()}
                style={{ margin: "auto" }}
              >
                Avsluta försäkringar
              </Button>
            )}
          </>
        )}
      </div>
    );
  }
}
Insurances.propTypes = {
  fetchInsurances: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  fetchFamilyInHouseHold: PropTypes.func.isRequired,
  addInsurancePolicyData: PropTypes.func.isRequired,
  orderNumbers: PropTypes.oneOfType([
    ImmutablePropTypes.setOf(PropTypes.oneOfType([PropTypes.string])),
    ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.string)),
    ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    PropTypes.object,
  ]).isRequired,
  itemsIds: PropTypes.oneOfType([
    ImmutablePropTypes.setOf(PropTypes.oneOfType([PropTypes.string])),
    ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.string)),
    ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    PropTypes.object,
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  simpleCard: PropTypes.bool,
  isOnlyKeyTabs: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  openEndInsuranceModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: getUpdating(state, "fetchUserInsurances"),
  itemsIds: getItemIds(state),
  isOnlyKeyTabs: hasOnlyKeyTabInsurance(state),
  orderNumbers: getItemIds(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchInsurances: () => dispatch(insuranceActions.getInsurances()),
  fetchFamilyInHouseHold: () =>
    dispatch(familyActions.fetchFamilyInHouseHold()),
  fetchUserData: () => dispatch(userActions.getUserData()),
  addInsurancePolicyData: (orderNumbers) =>
    dispatch(policyActions.getAllInsurancePolicyData(orderNumbers)),
  openEndInsuranceModal: (item) => {
    dispatch(modalActions.openModal("endInsuranceModal", item));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Insurances);
