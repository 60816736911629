import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";
import Input from "../../helper/Input";
import Button from "../../helper/Button";
import LoadingSpinner from "../../helper/LoadingSpinner";
import lang from "../../../helpers/lang-constants";

const language = lang.customer.components.login.sms.sms_token_form;

function SmsTokenForm({ smsAuthenticate, handleSubmit, isLoading }) {
  return (
    <form
      onSubmit={handleSubmit(smsAuthenticate)}
      className="token-form flex column align-center justify-center"
    >
      <Field
        name="token"
        component={Input}
        placeholder={language.placeholder}
        className="token-input"
        autoFocus
      />
      <Button type="submit">
        <LoadingSpinner
          isLoading={isLoading}
          text={language.submit_button.text}
        />
      </Button>
    </form>
  );
}

SmsTokenForm.propTypes = {
  smsAuthenticate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: "smsTokenForm",
})(SmsTokenForm);
