import React from "react";
import { connect } from "react-redux";
import { bankIdActions, modalActions } from "../../../actions";
import BankIdLoginForm from "./BankIdLoginForm";

class BankIdCollect extends React.Component {
  componentWillUnmount() {
    const { closeModal } = this.props;
    closeModal();
  }

  componentDidMount() {
    const { collectBankId, match } = this.props;
    const { orderRef } = match.params;
    collectBankId(orderRef);
  }

  render() {
    return <BankIdLoginForm />;
  }
}

const mapDispatchToProps = (dispatch) => {
  const { bankIdWaitForCollect } = bankIdActions;
  return {
    collectBankId: (orderRef) => {
      dispatch(modalActions.openModal("bankId", null, null, orderRef));
      dispatch(bankIdWaitForCollect(orderRef));
    },
    closeModal: () => {
      dispatch(modalActions.closeModal("bankId"));
    },
  };
};

export default connect(null, mapDispatchToProps)(BankIdCollect);
