const isOpen = ({ snackbar }) => snackbar.get("isOpen", false);
const getSnackbarType = ({ snackbar }) => {
  if (snackbar.getIn(["snackbarType", "failure"], false)) return "failure";
  if (snackbar.getIn(["snackbarType", "success"], false)) return "success";
  return "";
};
const getTitle = ({ snackbar }) => snackbar.get("title", "");
const getMessage = ({ snackbar }) => snackbar.get("message", "");

export default {
  getSnackbarType,
  getTitle,
  getMessage,
  isOpen,
};
