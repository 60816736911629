import React from "react";
import lang from "../../helpers/lang-constants";
import ExternalMenuItem from "./ExternalMenuItem";
import { externalRoutesConstants } from "../../constants";

const language = lang.customer.components.navbar.top_header;
const {
  EXT_GET_INSURANCE,
  EXT_TERMS,
  EXT_ABOUT_US,
  EXT_PRODUCTS,
  EXT_CONTACT,
} = externalRoutesConstants;

function PrimaryNav() {
  return (
    <div id="primary-nav">
      <div className="nav-container">
        <nav role="navigation">
          <ul className="primary-menu">
            <ExternalMenuItem
              href={EXT_GET_INSURANCE}
              title="TECKNA FÖRSÄKRING"
            />
            <ExternalMenuItem href={EXT_TERMS} title="VILLKOR" />
            <ExternalMenuItem href={EXT_ABOUT_US} title="OM OSS" />
            <ExternalMenuItem href={EXT_PRODUCTS} title="TRAFIKFÖRSÄKRING" />
            <ExternalMenuItem href={EXT_CONTACT} title="KONTAKT" />
          </ul>
        </nav>
      </div>
    </div>
  );
}
export default PrimaryNav;
