import { authHeader } from "../helpers";
import { apiConstants } from "../constants";

const handleResponse = (response) =>
  response.text().then((text) => text && JSON.parse(text));

function excludeApartmentAddresses(relations) {
  const includedRelations = [];
  for (let i = 0; i < relations.length; i++) {
    // This excluding of things with lgh was done at request from AB because
    // their MerInfo service had issues with "something..".
    // Very hacky solution, the spaces are important so we do not exclude names that have lgh in it.
    if (relations[i].address.toLowerCase().indexOf(" lgh ") === -1) {
      includedRelations.push(relations[i]);
    }
  }
  return includedRelations;
}

const addFriend = (payload) =>
  fetch(apiConstants.API.SMS.SEND_APP_LINK, {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(payload),
  })
    .then(handleResponse)
    .then((data) => data);

const fetchFamilyInHouseHold = () => {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(apiConstants.API.USER.RELATIONS, reqOpt)
    .then(handleResponse)
    .then((data) => {
      const transformedData = data;
      if (transformedData.relations && transformedData.relations.length > 0) {
        transformedData.relations = excludeApartmentAddresses(
          transformedData.relations
        );
      }
      return transformedData;
    })
    .then((data) => data);
};

export default {
  addFriend,
  fetchFamilyInHouseHold,
};
