import { getFormValues, isInvalid } from "redux-form";
import { apiConstants, familyConstants, errorConstants } from "../constants";
import { familyService } from "../services";
import modalActions from "./modal.actions";
import snackbarActions from "./snackbar.actions";
import language from "../helpers/lang-constants";

const lang = language.customer.components.snack_bar;

const {
  ADD_FRIEND_REQUEST,
  ADD_FRIEND_SUCCESS,
  ADD_FRIEND_FAILURE,
  FETCH_FAMILY_IN_HOUSE_HOLD_REQUEST,
  FETCH_FAMILY_IN_HOUSE_HOLD_SUCCESS,
  FETCH_FAMILY_IN_HOUSE_HOLD_FAILURE,
} = familyConstants;
const { SUCCESS_CODE_200 } = apiConstants;
const RADIX = 10;

const addFriendRequest = () => ({
  type: ADD_FRIEND_REQUEST,
});
const addFriendSuccess = (data) => ({
  type: ADD_FRIEND_SUCCESS,
  data,
});
const addFriendFailure = (error) => ({
  type: ADD_FRIEND_FAILURE,
  error,
});
const addFriend = () => (dispatch, getState) => {
  const formValues = getFormValues("inviteFriendForm")(getState());
  const invalid = isInvalid("inviteFriendForm")(getState());

  if (!formValues || invalid) return;
  dispatch(addFriendRequest());
  familyService.addFriend(formValues).then(
    (data) => {
      if (data.statusCode !== SUCCESS_CODE_200) {
        dispatch(addFriendFailure(data));
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.GENERIC_ERROR_MESSAGE
          )
        );
      } else {
        dispatch(addFriendSuccess(data));
        dispatch(modalActions.closeModal());
        dispatch(
          snackbarActions.openSnackbar("success", lang.sms_invite.success)
        );
      }
    },
    (err) => {
      dispatch(addFriendFailure(errorConstants.GENERIC_ERROR_MESSAGE));
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      );
    }
  );
};

const fetchFamilyInHouseHoldRequest = () => ({
  type: FETCH_FAMILY_IN_HOUSE_HOLD_REQUEST,
});
const fetchFamilyInHouseHoldSuccess = (familyInHouseHold) => ({
  type: FETCH_FAMILY_IN_HOUSE_HOLD_SUCCESS,
  familyInHouseHold,
});
const fetchFamilyInHouseHoldFailure = (error) => ({
  type: FETCH_FAMILY_IN_HOUSE_HOLD_FAILURE,
  error,
});

const fetchFamilyInHouseHold = () => (dispatch) => {
  dispatch(fetchFamilyInHouseHoldRequest());
  familyService.fetchFamilyInHouseHold().then((data) => {
    if (parseInt(data.statusCode, RADIX) === SUCCESS_CODE_200)
      dispatch(fetchFamilyInHouseHoldSuccess(data));
    else dispatch(fetchFamilyInHouseHoldFailure(data.statusMessage));
  });
};

export default {
  addFriend,
  fetchFamilyInHouseHold,
};
