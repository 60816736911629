import { Map } from "immutable";

const getCurrentInsurancePolicy = ({ policy }) => policy.get("current", {});

const getUpcomingInsurancePolicy = ({ policy }) => policy.get("upcoming", {});

const getDateInsurancePolicy = ({ policy }) => policy.get("dateActive", {});

const getBlobInsurancePolicy = ({ policy }) => policy.get("blob", "");

const getLoading = ({ policy }) => policy.get("loading", Map());
const getUpdating = ({ policy }) => policy.get("updating", false);

export default {
  getCurrentInsurancePolicy,
  getUpcomingInsurancePolicy,
  getDateInsurancePolicy,
  getBlobInsurancePolicy,
  getLoading,
  getUpdating,
};
