import { apiConstants, errorConstants } from "../constants";
import { fetch } from "../helpers/fetch";

function handleResponse(response) {
  return response.json().then((data) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(data);
    }
    if (response.status < 500) {
      const error = new Error(data.details || data);
      error.response = response;
      return Promise.reject(error);
    }
    return Promise.reject(new Error(errorConstants.GENERIC_ERROR_MESSAGE));
  });
}

function authenticate(personalNumber) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      rp: "assistansbolaget",
    }),
  };
  return fetch(apiConstants.AUTH_API.BANKID.AUTHENTICATE, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

function collect(orderRef) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    retries: 5,
    isRetryDelayExponential: true,
    body: JSON.stringify({
      rp: "assistansbolaget",
      orderRef,
    }),
  };
  return fetch(apiConstants.AUTH_API.BANKID.COLLECT, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

function cancel(orderRef) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      rp: "assistansbolaget",
      orderRef,
    }),
  };

  return fetch(apiConstants.AUTH_API.BANKID.CANCEL, requestOptions)
    .then(handleResponse)
    .then((cancelResponse) => {
      localStorage.removeItem("orderRef");
      return cancelResponse;
    });
}

export default {
  authenticate,
  collect,
  cancel,
};
