import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import RowContainer from "../helper/RowContainer";
import lang from "../../helpers/lang-constants";
import { insuranceSelectors } from "../../selectors";
import formatDate from "../../helpers/formatter";

const { getItemGroupByOrderNumberState } = insuranceSelectors;

const language = lang.customer.components.invoice.invoice_insurance_card;

function InvoiceInsuranceCard({ item, insuranceGroup }) {
  const insurancePeriod = insuranceGroup.size
    ? lang.formatString(
        language.insurance_period_date,
        formatDate(insuranceGroup.first().get("startDate", "")),
        formatDate(insuranceGroup.first().get("endDate", ""))
      )
    : "";

  const transactions = item.get("transactions");
  const insuranceNames = transactions.map((transaction, i) => (
    <div key={i} className="flex insurance-description insurance-name-row">
      <RowContainer className="flex row wrap">
        <span itemType="leftTitle" className="label-bold">
          {language.insurance_pre_text} {transaction.get("articleName", "")}
        </span>
        <span itemType="rightTitle" className="label-bold align-always-right">
          {Math.round(transaction.get("amount", 0))} kr
        </span>
      </RowContainer>
    </div>
  ));
  const remainingAmount = item.get("remainingAmount", 0);
  const ocr = item.get("ocr", "");
  return (
    <div className="insurance-data flex column wrap">
      {
        // <RowContainer className="flex row">
        //   <span itemType="leftContent" style={{ color: "#7b7b7b" }}>
        //     Försäkringar
        //   </span>
        //   <span
        //     itemType="rightContent"
        //     className="align-always-right"
        //     style={{ color: "#7b7b7b" }}
        //   >
        //     Försäkringspremie
        //   </span>
        // </RowContainer>
      }
      <hr className="insurance-divider" />
      <RowContainer className="flex row">
        <span itemType="leftContent" className="label-bold">
          {language.insurance_period}
        </span>
        <span itemType="rightContent" className="align-always-right label-bold">
          {insurancePeriod}
        </span>
      </RowContainer>
      {insuranceNames}
      {item.get("fee") > 0 && (
        <RowContainer className="flex row wrap">
          <span itemType="leftTitle" className="label-bold">
            {language.invoice_cost_text}
          </span>
          <span itemType="rightTitle" className="label-bold align-always-right">
            {Math.round(item.get("fee", 0))} kr
          </span>
        </RowContainer>
      )}
      <RowContainer className="flex row wrap ocr-row">
        <span itemType="leftTitle" className="label-bold">
          {language.ocr_text}
        </span>
        <span itemType="leftContent" className="content-primary">
          {ocr}
        </span>
        <span itemType="rightTitle" className="label-grey align-always-right">
          {language.to_pay_text}
        </span>
        <h4 itemType="rightContent" className="align-always-right">
          {remainingAmount} {language.currency_display_text}
        </h4>
      </RowContainer>
    </div>
  );
}
const makeMapStateToProps = () => {
  const getInsurance = getItemGroupByOrderNumberState();
  return (state, { item }) => ({
    insuranceGroup: getInsurance(
      state,
      item.get("orderNumber") ? item.get("orderNumber").toString() : ""
    ),
  });
};
InvoiceInsuranceCard.propTypes = {
  name: PropTypes.string,
};
export default connect(makeMapStateToProps)(InvoiceInsuranceCard);
