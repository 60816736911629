import DisabledLogin from "./DisabledLogin";
import config from "../../helpers/config";
import BankIdContainer from "./bankid/BankIdContainer";
import SmsLoginContainer from "./sms/SmsLoginContainer";

let LoginComponent;

if (config.disableLogin) {
  LoginComponent = DisabledLogin;
} else if (config.isBankdIdLogin) {
  LoginComponent = BankIdContainer;
} else {
  LoginComponent = SmsLoginContainer;
}

export { LoginComponent };
