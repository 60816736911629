import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { userActions } from "../../actions";
import { routesConstants } from "../../constants";
import lang from "../../helpers/lang-constants";

const { LOGIN } = routesConstants;
const language = lang.customer.components.navbar.logout;

function Logout({ logout, ...rest }) {
  return (
    <div
      className="logout-link"
      onClick={rest.onClick ? rest.onClick : undefined}
      onKeyPress={rest.onClick ? rest.onClick : undefined}
      tabIndex={0}
      role="button"
    >
      <NavLink
        to={LOGIN}
        onClick={logout}
        className="nav-link logout color-white flex row align-center"
      >
        {language.logout_text}
        <i className="fal fa-sign-out-alt" />
      </NavLink>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(null, mapDispatchToProps)(Logout);
