import jwtDecode from "jwt-decode";
import { getFormValues, reset } from "redux-form";
import { apiConstants, errorConstants, userConstants } from "../constants";
import { authSelectors } from "../selectors";
import { userService } from "../services";
import modalActions from "./modal.actions";
import snackbarActions from "./snackbar.actions";
import language from "../helpers/lang-constants";
import { isAuthorized } from "./functions";

const { getUserToken } = authSelectors;
const lang = language.customer.components.snack_bar;

const {
  USER_LOGOUT,
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  FETCH_USER_FELLOW_APPLICANTS_DATA_REQUEST,
  FETCH_USER_FELLOW_APPLICANTS_DATA_SUCCESS,
  FETCH_USER_FELLOW_APPLICANTS_FAILURE,
  UPDATE_USER_VEHICLE_DATA_REQUEST,
  UPDATE_USER_VEHICLE_DATA_SUCCESS,
  UPDATE_USER_VEHICLE_DATA_FAILURE,
  FETCH_USER_VEHICLE_DATA_REQUEST,
  FETCH_USER_VEHICLE_DATA_SUCCESS,
  FETCH_USER_VEHICLE_DATA_FAILURE,
} = userConstants;

const { SUCCESS_CODE_200 } = apiConstants;
const RADIX = 10;

const requestUpdate = () => ({
  type: UPDATE_USER_DATA_REQUEST,
});
const updateSuccess = (statusMessage, userData) => ({
  type: UPDATE_USER_DATA_SUCCESS,
  statusMessage,
  userData,
});
const updateFailure = (error) => ({
  type: UPDATE_USER_DATA_FAILURE,
  error,
});

const updateUser = (form) => (dispatch, getState) => {
  const body = getFormValues(form)(getState());
  if (!body || (!body.email && !body.mobilePhone)) return;
  dispatch(requestUpdate());
  userService
    .updateUser(body)
    .then(({ statusCode, statusMessage }) => {
      if (statusCode === SUCCESS_CODE_200) {
        dispatch(updateSuccess(statusMessage, body));
        dispatch(reset("detailsForm"));
        dispatch(
          snackbarActions.openSnackbar("success", lang.update_user.success)
        );
        dispatch(modalActions.closeModal());
      } else {
        dispatch(snackbarActions.openSnackbar("failure", statusMessage));
        dispatch(updateFailure(statusMessage));
      }
    })
    .catch((err) => {
      if (!isAuthorized(err)) {
        dispatch(
          snackbarActions.openSnackbar(
            "success",
            errorConstants.SESSION_ERROR_MESSAGE
          )
        );
        return;
      }
      throw err;
    })
    .catch((err) =>
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      )
    );
};

const requestUserData = () => ({
  type: GET_USER_DATA_REQUEST,
});
const userDatasuccess = (userData) => ({
  type: GET_USER_DATA_SUCCESS,
  userData,
});
const userDataFailure = (error) => ({
  type: GET_USER_DATA_FAILURE,
  error,
});

const getUserData = () => (dispatch) => {
  dispatch(requestUserData());
  userService
    .getUserData()
    .then((userData) => {
      if (typeof userData === "string") dispatch(userDataFailure(userData));
      else dispatch(userDatasuccess(userData));
    })
    .catch((err) => {
      if (!isAuthorized(err)) {
        dispatch(
          snackbarActions.openSnackbar(
            "success",
            errorConstants.SESSION_ERROR_MESSAGE
          )
        );
        return;
      }
      throw err;
    })
    .catch((error) => {
      dispatch(userDataFailure(error));
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      );
    });
};

const fetchUserFellowApplicantsRequest = () => ({
  type: FETCH_USER_FELLOW_APPLICANTS_DATA_REQUEST,
});
const fetchUserFellowApplicantsSuccess = (fellowApplicants) => ({
  type: FETCH_USER_FELLOW_APPLICANTS_DATA_SUCCESS,
  fellowApplicants,
});
const fetchUserFellowApplicantsFailure = (error) => ({
  type: FETCH_USER_FELLOW_APPLICANTS_FAILURE,
  error,
});

const fetchUserFellowApplicants = () => (dispatch) => {
  dispatch(fetchUserFellowApplicantsRequest());
  userService
    .fetchUserFellowApplicants()
    .then((data) => {
      if (typeof fellowApplicants === "string")
        dispatch(fetchUserFellowApplicantsFailure(data.statusMessage));
      else dispatch(fetchUserFellowApplicantsSuccess(data.fellowApplicants));
    })
    .catch((err) => {
      if (!isAuthorized(err)) {
        dispatch(
          snackbarActions.openSnackbar(
            "success",
            errorConstants.SESSION_ERROR_MESSAGE
          )
        );
        return;
      }
      throw err;
    })
    .catch((error) => {
      dispatch(fetchUserFellowApplicantsFailure(error));
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      );
    });
};

const fetchUserVehicleDataRequest = () => ({
  type: FETCH_USER_VEHICLE_DATA_REQUEST,
});
const fetchUserVehicleDataSuccess = (vehicles) => ({
  type: FETCH_USER_VEHICLE_DATA_SUCCESS,
  vehicles,
});
const fetchUserVehicleDataFailure = (error) => ({
  type: FETCH_USER_VEHICLE_DATA_FAILURE,
  error,
});
const fetchUserVehicleData = () => (dispatch, getState) => {
  const socialNumber = jwtDecode(getUserToken(getState())).CustomerCode;
  dispatch(fetchUserVehicleDataRequest());
  userService.getUserVehicles(socialNumber).then((data) => {
    if (parseInt(data.statusCode, RADIX) !== SUCCESS_CODE_200)
      dispatch(fetchUserVehicleDataFailure(data.statusMessage));
    else dispatch(fetchUserVehicleDataSuccess(data.vehicles));
  });
};

const updateUserVehicleDataRequest = () => ({
  type: UPDATE_USER_VEHICLE_DATA_REQUEST,
});
const updateUserVehicleDataSuccess = (vehicle) => ({
  type: UPDATE_USER_VEHICLE_DATA_SUCCESS,
  vehicle,
});
const updateUserVehicleDataFailure = (regNo) => ({
  type: UPDATE_USER_VEHICLE_DATA_FAILURE,
  regNo,
});
const updateUserVehicleData = (regNo) => (dispatch) => {
  dispatch(updateUserVehicleDataRequest());
  userService.getVehicleInformation(regNo).then((data) => {
    if (parseInt(data.statusCode, RADIX) !== SUCCESS_CODE_200)
      dispatch(updateUserVehicleDataFailure(regNo));
    else dispatch(updateUserVehicleDataSuccess(data.vehicle));
  });
};

const logout = () => {
  userService.logout();
  return { type: USER_LOGOUT };
};

export default {
  logout,
  getUserData,
  updateUser,
  fetchUserFellowApplicants,
  fetchUserVehicleData,
  updateUserVehicleData,
};
