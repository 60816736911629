import { useEffect } from "react";

const ClaimQuestionWrapper = ({ children, setQuestionList }) => {
  useEffect(() => {
    const questionList = children.reduce((acc, child, index) => {
      const { name } = child.props;
      return [
        ...acc,
        { show: index === 0, name, answered: false, index, errorMessage: null },
      ];
    }, []);
    setQuestionList(questionList);
  }, []);
  return children;
};

export default ClaimQuestionWrapper;
