import React from "react";

function FooterDivider() {
  return (
    <div className="container">
      <hr />
    </div>
  );
}

export default FooterDivider;
