import React from "react";
import { Route, Redirect } from "react-router-dom";
import { shouldUpdate } from "recompose";
import { routesConstants } from "../../constants";
import { history } from "../../helpers";

const { LOGIN, SHOP } = routesConstants;

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect push to={LOGIN} />
        )
      }
    />
  );
}

export default PrivateRoute;
