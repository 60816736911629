import "react-app-polyfill/ie11";
import "./helpers/bootstrapper";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "typeface-open-sans";
import App from "./App";
import ScrollToTop, { history, store } from "./helpers";
import { unregister } from "./registerServiceWorker";

//! OBS: Updated node-sass-chokidar -> dart-sass. Architecture is no longer viable for css build output, hence the css loader needs to be
//! manually changed to change language support.
// Todo: Fix output target in `package.json` depending on which build.
import "./sass/global.css";

if (!global._babelPolyfill) {
  require("babel-polyfill");
}

render(
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById("root")
);

unregister();
