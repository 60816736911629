import vehicleConstants from "../../../../constants/vehicle.constants";

const { LIGHT_TRUCK } = vehicleConstants;
export const PYRAMID_TYPE = "LB";

export default {
  type: LIGHT_TRUCK,
  matchesType: (vehicle) => {
    return vehicle.data.typeCode === PYRAMID_TYPE;
  },
  isInsurable: () => true,
};
