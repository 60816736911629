import { fromJS, Set } from "immutable";
import {
  invoicesConstants,
  isInvoice,
  isCreditCard,
  isDirectDebit,
} from "../constants";
import { updateMap } from "./functions";

const {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  CHANGE_PAYMENT_METHOD_TEMPORARY,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_PERIOD,
  SET_TEMP_PAYMENT_METHOD,
  SET_TEMP_PAYMENT_PERIOD,
} = invoicesConstants;

const initialState = fromJS({
  loading: false,
  items: {},
  itemIds: Set(),
  error: "",
  tempPaymentMethod: "",
  tempPaymentPeriod: "",
  paymentMethod: "",
  paymentPeriod: 12,
  iFrameUrl: "",
});

const invoices = (state = initialState, action) => {
  const { error, type, method, iframe, insurances } = action;
  let newState = state;
  switch (type) {
    case CHANGE_PAYMENT_METHOD_SUCCESS:
      return newState.set("iFrameUrl", iframe);
    case GET_INVOICES_REQUEST:
      newState = newState.set("loading", true);
      newState = newState.set("error", "");
      return newState;
    case GET_INVOICES_SUCCESS:
      newState = newState.set("loading", false);
      if (!action || !action.invoices) return newState;
      action.invoices.forEach((invoice) => {
        const {
          ocr,
          paymentStatus,
          remainingAmount,
          lastPaymentDate,
          dueDate,
        } = invoice;
        newState = newState.update("itemIds", (idList) =>
          idList.add(
            fromJS({
              ocr,
              paymentStatus,
              remainingAmount,
              lastPaymentDate,
              dueDate,
            })
          )
        );
        newState = updateMap(newState, "items", ocr, invoice);
      });
      return newState;
    case GET_INVOICES_FAILURE:
      newState = newState.set("loading", true);
      newState = newState.set("error", error);
      return newState;
    case CHANGE_PAYMENT_METHOD_TEMPORARY:
      newState = newState.set("tempPaymentMethod", method);
      return newState;
    case SET_PAYMENT_METHOD: {
      if (!insurances || !insurances.insurances.length) return newState;
      const paymentMethod = insurances.insurances[0].paymentMethodName;
      newState = newState.set("paymentMethod", paymentMethod);
      if (isInvoice(paymentMethod)) {
        newState = newState.set("tempPaymentMethod", 4);
      } else if (isDirectDebit(paymentMethod)) {
        newState = newState.set("tempPaymentMethod", 1);
      } else if (isCreditCard(paymentMethod)) {
        newState = newState.set("tempPaymentMethod", 3);
      }
      return newState;
    }
    case SET_PAYMENT_PERIOD: {
      if (!insurances || !insurances.insurances.length) return newState;
      const paymentPeriod = insurances.insurances[0].paymentInterval;
      newState = newState.set("paymentPeriod", paymentPeriod);
      newState = newState.set("tempPaymentPeriod", paymentPeriod);
      return newState;
    }
    case SET_TEMP_PAYMENT_METHOD: {
      newState = newState.set("tempPaymentMethod", action.paymentMethod);
      newState = newState.set("tempPaymentMethodProvider", action.provider);
      return newState;
    }
    case SET_TEMP_PAYMENT_PERIOD: {
      newState = newState.set("tempPaymentPeriod", action.paymentPeriod);
      return newState;
    }
    default:
      return newState;
  }
};

export default invoices;
