export default {
  USER_LOGOUT: "USER_LOGOUT",
  GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE: "GET_USER_DATA_FAILURE",
  UPDATE_USER_DATA_REQUEST: "UPDATE_USER_DATA_REQUEST",
  UPDATE_USER_DATA_SUCCESS: "UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_FAILURE: "UPDATE_USER_DATA_FAILURE",
  FETCH_USER_FELLOW_APPLICANTS_DATA_REQUEST:
    "FETCH_USER_FELLOW_APPLICANTS_DATA_REQUEST",
  FETCH_USER_FELLOW_APPLICANTS_DATA_SUCCESS:
    "FETCH_USER_FELLOW_APPLICANTS_DATA_SUCCESS",
  FETCH_USER_FELLOW_APPLICANTS_FAILURE:
    "FETCH_USER_FELLOW_APPLICANTS_DATA_FAILURE",
  FETCH_USER_VEHICLE_DATA_REQUEST: "FETCH_USER_VEHICLE_DATA_REQUEST",
  FETCH_USER_VEHICLE_DATA_SUCCESS: "FETCH_USER_VEHICLE_DATA_SUCCESS",
  FETCH_USER_VEHICLE_DATA_FAILURE: "FETCH_USER_VEHICLE_DATA_FAILURE",
  UPDATE_USER_VEHICLE_DATA_REQUEST: "UPDATE_USER_VEHICLE_DATA_REQUEST",
  UPDATE_USER_VEHICLE_DATA_SUCCESS: "UPDATE_USER_VEHICLE_DATA_SUCCESS",
  UPDATE_USER_VEHICLE_DATA_FAILURE: "UPDATE_USER_VEHICLE_DATA_FAILURE",
  ADD_FRIEND_REQUEST: "ADD_FRIEND_REQUEST",
  ADD_FRIEND_SUCCESS: "ADD_FRIEND_SUCCESS",
  ADD_FRIEND_FAILURE: "ADD_FRIEND_FAILURE",
  CLEAR_CURRENT_CUSTOMER_CODE: "CLEAR_CURRENT_CUSTOMER_CODE",
  SET_CURRENT_CUSTOMER_CODE: "SET_CURRENT_CUSTOMER_CODE",
};
