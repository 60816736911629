import { authHeader } from "../helpers";
import { apiConstants } from "../constants";

function getInvoices() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(apiConstants.API.USER.INVOICES(0), requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

const updatePayment = (data) => {
  if (process.env.NODE_ENV === "development") console.log("body", data);
  return fetch(apiConstants.API.SCRIVE.SIGN, {
    method: "POST",
    body: JSON.stringify(data),
    headers: authHeader(),
  })
    .then((res) => res.json())
    .then(
      (res) => {
        if (process.env.NODE_ENV === "development") console.log("RES", res);
        return res;
      },
      (err) => {
        if (process.env.NODE_ENV === "development") console.log("ERROR", err);
      }
    );
};

function handleResponse(response) {
  return response.text().then((text) => text && JSON.parse(text));
}
export default {
  getInvoices,
  updatePayment,
};
