import { createSelector } from "reselect";
import { Map, Set, fromJS } from "immutable";
import { invoicesConstants } from "../constants";

const { SET_TEMP_PAYMENT_METHOD, SET_TEMP_PAYMENT_PERIOD } = invoicesConstants;

const getInvoice = ({ invoices }, id) => invoices.getIn(["items", id], Map());
const getItemIds = ({ invoices }) => invoices.get("itemIds", Set());
const getLoading = ({ invoices }) => invoices.get("loading", false);
const getPaymentMethod = ({ invoices }) => invoices.get("paymentMethod", "");
const getIframeUrl = ({ invoices }) => invoices.get("iFrameUrl", "");
const getTempPaymentMethod = ({ invoices }) =>
  invoices.get("tempPaymentMethod", "");
const getTempPaymentMethodProvider = ({ invoices }) =>
  invoices.get("tempPaymentMethodProvider", "Scrive");
const getPaymentPeriod = ({ invoices }) => invoices.get("paymentPeriod", "");
const getTempPaymentPeriod = ({ invoices }) =>
  invoices.get("tempPaymentPeriod", "");
const getNextInvoice = ({ invoices }) => {
  let nextInvoice = fromJS({});
  nextInvoice = nextInvoice.set("dueDate", new Date("3000-12-17T03:24:00"));
  nextInvoice = nextInvoice.set("remainingAmount", 0);
  nextInvoice = nextInvoice.set("collectedInvoiceData", false);
  const inv = invoices.get("itemIds", Set());
  inv.map((invoice) => {
    const dueDate = invoice.get("dueDate");
    const paymentStatus = invoice.get("paymentStatus");
    const remainingAmount = invoice.get("remainingAmount");
    if (
      (paymentStatus.toLowerCase() === "obetald" ||
        paymentStatus.toLowerCase() === "ej betald") &&
      new Date(dueDate) < nextInvoice.get("dueDate")
    ) {
      nextInvoice = nextInvoice.set("dueDate", dueDate);
      nextInvoice = nextInvoice.set("remainingAmount", remainingAmount);
      nextInvoice = nextInvoice.set("collectedInvoiceData", true);
    }
  });
  return nextInvoice;
};

const getItemIdsState = () =>
  createSelector([getItemIds], (idsObj) => {
    let ids = Set();
    idsObj.forEach((obj) => {
      const ocr = obj.get("ocr");
      const paymentStatus = obj.get("paymentStatus");
      if (paymentStatus.toLowerCase() !== "obetald") ids = ids.add(ocr);
    });
    return ids;
  });

const setTempPaymentMethod = (value, method, provider) => ({
  type: SET_TEMP_PAYMENT_METHOD,
  identifier: value,
  paymentMethod: method,
  provider,
});

const setTempPaymentPeriod = (value) => ({
  type: SET_TEMP_PAYMENT_PERIOD,
  paymentPeriod: value,
});

export default {
  getItemIdsState,
  getLoading,
  getInvoice,
  getPaymentMethod,
  getPaymentPeriod,
  getNextInvoice,
  getTempPaymentMethod,
  getTempPaymentMethodProvider,
  getTempPaymentPeriod,
  setTempPaymentMethod,
  setTempPaymentPeriod,
  getIframeUrl,
};
