export default {
  INSURANCE_CAR: "100035",
  INSURANCE_LIGHT_TRUCK: "100021",
  INSURANCE_VETERAN_CAR: "100081",
  INSURANCE_MC: "100072",
  INSURANCE_CARAVAN: "100101",
  INSURANCE_MOBILE_HOME: "100056",
  INSURANCE_TRAILER: "100162",
  INSURANCE_CAR_TYPE: "B01",
  INSURANCE_DEDUCTIBLE_CAR_TYPE: "SJF300001",
  INSURANCE_CAROLDER_TYPE: "B02",
  INSURANCE_LIGHT_TRUCK_TYPE: "LB",
  INSURANCE_VETERAN_CAR_TYPE: "V01",
  INSURANCE_VETERAN_CAR_SEASON_TYPE: "VS",
  INSURANCE_MC_TYPE: "M01",
  INSURANCE_DEDUCTIBLE_MC_TYPE: "SJF300002",
  INSURANCE_MC_OLD_TYPE: "M02",
  INSURANCE_MC_SEASON_TYPE: "MS",
  INSURANCE_CARAVAN_TYPE: "HV",
  INSURANCE_MOBILE_HOME_TYPE: "H01",
  INSURANCE_DEDUCTIBLE_MOBILE_HOME_TYPE: "SJF300003",
  INSURANCE_MOBILE_HOME_OLD_TYPE: "H02",
  INSURANCE_TRAILER_TYPE: "SL",
  INSURANCE_CAR_KEYCHAIN_TYPE: "N01",
  INSURANCE_KEYCHAIN_TYPE: "NB1",
  INSURANCE_VIKING_TYPE: "V02",
};
