import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { routesConstants } from "../../constants";
import { benefitSelectors } from "../../selectors";

const { BENEFITS } = routesConstants;
const { getBenefit } = benefitSelectors;

function BenefitCardSimple({ item }) {
  return (
    <div className="benefit-simple benefit flex column">
      <Link to={BENEFITS}>
        <div
          className="benefit-image background-cover background-center"
          style={{ backgroundImage: `url(${item.get("url", "")})` }}
        />
        <span className="read-more-link">
          {item.getIn(["title", "rendered"], "title", "")}
        </span>
      </Link>
    </div>
  );
}

const mapStateToProps = (state, { id }) => ({
  item: getBenefit(state, id),
});

export default connect(mapStateToProps)(BenefitCardSimple);
