import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { routesConstants } from "../../constants";

const { HOME } = routesConstants;

function Button({ children, btnType = "primary", path, ...other }) {
  return (Object.prototype.hasOwnProperty.call(other, "type") &&
    other.type === "submit") ||
    other.type === "button" ? (
    // eslint-disable-next-line react/button-has-type
    <button
      type={other.type}
      className={classNames(
        "button flex align-center justify-center",
        `button-${btnType}`
      )}
      {...other}
    >
      {children}
    </button>
  ) : (
    <Link
      to={path}
      className={classNames(
        "button flex align-center justify-center",
        `button-${btnType}`
      )}
      {...other}
    >
      {children}
    </Link>
  );
}

Button.defaultProps = {
  btnType: "primary",
  path: HOME,
};

Button.propTypes = {
  children: PropTypes.node,
  btnType: PropTypes.string,
  path: PropTypes.string,
};

export default Button;
