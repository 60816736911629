import React from "react";
import { routesConstants } from "../../constants";

const { LOGIN } = routesConstants;

function StaticLayout({ component: Component, isTopHeader = false, ...rest }) {
  if (isTopHeader) return <Component {...rest} />;
  if (!isTopHeader && !rest.history.location.pathname.includes(LOGIN))
    return <Component {...rest} />;
  return <div />;
}

export default StaticLayout;
