import jwtDecode from "jwt-decode";
import { startDirectDebitPaymentWorkerNets } from "sign-insurance-components";
import { call, put, takeEvery } from "redux-saga/effects";
import { invoicesConstants, routesConstants, apiConstants } from "../constants";
import { invoiceService } from "../services";
import { invoiceSelectors, authSelectors, userSelectors } from "../selectors";
import { isNetsProvider } from "../constants/invoices.constants";
import { history } from "../helpers";

const {
  getTempPaymentPeriod,
  getTempPaymentMethod,
  getTempPaymentMethodProvider,
} = invoiceSelectors;
const { getUserToken } = authSelectors;
const { getUserData } = userSelectors;

const { AG_AB } = apiConstants;
const { SCRIVEACCEPT, SCRIVECANCEL } = routesConstants;

const {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  CHANGE_PAYMENT_METHOD_TEMPORARY,
  CHANGE_PAYMENT_METHOD_REQUEST,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD_FAILURE,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_PERIOD,
} = invoicesConstants;

const request = () => ({
  type: GET_INVOICES_REQUEST,
});
const success = ({ invoices }) => ({
  type: GET_INVOICES_SUCCESS,
  invoices,
});
const failure = (error) => ({
  type: GET_INVOICES_FAILURE,
  error,
});

const getInvoices = () => (dispatch) => {
  dispatch(request());
  invoiceService.getInvoices().then(
    (invoices) => {
      dispatch(success(invoices));
    },
    (error) => dispatch(failure(error))
  );
};

const changePaymentMethodTemp = (method) => ({
  type: CHANGE_PAYMENT_METHOD_TEMPORARY,
  method,
});

const setPaymentMethod = (insurances) => ({
  type: SET_PAYMENT_METHOD,
  insurances,
});
const setPaymentPeriod = (insurances) => ({
  type: SET_PAYMENT_PERIOD,
  insurances,
});

const requestUpdate = (provider, body) => ({
  type: CHANGE_PAYMENT_METHOD_REQUEST,
  provider,
  body,
});
const successUpdate = (iframe) => ({
  type: CHANGE_PAYMENT_METHOD_SUCCESS,
  iframe,
});
const failureUpdate = (error) => ({
  type: CHANGE_PAYMENT_METHOD_FAILURE,
  error,
});

// todo: this should really be moved to a saga thingymajib
export function* watchUpdatePaymentMethod() {
  yield takeEvery(CHANGE_PAYMENT_METHOD_REQUEST, updatePaymentMethodByProvider);
}

function* updatePaymentMethodByProvider(action) {
  const provider = action.provider || "";
  const { body } = action;
  if (isNetsProvider(provider)) {
    yield updatePaymentMethodNets(
      body.customerCode,
      body.paymentPeriod,
      body.kid
    );
  } else {
    yield updatePaymentMethodScrive(body.customerCode, body.paymentPeriod);
  }
}

function* updatePaymentMethodNets(customerCode, paymentPeriod, kid) {
  try {
    yield call(
      startDirectDebitPaymentWorkerNets,
      {
        customerCode,
        kid,
      },
      history,
      paymentPeriod
    );
  } catch (error) {
    yield put(failureUpdate(error));
  }
}

function* updatePaymentMethodScrive() {
  window.open(AG_AB);
}

const updatePaymentMethod = () => (dispatch, getState) => {
  const paymentPeriod = getTempPaymentPeriod(getState());
  const paymentMethod = getTempPaymentMethod(getState());
  const paymentProvider = getTempPaymentMethodProvider(getState());
  const userData = getUserData(getState());
  const customerCode = userData.get("customerCode");
  const kid = userData.get("kid", undefined);
  dispatch(
    requestUpdate(paymentProvider, {
      customerCode,
      paymentMethod,
      paymentPeriod,
      kid,
    })
  );
};

export default {
  getInvoices,
  changePaymentMethodTemp,
  setPaymentMethod,
  setPaymentPeriod,
  updatePaymentMethod,
};
