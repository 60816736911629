import { vehicleConstants } from "../../../../../constants";

const { RV_VEHICLE } = vehicleConstants;

export default {
  articleCode: "300003",
  shadowType: "SJF300003",
  types: ["SJF"],
  coverage: [RV_VEHICLE],
  isCoInsurable: false,
};
