import { fromJS, Set } from "immutable";
import { benefitsConstants } from "../constants";
import { updateMap } from "./functions";

const { GET_BENEFITS_REQUEST, GET_BENEFITS_SUCCESS, GET_BENEFITS_FAILURE } =
  benefitsConstants;

const initialState = fromJS({
  loading: false,
  items: {},
  itemIds: {},
  error: "",
});

const benefits = (state = initialState, action) => {
  const { benefits, error, type } = action;
  let newState = state;
  switch (type) {
    case GET_BENEFITS_REQUEST:
      newState = newState.set("loading", true);
      newState = newState.set("error", "");
      return newState;
    case GET_BENEFITS_SUCCESS:
      newState = newState.set("loading", false);
      if (!Array.isArray(benefits)) return newState;
      benefits.forEach((benefit) => {
        const {
          id,
          link,
          title,
          acf: { description },
          acf: { code },
          acf: { affiliateLink },
          _embedded: { "wp:featuredmedia": media, "wp:term": cat },
        } = benefit;
        newState = newState.updateIn(
          ["itemIds", cat[0][0].name],
          Set(),
          (list) => list.add(id)
        );
        newState = updateMap(newState, "items", id, {
          id,
          link,
          title,
          description,
          code,
          url: media[0].source_url,
          category: cat[0][0].name,
          affiliateLink,
        });
      });
      return newState;
    case GET_BENEFITS_FAILURE:
      newState = newState.set("loading", true);
      newState = newState.set("error", error);
      return newState;
    default:
      return newState;
  }
};

export default benefits;
