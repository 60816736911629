import { vehicleConstants } from "../../../../../constants";

const { CARAVAN_VEHICLE } = vehicleConstants;

export default {
  types: ["HV", "HV2"],
  coverage: [CARAVAN_VEHICLE],
  isCoInsurable: true,
  recommendationTitle: "Vägassistans Husvagn",
  recommendationText:
    "Teckna vår Vägassistans Husvagn så får du ett personligt försäkringsskydd vilket betyder att du får hjälp oavsett husvagn som du framför.. Genom att kombinera flera försäkringar hos oss får du som kund 30% rabatt på den totala försäkringspremien.",
};
