import { bannerConstants } from "../constants";
import { wpService } from "../services";

const { FETCH_BANNER_REQUEST, FETCH_BANNER_SUCCESS, FETCH_BANNER_FAILURE } =
  bannerConstants;

const fetchBannerRequest = () => ({
  type: FETCH_BANNER_REQUEST,
});
const fetchBannerSuccess = (banner) => ({
  type: FETCH_BANNER_SUCCESS,
  banner,
});
const fetchBannerFailure = (error) => ({
  type: FETCH_BANNER_FAILURE,
  error,
});

const fetchBanners = () => (dispatch) => {
  dispatch(fetchBannerRequest());
  wpService.getAcfWpPosts("banner").then(
    (banner) => dispatch(fetchBannerSuccess(banner)),
    (error) => dispatch(fetchBannerFailure(error))
  );
};

export default { fetchBanners };
