import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";
import PropTypes from "prop-types";
import { magazineActions } from "../../actions";
import { externalRoutesConstants } from "../../constants";
import { magazineSelectors } from "../../selectors";
import ListComponent from "../helper/ListComponent";
import ReadMoreCard from "../helper/ReadMoreCard";
import MagazineCard from "./MagazineCard";
import lang from "../../helpers/lang-constants";

const { MAGAZINES } = externalRoutesConstants;
const { getMagazineIdsState, getLoading } = magazineSelectors;
const language = lang.customer.components.magazine.magazines;

class Magazines extends Component {
  componentDidMount() {
    const { fetchMagazines } = this.props;
    fetchMagazines();
  }

  getIds = () => {
    const { itemsIds } = this.props;
    let idList = List();
    if (itemsIds) {
      itemsIds.forEach((ids) => {
        idList = idList.push(ids.get("id"));
      });
    }

    return idList;
  };

  anyMagazines = () => {
    return this.getIds().size > 0;
  };

  render() {
    const { isLoading } = this.props;
    return this.anyMagazines() ? (
      <div className="container magazines">
        <ListComponent
          ids={this.getIds()}
          component={MagazineCard}
          direction="row"
          isPrimaryHeading={false}
          heading={language.heading_text}
          isLoading={isLoading}
        >
          <ReadMoreCard
            text={language.read_more_button.text}
            url={MAGAZINES}
            newLink
            className="read-more-magazine"
          />
        </ListComponent>
      </div>
    ) : null;
  }
}

Magazines.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchMagazines: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
  const getMagazineIds = getMagazineIdsState();
  return (state) => ({
    itemsIds: getMagazineIds(state),
    isLoading: getLoading(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  fetchMagazines: () => dispatch(magazineActions.getMagazines()),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Magazines);
