import transformVehicle from "./vehicle";

export default function handleVehicleResponse(apiResponse) {
  if (!Array.isArray(apiResponse.vehicles)) {
    if (apiResponse.statusCode === 200) {
      apiResponse.vehicle = transformVehicle(apiResponse.vehicle);
    }
    return apiResponse;
  }
  apiResponse.vehicles = apiResponse.vehicles.map((vehicle) =>
    transformVehicle(vehicle)
  );
  return apiResponse;
}
