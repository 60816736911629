import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { routesConstants, externalRoutesConstants } from "../../constants";
import InformationBanner from "../banner/InformationBanner";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";

const {
  HOME,
  INSURANCES,
  DETAILS,
  CUSTOMER_SERVICE,
  INVOICES,
  CLAIM,
  BENEFITS,
  SHOP,
} = routesConstants;
const {
  EXT_GET_INSURANCE,
  EXT_TERMS,
  EXT_ABOUT_US,
  EXT_PRODUCTS,
  EXT_CONTACT,
} = externalRoutesConstants;
const language = lang.customer.components.navbar.side_bar;

function Sidebar({ ...rest }) {
  return (
    <nav className={rest.className || ""}>
      <div className="container-nav">
        <ul
          className="nav-list flex column justify-center align-center"
          onClick={rest.onClick ? rest.onClick : undefined}
          onKeyPress={rest.onClick ? rest.onClick : undefined}
          role="button"
        >
          {!rest.isLogin && <LoggedInMenuOptions rest={rest} />}
          {rest.isLogin && <LoggedOutMenuOptions rest={rest} />}
        </ul>

        {rest.className === "side-bar" && (
          <InformationBanner
            className="information-banner"
            history={rest.history}
          />
        )}
      </div>
    </nav>
  );
}

function LoggedInMenuOptions(rest) {
  return (
    <>
      <LoggedInMenuOption to={HOME} lang={language.home_text} />
      <LoggedInMenuOption to={DETAILS} lang={language.my_settings_text} />
      <LoggedInMenuOption to={INSURANCES} lang={language.my_insurances_text} />
      <LoggedInMenuOption to={INVOICES} lang={language.my_invoices_text} />
      <LoggedInMenuOption to={CLAIM} lang={language.new_claim_text} />
      {!themes.isSbf() && (
        <LoggedInMenuOption to={BENEFITS} lang={language.my_benefits_text} />
      )}
      {themes.isAssistans() && (
        <LoggedInMenuOption to={SHOP} lang={language.sign_insurance_text} />
      )}
      <LoggedInMenuOption
        to={CUSTOMER_SERVICE}
        lang={language.contact_customer_support_text}
      />
    </>
  );
}

function LoggedInMenuOption(props) {
  return (
    <li>
      <NavLink
        exact
        to={props.to}
        activeClassName="active"
        className="nav-link-sidebar"
      >
        <span>{props.lang}</span>
      </NavLink>
    </li>
  );
}

function LoggedOutMenuOptions(rest) {
  return (
    <>
      <LoggedOutMenuOption to={EXT_GET_INSURANCE} lang="TECKNA FÖRSÄKRING" />
      <LoggedOutMenuOption to={EXT_TERMS} lang="VILLKOR" />
      <LoggedOutMenuOption to={EXT_ABOUT_US} lang="OM OSS" />
      <LoggedOutMenuOption to={EXT_PRODUCTS} lang="TRAFIKFÖRSÄKRING" />
      <LoggedOutMenuOption to={EXT_CONTACT} lang="KONTAKT" />
    </>
  );
}

function LoggedOutMenuOption(props) {
  return (
    <li>
      <a
        href={props.to}
        rel="noopener"
        activeClassName="active"
        className="nav-link-sidebar"
      >
        <span>{props.lang}</span>
      </a>
    </li>
  );
}

export default Sidebar;
