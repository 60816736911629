import Snackbar from "@material-ui/core/Snackbar";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { snackbarActions } from "../../actions";
import { snackbarSelectors } from "../../selectors";

const { isOpen, getSnackbarType, getTitle, getMessage } = snackbarSelectors;
const { closeSnackbar } = snackbarActions;

function SnackbarComponent({
  isOpen,
  snackbarType,
  title,
  message,
  closeSnackbar,
}) {
  return (
    <Snackbar
      className="snackbar"
      open={isOpen}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      // autoHideDuration={AUTO_CLOSE_SNACKBAR}
      onClose={closeSnackbar}
    >
      <div
        className={classNames(
          "snackbar-content flex row justify-between align-center",
          snackbarType
        )}
      >
        <div className={classNames("snackbar-text flex column")}>
          <span className={classNames("snackbar-title")}>{title}</span>
          <span className={classNames("snackbar-message")}>{message}</span>
        </div>

        <i
          className="fal fa-times-circle"
          onClick={closeSnackbar}
          role="button"
          onKeyPress={closeSnackbar}
          tabIndex={0}
        />
      </div>
    </Snackbar>
  );
}

const mapStateToProps = (state) => ({
  isOpen: isOpen(state),
  snackbarType: getSnackbarType(state),
  title: getTitle(state),
  message: getMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeSnackbar: () => dispatch(closeSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
