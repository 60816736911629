import vehicleConstants from "../../../../constants/vehicle.constants";

const { PERSONAL_VEHICLE } = vehicleConstants;
export const PYRAMID_TYPE = "PB";

export default {
  type: PERSONAL_VEHICLE,
  matchesType: (vehicle) => {
    return (
      vehicle.data.typeCode === PYRAMID_TYPE &&
      new Date().getFullYear() - Number(vehicle.data.modelYear) < 30
    );
  },
  isInsurable: (vehicle) => {
    const vehicleAge =
      new Date().getFullYear() - Number(vehicle.data.modelYear);
    return !(vehicleAge > 25 && vehicleAge < 30);
  },
};
