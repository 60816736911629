import classNames from "classnames";
import React from "react";
import InsuranceCard from "../../components/insurance/InsuranceCard";
import Insurances from "../../components/insurance/Insurances";
import Summations from "../../components/insurance/Summations";
import KeyTab from "../../components/invoice/KeyTab";
import Heading from "../../components/helper/Heading";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";

const language = lang.customer.views.insurances.index;

function InsurancePage() {
  return (
    <div className={classNames("container")}>
      <Heading heading={language.title} />
      <Summations />
      {themes.isAssistans() && <KeyTab />}
      <Insurances component={InsuranceCard} />
    </div>
  );
}

export default InsurancePage;
