import { getFormValues } from "redux-form";
import { smsConstants, routesConstants, errorConstants } from "../constants";
import { smsService } from "../services";
import { setTokenLocalStorage } from "./functions";
import { authSelectors } from "../selectors";
import { history } from "../helpers";
import snackbarActions from "./snackbar.actions";
import lang from "../helpers/lang-constants";

const language = lang.customer.components.snack_bar;
const STATUS_SUCCESS = "Skickat SMS";
const { getSsn } = authSelectors;
const {
  SMS_TOKEN_REQUEST,
  SMS_TOKEN_SUCCESS,
  SMS_TOKEN_FAILURE,
  SMS_AUTHENTICATE_REQUEST,
  SMS_AUTHENTICATE_SUCCESS,
  SMS_AUTHENTICATE_FAILURE,
} = smsConstants;
const { HOME } = routesConstants;

const requestSmsTokenBegin = (ssn) => ({
  type: SMS_TOKEN_REQUEST,
  ssn,
});
const requestSmsTokenSuccess = (statusMessage) => ({
  type: SMS_TOKEN_SUCCESS,
  statusMessage,
});
const requestSmsTokenFailure = (error) => ({
  type: SMS_TOKEN_FAILURE,
  error,
});

const requestSmsToken = () => (dispatch, getState) => {
  const customerCode = getFormValues("loginForm")(getState()).login;
  dispatch(requestSmsTokenBegin(customerCode));
  smsService.requestToken({ customerCode }).then(
    ({ statusMessage }) => {
      if (STATUS_SUCCESS === statusMessage)
        dispatch(requestSmsTokenSuccess(statusMessage));
      else {
        dispatch(
          snackbarActions.openSnackbar(
            "failure",
            errorConstants.GENERIC_ERROR_MESSAGE
          )
        );
        dispatch(requestSmsTokenFailure(statusMessage));
      }
    },
    (err) => {
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      );
      dispatch(requestSmsTokenFailure(err));
    }
  );
};

const smsAuthenticateRequest = () => ({
  type: SMS_AUTHENTICATE_REQUEST,
});
const smsAuthenticateSuccess = () => ({
  type: SMS_AUTHENTICATE_SUCCESS,
});
const smsAuthenticateFailure = () => ({
  type: SMS_AUTHENTICATE_FAILURE,
});

const smsAuthenticate = () => (dispatch, getState) => {
  const ssn = getSsn(getState());
  if (!ssn) return;
  const { token } = getFormValues("smsTokenForm")(getState());
  dispatch(smsAuthenticateRequest());
  smsService.smsLogin(token, ssn).then(
    (response) => {
      if (response.statusMessage === "success") {
        setTokenLocalStorage(response.token);
        dispatch(smsAuthenticateSuccess());
        history.push(HOME);
      } else {
        dispatch(smsAuthenticateFailure(response.statusMessage));
        dispatch(
          snackbarActions.openSnackbar("failure", language.sms_login.login_fail)
        );
      }
    },
    (err) =>
      dispatch(
        snackbarActions.openSnackbar(
          "failure",
          errorConstants.GENERIC_ERROR_MESSAGE
        )
      )
  );
};

export default {
  requestSmsToken,
  smsAuthenticate,
};
