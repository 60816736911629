import { Map } from "immutable";

const getUserData = ({ user }) => user.get("user", Map());
const getKeyTabs = ({ user }) => user.get("keyTabs", []);
const getUpdating = ({ user, modal }, type) => {
  if (type !== "insuranceId") return user.getIn(["updating", type], false);
  return user.getIn(["updating", modal.get("insuranceId")], false);
};
// const getRegisteredVehicles = ({ user }) =>
//   user.get("registeredVehicles", List());
const getVehicle = ({ user }, id) => user.getIn(["registeredVehicles", id]);
const getRegisteredVehiclesIds = ({ user }) =>
  user.get("registeredVehiclesIds");
const getCustomRegisteredVehiclesIds = ({ user }) =>
  user.get("customRegisteredVehiclesIds");
const getRegisteredVehiclesTypes = ({ user }) =>
  user.get("registeredVehiclesTypes");
const getStatusMessage = ({ user }, type) =>
  user.getIn(["statusMessage", type], "");

const getNumberOfKeyTabs = ({ user }) =>
  user.getIn(["user", "numberOfKeyTabs"], 0);

const getFellowApplicants = ({ user }) => user.getIn(["fellowApplicants"]);

const getFellowApplicant = ({ user }, socialNumber) =>
  user.getIn(
    ["fellowApplicants"].filter(
      (applicant) => applicant.socialNumber === socialNumber
    )
  );

export default {
  getUserData,
  getKeyTabs,
  getUpdating,
  getVehicle,
  getRegisteredVehiclesIds,
  getCustomRegisteredVehiclesIds,
  getRegisteredVehiclesTypes,
  getStatusMessage,
  getNumberOfKeyTabs,
  getFellowApplicants,
  getFellowApplicant,
};
