import classNames from "classnames";
import Parser from "html-react-parser";
import { List } from "immutable";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { benefitActions } from "../../actions";
import { routesConstants } from "../../constants";
import history from "../../helpers/history";
import { benefitSelectors } from "../../selectors";
import Heading from "../helper/Heading";
import ListComponent from "../helper/ListComponent";
import ReadMoreCard from "../helper/ReadMoreCard";
import BenefitCard from "./BenefitCard";
import BenefitCardSimple from "./BenefitCardSimple";
import LoadingSpinner from "../helper/LoadingSpinner";
import lang from "../../helpers/lang-constants";

const { BENEFITS } = routesConstants;
const { getBenefitIds, getLoading } = benefitSelectors;
const language = lang.customer.components.benefit.benefits;

class Benefits extends PureComponent {
  componentDidMount() {
    const { fetchBenefits } = this.props;
    fetchBenefits();
  }

  mapIds = () => {
    const { itemsIds } = this.props;
    let idList = List();
    try {
      itemsIds.forEach((ids) => {
        ids.map((id) => {
          idList = idList.concat(id);
          if (idList.size > 4) throw idList;
        });
      });
    } catch (e) {}
    return idList;
  };

  render() {
    const isSimpleBenefit = history.location.pathname !== BENEFITS;
    const { itemsIds, isLoading } = this.props;
    return (
      <div
        className={classNames("container benefit", {
          simple: isSimpleBenefit,
          full: !isSimpleBenefit,
          loading: isLoading && !itemsIds.size,
        })}
      >
        {!isSimpleBenefit && <Heading heading={language.heading_text} />}
        {!isSimpleBenefit && <LoadingSpinner isLoading={isLoading} size={25} />}
        {!isSimpleBenefit &&
          itemsIds.entrySeq().map((item) => (
            <div key={item[0]}>
              <h3>{Parser(item[0])}</h3>
              <ListComponent
                className="benefit-card"
                ids={item[1]}
                component={BenefitCard}
                direction="column"
              />
            </div>
          ))}
        {isSimpleBenefit && (
          <ListComponent
            className="benefit-card-simple"
            ids={this.mapIds()}
            isPrimaryHeading={false}
            heading={language.heading_text}
            component={BenefitCardSimple}
            direction="row"
            isLoading={isLoading}
          >
            <ReadMoreCard
              text={language.readmore_text}
              url={BENEFITS}
              className="read-more-benefit"
            />
          </ListComponent>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  itemsIds: getBenefitIds(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBenefits: () => dispatch(benefitActions.getBenefits()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
