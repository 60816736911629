import React from "react";

// eslint-disable-next-line react/prop-types
function FooterSocialMedia({ fields }) {
  return (
    <div className="container container-width flex row justify-center align-center social-media">
      <ul className="flex row justify-around">
        {fields.get("social_media_links", []).map((mediaLink) => (
          <li key={mediaLink.get("icon")}>
            <a href={mediaLink.get("link")}>
              <i className={`${mediaLink.get("icon")} fa-2x color-gold`} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

FooterSocialMedia.propTypes = {
  // See FooterContainer for fields prop-validation
};

export default FooterSocialMedia;
