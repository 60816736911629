import React, { PureComponent } from "react";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import lang from "../../../helpers/lang-constants";
import { authSelectors } from "../../../selectors";
import { createQrCode } from "../../../helpers/bankid";
import bankIdLogo from "../../../assets/images/BankID_logo_neg.svg";
import qrCode from "../../../assets/images/qr-code.svg";
import BankIdQrCodeLoader from "./BankIdLoader";
import "./ab_error.scss";
import abErrorSelector from "../../../selectors/abError.selector";
import { formatISODate } from "../../../helpers/dateConverter";

const language = lang.customer.components.login.bankid.bankid_login_form;
const { getAutoStartToken, getLoading } = authSelectors;
const { selectError } = abErrorSelector;
class BankIdQrCode extends PureComponent {
  componentDidMount() {
    const { requestBankIdQrCodeAuth, fetchErrorStatusOnMount } = this.props;
    requestBankIdQrCodeAuth();
    fetchErrorStatusOnMount();
  }

  render() {
    const { autoStartToken, isLoading, selectError } = this.props;

    function splitMessage(message) {
      const [title, description] = message.split(";");
      return (
        <>
          <h1>{title}</h1>
          <p>{description}</p>
        </>
      );
    }
    const errorMessages = selectError?.communicationTexts;

    const getErrorMessages = () => {
      return errorMessages?.map((message) => {
        return (
          <div className="ab-error-divider">
            <span>{splitMessage(message?.text)}</span>
            <p>
              OBS! Ovan information beräknas vara gällande till{" "}
              {formatISODate(message?.endTime)}
            </p>
          </div>
        );
      });
    };

    return (
      <div className="bankid-qrcode-container">
        <div className="flex">
          <div className="left-wrapper">
            <h1>{language.title_text}</h1>
            <span>{language.description_text}</span>
            {autoStartToken && !isLoading && (
              <div className="qr-code">
                <QRCode value={createQrCode(autoStartToken)} />
              </div>
            )}
            {isLoading && <BankIdQrCodeLoader />}
            <div className="bankid-qrcode-footer">
              <span>
                <b>{language.tips_text_intro}</b> {language.tips_text}
                <br />
                <br />
                <b>{language.help_text_intro}</b> {language.help_text}
              </span>
            </div>
          </div>
          <div className="right-wrapper">
            <div className="phone">
              <div className="top-border" />
              <img className="bankid-logo" src={bankIdLogo} alt="bankid logo" />
              <div className="ready-to-use">
                <span>
                  {language.ready_to_use_text}
                  <br />
                  <br />
                  {language.ready_login_text}
                </span>
              </div>
              <img className="qr-code-white" src={qrCode} alt="small qr code" />
              <div className="footer-qr-code">
                <span>{language.qr_code_text}</span>
              </div>
            </div>
          </div>
        </div>
        {errorMessages && errorMessages.length > 0 && (
          <div className="ab-error">{getErrorMessages()}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const autoStartToken = getAutoStartToken(state);

  return {
    autoStartToken,
    isLoading: getLoading(state).get("bankIdAuth", false),
    selectError: selectError(state),
  };
};

export default connect(mapStateToProps)(BankIdQrCode);
