import React from "react";
import { connect } from "react-redux";
import { magazineSelectors } from "../../selectors";
import lang from "../../helpers/lang-constants";

const language = lang.customer.components.magazine.magazine_card;
const { getMagazine } = magazineSelectors;

function MagazineCard({ item }) {
  const title = item.get("title", "")
    ? item.get("title", "").get("rendered", "")
    : "";
  return (
    <div className="magazine-card magazine flex column">
      <a href={item.get("link")} target="_blank" rel="noopener noreferrer">
        <div
          className="magazine-image background-image"
          style={{ backgroundImage: `url(${item.get("image")})` }}
        />
      </a>
      <div className="flex magazine-border" />
      <div className="flex justify-between content-info">
        <span className="read-more-link">
          <span className="read-more-link">{title}</span>
        </span>
        <a
          href={item.get("link")}
          target="_blank"
          rel="noopener noreferrer"
          className="flex align-center read-more-link"
        >
          {language.read_more_text}
          <i className="fal fa-chevron-right" />
        </a>
      </div>
    </div>
  );
}
const mapStateToProps = (state, { id }) => ({
  item: getMagazine(state, id),
});

export default connect(mapStateToProps)(MagazineCard);
