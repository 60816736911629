export default class BaseApi {
  API = "";

  AUTH_API = "";

  WP = "";

  ETRACK = "";

  COUNTRY_CODE = "";

  POLICY_LINK = "";
}
