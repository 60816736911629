export default {
  AUTHENTICATE_REQUEST: "BANKID_AUTHENTICATE_REQUEST",
  AUTHENTICATE_SUCCESS: "BANKID_AUTHENTICATE_SUCCESS",
  AUTHENTICATE_FAILURE: "BANKID_AUTHENTICATE_FAILURE",

  AUTHENTICATE_QRCODE_REQUEST: "BANKID_AUTHENTICATE_QRCODE_REQUEST",
  AUTHENTICATE_QRCODE_SUCCESS: "BANKID_AUTHENTICATE_QRCODE_SUCCESS",
  AUTHENTICATE_QRCODE_FAILURE: "BANKID_AUTHENTICATE_QRCODE_FAILURE",

  COLLECT_REQUEST: "BANKID_COLLECT_REQUEST",
  COLLECT_SUCCESS: "BANKID_COLLECT_SUCCESS",
  COLLECT_FAILURE: "BANKID_COLLECT_FAILURE",

  CANCEL_REQUEST: "BANKID_CANCEL_REQUEST",
  CANCEL_SUCCESS: "BANKID_CANCEL_SUCCESS",
  CANCEL_FAILURE: "BANKID_CANCEL_FAILURE",

  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  OPEN_APP_PENDING: "OPEN_APP_PENDING",
  OPEN_APP_FAILURE: "OPEN_APP_FAILURE",
  OPEN_APP_SUCCESS: "OPEN_APP_SUCCESS",
};
