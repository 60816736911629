import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { modalConstants } from "../../constants";
import Button from "../helper/Button";
import "./_endInsuranceModal.style.scss";
import insuranceActions from "../../actions/insurance.actions";
import { insuranceSelectors } from "../../selectors";

const { MODAL_NOT_FOCUSED } = modalConstants;

const steps = {
  fetchInsurances: "fetchInsurances",
  chooseInsurances: "chooseInsurances",
  endInsurances: "endInsurances",
};
const {
  getEndableInsurances,
  checkEndableInsurancesState,
  terminateReasonsState,
  hasEndedInsurances,
} = insuranceSelectors;

function EndInsuranceModal({
  closeModal,
  fetchEndableInsurances,
  insurances,
  checkEndableInsurances,
  insurancesToEnd,
  fetchTerminateReasons,
  terminateReasons,
  endInsurances,
  hasEndedSucceded,
}) {
  const fixedInsurances = insurances.toList().toJS()[0];
  const fixedInsurancesToEnd = insurancesToEnd.toList().toJS()[0];
  const terminateReasonsList = terminateReasons.toList().toJS();
  const hasEndedSuccededState = hasEndedSucceded.toList().toJS();

  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [selectedTerminateReason, setSelectedTerminateReason] = useState("");
  const [currentStep, setCurrentStep] = useState(steps.fetchInsurances);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSelectTerminateReason = (event) => {
    setSelectedTerminateReason(event.target.value);
  };

  const findSelectedInsurance = fixedInsurances?.find(
    (insurance) =>
      insurance.articleCode === selectedInsurance.articleCode &&
      insurance.orderRowNumber === selectedInsurance.orderRowNumber
  );

  useEffect(() => {
    fetchEndableInsurances();
    fetchTerminateReasons();
  }, []);

  const handleFirstOnClick = () => {
    const whichInsurance = fixedInsurances.find(
      (insurance) =>
        insurance.articleCode === selectedInsurance.articleCode &&
        insurance.orderRowNumber === selectedInsurance.orderRowNumber
    );
    const { orderNumber, orderRowNumber } = whichInsurance;
    const body = {
      orderNumber,
      orderRowNumber,
    };
    checkEndableInsurances(body);
    setCurrentStep(steps.chooseInsurances);
  };

  const findSelectedTerminateReason = terminateReasonsList.find(
    (reason) => reason.id.toString() === selectedTerminateReason
  );
  const confirmEndInsurances = () => {
    const body = {
      email,
      phoneNumber,
      terminateReason: findSelectedTerminateReason?.text,
      endInsurances: fixedInsurancesToEnd,
    };
    endInsurances(body);
  };

  const handleConfirmEndInsurances = () => {
    confirmEndInsurances();
    setCurrentStep(steps.endInsurances);
  };

  const defaultInsurance = fixedInsurances?.find(
    (insurance) => insurance.articleCode === selectedInsurance
  );

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const phoneRegex = /^(?:\+46|0)[1-9][0-9]{1,9}$/;

  const emailIsValid = emailRegex.test(email);
  const phoneIsValid = phoneRegex.test(phoneNumber);

  const disabledState = () => {
    if (!emailIsValid) {
      return true;
    }
    if (!phoneIsValid) {
      return true;
    }
    if (!findSelectedTerminateReason) {
      return true;
    }
    return false;
  };

  return (
    <div className="modal content noScroll" tabIndex={MODAL_NOT_FOCUSED}>
      <div className="modal-body">
        <h4 className="dialog-title flex row justify-between">
          Avsluta försäkring{" "}
          <i
            className="fal fa-times-circle modal-close color-gold"
            onClick={closeModal}
            tabIndex={-1}
            role="button"
          />
        </h4>

        <div className="content-modal">
          {currentStep === steps.fetchInsurances && (
            <>
              <div style={{ color: "white" }}>
                <p style={{ color: "white" }}>
                  Välj i listan vilken försäkring som du vill avsluta. Vänligen
                  notera att eventuella medförsäkrade automatiskt kommer sägas
                  upp vid avslut av huvudförsäkring.
                </p>
                <p style={{ color: "white" }}>
                  Kom ihåg att våra vägassistansförsäkringar gäller dig som
                  kund, och inte ett specifikt fordon.
                </p>
              </div>
              <label
                style={{ width: "100%" }}
                htmlFor="QuestionDamageTypeGroup"
              >
                <select
                  className="insurances-select"
                  name="QuestionDamageType"
                  id="QuestionDamageType"
                  defaultValue={defaultInsurance?.text || ""}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    const [articleCode, orderRowNumber] = selectedValue
                      .split("+")
                      .map((str) => str.trim());
                    setSelectedInsurance({ articleCode, orderRowNumber });
                  }}
                >
                  <option value={null}>Välj försäkring i listan</option>
                  {fixedInsurances?.map((insurance) => (
                    <option
                      key={insurance.articleCode}
                      value={`${insurance.articleCode} + ${insurance.orderRowNumber}`}
                    >
                      {`${insurance.orderNumber}, ${insurance.insurance} - ${insurance.insuranceHolderName} - ${insurance.insuranceHolderSSN} `}
                    </option>
                  ))}
                </select>
              </label>
              <div className="button-wrapper">
                <Button
                  btnType="primary"
                  type="button"
                  onClick={() => handleFirstOnClick()}
                  disabled={!findSelectedInsurance}
                >
                  Nästa
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      {currentStep === steps.chooseInsurances && (
        <div className="modal-body">
          <p style={{ color: "white" }}>
            Följande försäkringar kommer att avslutas:
          </p>
          <ul>
            {fixedInsurancesToEnd?.map((insurance) => (
              <li
                className="insurance-list"
                key={insurance.articleCode + insurance.orderRowNumber}
              >
                <div className="icon-dot" />
                {`${insurance.orderNumber}, ${insurance.insurance} - ${insurance.insuranceHolderName} - ${insurance.insuranceHolderSSN} `}
              </li>
            ))}
          </ul>
          <div className="divider" />
          <p style={{ color: "white" }}>Kontaktuppgifter</p>
          <p style={{ color: "white", fontSize: "12px" }}>
            För att få bekräftelse på avslut behöver du ange korrekt
            e-postadress
          </p>
          <label className="input-labels" htmlFor="email">
            Email
            <input
              className="customer-input"
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="input-labels" htmlFor="phone">
            Telefonnummer
            <input
              className="customer-input"
              type="tel"
              name="phone"
              id="phone"
              onChange={(e) => setPhoneNumber(e.target.value)}
              inputMode="numeric"
            />
          </label>

          <label htmlFor="terminate-reason" className="input-labels">
            Vad är den främsta anledningen till att du avslutar din försäkring?
            <select
              className="insurances-select"
              name="terminate-reason"
              id="terminate-reason"
              onChange={handleSelectTerminateReason}
            >
              <option>Välj alternativ i listan</option>
              {terminateReasonsList?.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.text}
                </option>
              ))}
            </select>
          </label>
          <div className="button-wrapper">
            <Button
              btnType="primary"
              type="button"
              onClick={() => handleConfirmEndInsurances()}
              disabled={disabledState()}
            >
              Bekräfta
            </Button>
          </div>
        </div>
      )}

      {currentStep === steps.endInsurances && (
        <div className="end-page">
          {hasEndedSuccededState[0] ? (
            <p style={{ color: "white" }}>
              Dina försäkringar är nu avslutade. Du kommer att få en bekräftelse
              skickad till din e-post inom kort. Om du har några frågor eller
              vill teckna nya försäkringar kan du ta kontakt med vår kundtjänst.
              Varmt välkommen åter!
            </p>
          ) : (
            <>
              <p style={{ color: "white" }}>
                Vänligen vänta, vi jobbar med din förfrågan
              </p>
              <CircularProgress className="loading-spinner" size={45} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

const mapDispatchToProp = (dispatch) => ({
  fetchEndableInsurances: () =>
    dispatch(insuranceActions.fetchEndableInsurances()),
  checkEndableInsurances: (body) =>
    dispatch(insuranceActions.checkEndableInsurances(body)),
  fetchTerminateReasons: () =>
    dispatch(insuranceActions.fetchTerminateReasons()),
  endInsurances: (body) => dispatch(insuranceActions.endInsurancesAction(body)),
});

const mapStateToProps = (state) => ({
  insurances: getEndableInsurances(state),
  insurancesToEnd: checkEndableInsurancesState(state),
  terminateReasons: terminateReasonsState(state),
  hasEndedSucceded: hasEndedInsurances(state),
});

EndInsuranceModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fetchEndableInsurances: PropTypes.func.isRequired,
  checkEndableInsurances: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProp)(EndInsuranceModal);
