import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Map } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { selectors as stepSelectors } from "step-counter";
import { insuranceSelectors, userSelectors } from "../../selectors";

const { getUserData } = userSelectors;
const { getStep } = stepSelectors;
const { getShopInsuranceIds } = insuranceSelectors;

function ShopContent({ step, itemIds, steps, userData }) {
  const Component = steps[step].component;
  return (
    <div className="container shop-content">
      <Component itemIds={itemIds} userData={userData} isNewUser={false} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  step: getStep(state),
  itemIds: getShopInsuranceIds(state),
  userData: getUserData(state),
});

ShopContent.defaultProps = {
  userData: Map(),
};

ShopContent.propTypes = {
  userData: ImmutablePropTypes.mapContains({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    city: PropTypes.string,
    streetAddress: PropTypes.string,
    zipCode: PropTypes.string,
    customerCode: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(ShopContent);
