import { Map } from "immutable";

const getUser = ({ authentication }) => authentication.get("user", Map());
const getLoadingByType = ({ authentication }, type) =>
  authentication.getIn(["loading", type], false);
const getLoading = ({ authentication }) => authentication.get("loading", Map());
const isUserLoggedIn = ({ authentication }) =>
  authentication.get("loggedIn", false);
const getUserToken = ({ authentication }) =>
  authentication.getIn(["user", "token"], "");
const isSmsTokenSent = ({ authentication }) =>
  authentication.get("sentSmsToken", false);
const getSsn = ({ authentication }) => authentication.get("ssn", "");
const isOpenBankIdPending = ({ authentication }) =>
  authentication.get("openBankIdPending", false);
const getAutoStartToken = ({ authentication }) =>
  authentication.get("autoStartToken", null);
const isAuthError = ({ authentication }) =>
  authentication.get("isAuthError", false);

export default {
  getUser,
  getLoading,
  getLoadingByType,
  isUserLoggedIn,
  getUserToken,
  isSmsTokenSent,
  getSsn,
  isAuthError,
  isOpenBankIdPending,
  getAutoStartToken,
};
