import React, { useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../helper/Button";
import { routesConstants } from "../../../constants";
import { bankIdActions } from "../../../actions";
import history from "../../../helpers/history";
import lang from "../../../helpers/lang-constants";
import bankid_logo from "../../../assets/images/bankid_vector_black.svg";
import { formatISODate } from "../../../helpers/dateConverter";
import abErrorSelector from "../../../selectors/abError.selector";
import abErrorActions from "../../../actions/abError.actions";

const { BANKID_SIGN } = routesConstants;
const { selectError } = abErrorSelector;
const language = lang.customer.components.login.bankid.bankid_mobile_login;

function BankIdMobileLogin({
  activateBankIdApplication,
  selectError,
  fetchErrorStatusOnMount,
}) {
  function splitMessage(message) {
    const [title, description] = message.split(";");
    return (
      <>
        <h1>{title}</h1>
        <p>{description}</p>
      </>
    );
  }

  useEffect(() => {
    fetchErrorStatusOnMount && fetchErrorStatusOnMount();
  }, []);
  const errorMessages = selectError?.communicationTexts;
  const getErrorMessages = () => {
    return errorMessages?.map((message) => {
      return (
        <div className="ab-error-divider">
          <span>{splitMessage(message?.text)}</span>
          <p>
            OBS! Ovan information beräknas vara gällande till <br />
            {formatISODate(message?.endTime)}
          </p>
        </div>
      );
    });
  };
  return (
    <div className="content flex column mobile-login-content">
      <span>
        {language.description_text}
        <br />
        <br />
        {language.label_text}
      </span>
      <div className="flex column justify-center align-center">
        <Button type="submit" onClick={() => activateBankIdApplication()}>
          {language.open_app_button.text}
          <img className="button-logo" src={bankid_logo} />
        </Button>
        <Button
          type="submit"
          onClick={() => {
            history.push(BANKID_SIGN);
          }}
        >
          {language.default_login_button.text}
        </Button>
        {errorMessages && errorMessages.length > 0 && (
          <div className="ab-error">{getErrorMessages()}</div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectError: selectError(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { openBankidApplication } = bankIdActions;
  const { getMessages } = abErrorActions;
  return {
    fetchErrorStatusOnMount: () => {
      dispatch(getMessages());
    },
    activateBankIdApplication: () => {
      dispatch(openBankidApplication());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankIdMobileLogin);
