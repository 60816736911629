import React from "react";

function CustomVehicleClaimCard({ onClick, isSelected, id }) {
  return (
    <div
      className={`vehicle-claim-card${isSelected ? " selected" : ""}`}
      role="carousel-button"
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div className="vehicle-claim-background-image">
        <span
          className="vehicle-claim-add-new-vehicle-symbol"
          alt="{language.add_new_vehicle}"
        >
          ?
        </span>
      </div>
      <div className="vehicle-claim-regnr">{id}</div>
    </div>
  );
}

export default CustomVehicleClaimCard;
