import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Heading from "../../components/helper/Heading";
import ClaimForm from "./ClaimForm";
import lang from "../../helpers/lang-constants";
import { insuranceSelectors } from "../../selectors";
import ClaimFormResponse from "./ClaimFormResponse";

const language = lang.customer.views.claim.index;

const { getUpdating, getClaimInsuranceResponse } = insuranceSelectors;

function ClaimPage(props) {
  const [showResponseScreen, setshowResponseScreen] = useState(false);

  const content = showResponseScreen ? (
    <ClaimFormResponse
      loading={props.isLoading}
      response={props.claimInsuranceResponse}
      showResponse={setshowResponseScreen}
    />
  ) : (
    <div>
      <div className="container customer-greeting">
        <p>{language.greeting_text}</p>
      </div>
      <ClaimForm showResponse={setshowResponseScreen} />
    </div>
  );
  return (
    <div className={classNames("container")}>
      <Heading heading={language.heading_text} />
      {content}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: getUpdating(state, "claimInsurance"),
  claimInsuranceResponse: getClaimInsuranceResponse(state).toJSON(),
});

export default connect(mapStateToProps)(ClaimPage);
