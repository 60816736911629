/**
 * Loads a remote script, like Google analytics and injects it into the DOM
 */
export default (src, name) => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById(name);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = src;
      script.id = name;
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        console.log(`loaded script ${name}`);
        resolve();
      };
    }

    if (existingScript) {
      resolve();
    }
  });
};
