import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { hamburgerActions } from "../../actions";
import HamburgerMenuIcon from "./HamburgerMenuIcon";

const { openHamburger } = hamburgerActions;

function HamburgerMenu({ toggleMenu }) {
  return (
    <div className="hamburger-container">
      <div
        className="hamburger"
        onClick={toggleMenu}
        onKeyPress={toggleMenu}
        role="button"
        tabIndex={0}
      >
        <HamburgerMenuIcon />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => dispatch(openHamburger()),
});

export default connect(null, mapDispatchToProps)(HamburgerMenu);
