import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { modalConstants } from "../../constants";
import { authSelectors, modalSelectors } from "../../selectors";
import bankId from "../../assets/images/bankid_high_rgb.png";
import lang from "../../helpers/lang-constants";
import { getBankIdApplicationLink } from "../../helpers/bankid";

const { MODAL_NOT_FOCUSED } = modalConstants;
const { isAuthError, isUserLoggedIn, getAutoStartToken } = authSelectors;
const { getOrderRef } = modalSelectors;
const language = lang.customer.components.modal.bank_id_modal;

class BankIdModal extends PureComponent {
  componentDidUpdate() {
    const { closeModal, isError, isLoggedIn } = this.props;
    if (isError || isLoggedIn) closeModal();
  }

  render() {
    const { showOpenBankIdLink } = this.props;
    const { autoStartToken, orderRef } = this.props;
    return (
      <div className="modal content bank-id" tabIndex={MODAL_NOT_FOCUSED}>
        <h4 className="dialog-title flex row justify-center">
          <img src={bankId} alt="bankID logotyp" />
        </h4>
        <div className="dialog-content flex column align-center">
          <span className="content-description">{language.title}</span>
          <span className="semi-bold">{language.description}</span>
          {showOpenBankIdLink && (
            <div className="open-app-content flex column align-center">
              <span>{language.open_app_text}</span>
              <span>
                <a
                  href={getBankIdApplicationLink(
                    autoStartToken,
                    orderRef,
                    false
                  )}
                >
                  {language.open_app_link_text}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const autoStartToken = getAutoStartToken(state);
  const orderRef = getOrderRef(state);
  return {
    autoStartToken,
    orderRef,
    showOpenBankIdLink: !!(autoStartToken && orderRef),
    isError: isAuthError(state),
    isLoggedIn: isUserLoggedIn(state),
  };
};

export default connect(mapStateToProps)(BankIdModal);
