import React from "react";
import PropTypes from "prop-types";
import paperClip from "../../assets/images/paperclip.svg";

function AddAttachments(props) {
  const { onAddFileHandler, buttonText, title, description } = props;

  return (
    <div className="add-attachments-container">
      <h6>{title}</h6>
      <p>{description}</p>
      <label htmlFor="add-claim-info" className="button-add-claim-info">
        <img src={paperClip} alt="paperclip" width="12px" />
        <input
          type="file"
          multiple
          accept="image/*, application/pdf, .doc, .docx"
          id="add-claim-info"
          onChange={onAddFileHandler}
        />
        {buttonText}
      </label>
    </div>
  );
}

AddAttachments.defaultProps = {
  buttonText: "BIFOGA FIL",
};

AddAttachments.propTypes = {
  onAddFileHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AddAttachments;
