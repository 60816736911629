import { fromJS, Set } from "immutable";
import { bannerConstants } from "../constants";
import { updateMap } from "./functions";

const { FETCH_BANNER_REQUEST, FETCH_BANNER_SUCCESS, FETCH_BANNER_FAILURE } =
  bannerConstants;

const initialState = fromJS({
  loading: false,
  banners: {
    verticalPromotionalBanner: {},
    horizontalPromotionalBanner: {},
    informationBanner: {},
  },
  error: "",
});

const banner = (state = initialState, action) => {
  const { banner, error } = action;
  let newState = state;
  switch (action.type) {
    case FETCH_BANNER_REQUEST:
      newState = newState.set("loading", true);
      newState = newState.set("error", initialState.get("error"));
      return newState;
    case FETCH_BANNER_SUCCESS:
      newState = newState.set("loading", false);
      if (!Array.isArray(banner)) return newState;
      banner.forEach((banner) => {
        const {
          acf: {
            active,
            type,
            backgroundColor,
            backgroundImage,
            text,
            link,
            image,
            alt,
          },
        } = banner;
        newState = updateMap(newState, "banners", type, {
          active,
          type,
          backgroundColor,
          backgroundImage,
          image,
          alt,
          text,
          link,
        });
      });
      return newState;
    case FETCH_BANNER_FAILURE:
      newState = newState.set("loading", false);
      newState = newState.set("error", initialState.get(error));
      return newState;
    default:
      return newState;
  }
};

export default banner;
