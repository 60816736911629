import { authHeader } from "../helpers";
import { apiConstants } from "../constants";

const fileNameString = "&filename=";

function handleResponse(response) {
  if (process.env.NODE_ENV === "development") console.log("response", response);
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (response.ok) return data;
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  });
}

function handlePDFResponse(response) {
  if (process.env.NODE_ENV === "development") console.log("response", response);
  return response.blob().then((payload) => {
    if (response.status >= 200 && response.status < 300 && response) {
      const file = new window.Blob([payload], {
        type: "application/pdf",
        headers: authHeader(),
      });
      const url = URL.createObjectURL(file);
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) !== -1
      ) {
        window.open(url, "_blank");
      } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        window.open(url, "_blank");
      } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        window.open(url, "_self");
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        window.open(url, "_blank");
      } else if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode === true
      ) {
        window.navigator.msSaveOrOpenBlob(file);
      } else if (navigator.userAgent.indexOf("Trident/") !== -1) {
        window.navigator.msSaveOrOpenBlob(file);
      } else {
        window.open(url);
      }
    }

    return response;
  });
}

const addInsurancePolicyData = (orderNumber) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(apiConstants.API.USER.POLICY_DATA + orderNumber, requestOptions)
    .then(handleResponse)
    .then((data) => data);
};

const fetchInsurancePolicy = (orderNumber, filename) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    apiConstants.API.USER.INSURANCE_POLICY +
      orderNumber +
      fileNameString +
      filename,
    requestOptions
  )
    .then(handlePDFResponse)
    .then((data) => data);
};

export default {
  addInsurancePolicyData,
  fetchInsurancePolicy,
};
