import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import FormCard from "../../../components/formCard/FormCard";
import lang from "../../../helpers/lang-constants";

const language =
  lang.customer.views.claim.questions.question_legal_confirmation;

function QuestionLegalConfirmation(props) {
  const onChangeApplyingHandler = () => {
    props.setState([true, props.state[1]]);
    props.state[1] && props.showNextQuestion(props.name);
  };
  const onChangeConsentHandler = () => {
    props.setState([props.state[0], true]);
    props.state[0] && props.showNextQuestion(props.name);
  };

  const applicationText = (
    <Typography className="legal-text">
      {language.description_text_applying}
    </Typography>
  );
  const consentText = (
    <Typography className="legal-text">
      {language.description_text_consent}
    </Typography>
  );

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
    >
      <FormControlLabel
        label={applicationText}
        control={
          <Checkbox
            checked={props.state[0]}
            onChange={onChangeApplyingHandler}
          />
        }
      />
      <hr className="horizontal-divider" />
      <FormControlLabel
        label={consentText}
        control={
          <Checkbox
            checked={props.state[1]}
            onChange={onChangeConsentHandler}
          />
        }
      />
    </FormCard>
  );
}

export default QuestionLegalConfirmation;
