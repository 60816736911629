import { modalConstants } from "../constants";

const { OPEN_MODAL, CLOSE_MODAL } = modalConstants;

const openModal = (
  component,
  articleCode,
  insuranceId = "",
  orderRef,
  familyMember
) => ({
  type: OPEN_MODAL,
  component,
  articleCode,
  insuranceId,
  orderRef,
  familyMember,
});
const closeModal = () => ({
  type: CLOSE_MODAL,
});

export default {
  openModal,
  closeModal,
};
