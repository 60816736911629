const damageTypes = [
  { description: "Drulle/Allrisk", value: 800007 },
  { description: "Hyrbil", value: 800013 },
  { description: "Krishjälp", value: 800008 },
  { description: "Räddning", value: 800009 },
  { description: "Rättskydd", value: 800010 },
  { description: "Bilnyckel", value: 800016 },
  { description: "Egendom i bil", value: 800014 },
  { description: "Elektronik", value: 800021 },
  { description: "Feltankning", value: 800012 },
  { description: "Kupéskada", value: 800017 },
  { description: "Maskin/Motor", value: 800006 },
  { description: "Brand", value: 800002 },
  { description: "Lackskada", value: 800020 },
  { description: "Plåtskada", value: 800019 },
  { description: "Vilt/Djur", value: 800018 },
  { description: "Inbrott", value: 800005 },
  { description: "Olycksfall", value: 800015 },
  { description: "Skada på annat fordon", value: 800022 },
  { description: "Stöld", value: 800004 },
  { description: "Glas - Stenskott", value: 800003 },
  { description: "Glas - Övrigt", value: 800023 },
];

export default damageTypes;
