import React from "react";
import FormCard from "../../../components/formCard/FormCard";
import lang from "../../../helpers/lang-constants";
import { getQuestionErrorMessage } from "../../../helpers/utils";

const language = lang.customer.views.claim.questions.question_cost;

const costValidator = (value) => {
  let errorMessage;
  const validValue = /^[0-9]+$/gm;
  const valid = validValue.test(value);
  if (!valid) {
    errorMessage = language.error_message;
  }
  return errorMessage;
};

function QuestionCost(props) {
  const onChangeHandler = (event) => {
    const { value } = event.target;
    const validationMessage = costValidator(value);
    props.setState(value);
    props.showNextQuestion(props.name, validationMessage);
  };

  const errorMessage = getQuestionErrorMessage(props.showList, props.name);

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <div className="cost-input-wr">
        <label htmlFor="QuestionCost">
          <input
            className="input-claim-cost"
            dir="rtl"
            type="number"
            name="QuestionCost"
            id="QuestionCost"
            onChange={onChangeHandler}
          />
        </label>
        <span className="suffix-cost">kr</span>
      </div>
      <span className="questions-error-message">{errorMessage || " "}</span>
    </FormCard>
  );
}

export default QuestionCost;
