import { benefitsConstants } from "../constants";
import { wpService } from "../services";

const { GET_BENEFITS_REQUEST, GET_BENEFITS_SUCCESS, GET_BENEFITS_FAILURE } =
  benefitsConstants;

function getBenefits() {
  return (dispatch) => {
    dispatch(request());
    wpService.getWpPosts("benefit").then(
      (benefits) => dispatch(success(benefits)),
      (error) => dispatch(failure(error))
    );
  };
}

const request = () => ({
  type: GET_BENEFITS_REQUEST,
});
const success = (benefits) => ({
  type: GET_BENEFITS_SUCCESS,
  benefits,
});
const failure = (error) => ({
  type: GET_BENEFITS_FAILURE,
  error,
});

export default {
  getBenefits,
};
