import { bootstrap as regBootstrap } from "register";
import { bootstrap as compBootstrap } from "sign-insurance-components";
import { bootstrap as langBootstrap } from "./lang-constants";
import { themes, env } from "./config";
import apiConstants from "../constants/api.constants";
import analyticConstants from "../constants/analytics.constants";
import loadRemoteScript from "./loadRemoteScript";

const disableLog = () => {
  console.log = () => {};
};

if (env.isProduction()) {
  disableLog();
}

// Bootstrap title, description and favicon
const metaBootstrap = (lang) => {
  const { title, description, favicon } = lang.meta;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description);
  document.title = title;
  const link =
    document.querySelector("link[rel*='shortcut icon']") ||
    document.createElement("link");
  link.href = `/${favicon}`;
  document.getElementsByTagName("head")[0].appendChild(link);
};
const googleAnalyticsBootstrap = async (tag) => {
  if (!tag) {
    console.log("No Google Analytics tag specified");
    return;
  }
  console.log(`Google analytics tag found: ${tag}`);

  await loadRemoteScript(
    `https://www.googletagmanager.com/gtag/js?id=${tag}`,
    "analytics"
  );

  // GA specific code
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", tag);
};

let lang_file = {};

if (themes.isNorway()) {
  lang_file = require("../assets/locale/no.json");
} else {
  lang_file = require("../assets/locale/sv.json");
}

const lang = langBootstrap(lang_file);
regBootstrap({}, lang, {
  BASE_VALIDATE_CAMPAIGN_CODE: apiConstants.API.CAMPAIGN.VALIDATE_CAMPAIGN_CODE,
});
compBootstrap({}, lang, {
  NETS_RETURN_URL: apiConstants.NETS.NETS_RETURN_URL,
  COMPANY_ACCOUNT_NUMBER: apiConstants.NETS.COMPANY_ACCOUNT_NUMBER,
  NETS_ENDPOINT: apiConstants.NETS.NETS_ENDPOINT,
  BASE_CALLBACK_ACCEPT: apiConstants.NETS.NETS_ACCEPT,
  BASE_CALLBACK_CANCEL: apiConstants.NETS.NETS_CANCEL,
  BASE_DIRECT_DEBIT_ACCEPT: apiConstants.API.SCRIVE.ACCEPT,
  BASE_DIRECT_DEBIT_REJECT: apiConstants.API.SCRIVE.REJECT,
});
metaBootstrap(lang);
googleAnalyticsBootstrap(analyticConstants.TAG);
