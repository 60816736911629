import { Map } from "immutable";
import React from "react";
import { connect } from "react-redux";
import { InfoText } from "sign-insurance-components";
import { modalActions } from "../../actions";
import { insuranceSelectors, userSelectors } from "../../selectors";
import Button from "../helper/Button";
import Heading from "../helper/Heading";
import LoadingSpinner from "../helper/LoadingSpinner";
import RowContainer from "../helper/RowContainer";
import lang from "../../helpers/lang-constants";
import formatDate from "../../helpers/formatter";

const { getKeyTabInsuranceState, getUpdating } = insuranceSelectors;
const { getKeyTabs } = userSelectors;
const language = lang.customer.components.invoice.key_tab;

const ONE_YEAR = 12;
const MAX_KEY_TABS = 5;

function KeyTab({ keyTabList, openModal, keyTabs, loading }) {
  const keyTabInsurance = keyTabList.toJSON();
  const keyTabItem = keyTabList.get(0, Map());

  const keyTabTags = keyTabs.map((key, index) => (
    <span key={key.tag}>{(index ? ", " : "") + key.tag}</span>
  ));
  const isNotRenewalTags = keyTabs.filter((keyTab) => !keyTab.isRenewalTag);
  const isNotRenewalTagsLength = isNotRenewalTags.length;
  const monthPriceIncludingVAT = keyTabItem.get("monthPriceIncludingVAT", 0);
  const endDate = formatDate(keyTabItem.get("endDate"));

  let statusMessage = "Ej aktiv";
  let annualFee = 0;

  const hasKeytabInsurance = keyTabInsurance.length > 0;

  const hasKeyTabs = keyTabTags.length > 0;
  const isActive = keyTabItem.get("isActive");

  // Has keytag but no key insurance
  if (!hasKeytabInsurance && hasKeyTabs) {
    statusMessage = language.status.key_tab_no_insurance_text;
  }
  // Has insurance but is not active
  if (hasKeytabInsurance && !isActive) {
    statusMessage = language.status.insurance_no_key_tab_text;
  }
  // Has active insurance and keytag(s)
  if (isActive && hasKeyTabs) {
    statusMessage = `${language.status.active_key_tab_text} ${endDate}`;
    annualFee = Math.round(monthPriceIncludingVAT * ONE_YEAR);
  }

  return (
    <div>
      {hasKeyTabs && (
        <div className="container key-tab">
          <div className="flex row key-tab-header align-center">
            <Heading heading={language.title_text} isPrimary={false} />
            <InfoText
              description={language.description_text}
              bottom="40px"
              left="-70px"
            />
          </div>
          <LoadingSpinner size={25} isLoading={loading} />
          {!loading && (
            <div className="key-tab-content">
              <RowContainer className="keytabs_wrapper">
                <span itemType="leftTitle" className="label-bold">
                  {language.status_text}
                </span>
                <span itemType="leftContent" className="content">
                  {statusMessage}
                </span>
                <span itemType="centerTitle" className="label-bold">
                  {language.key_tab_number_text}
                </span>
                <span itemType="centerContent" className="content">
                  {keyTabTags}
                </span>
                <span
                  itemType="rightTitle"
                  className="label-bold align-text-right"
                >
                  {language.annual_fee_text}
                </span>
                <span
                  itemType="rightContent"
                  className="content align-text-right"
                >
                  {annualFee} {language.currency_text}
                </span>
              </RowContainer>
              <hr className="gold-divider" />
              <RowContainer className="add-more-key-tabs">
                <span itemType="leftTitle" className="label-bold">
                  {language.tabs_text}
                </span>
                <span itemType="leftContent" className="content">
                  {keyTabTags.length || 0} {language.unit_text}
                </span>
                <Button
                  type="button"
                  itemType="rightContent"
                  onClick={openModal}
                  btnType="primary"
                  disabled={
                    isNotRenewalTagsLength >= MAX_KEY_TABS ? "disabled" : ""
                  }
                  title={
                    isNotRenewalTagsLength >= MAX_KEY_TABS
                      ? language.max_key_tabs_warning_text
                      : ""
                  }
                >
                  {language.order_key_tabs_button.text}
                </Button>
              </RowContainer>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getKeyTab = getKeyTabInsuranceState();

  return (state) => ({
    keyTabList: getKeyTab(state),
    keyTabs: getKeyTabs(state),
    loading: getUpdating(state, "fetchUserInsurances"),
  });
};

const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(modalActions.openModal("orderKeyTabs")),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(KeyTab);
