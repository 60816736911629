import abErrorService from "../services/abError.service";

export const FETCH_ERROR_AB_SUCCESS = "FETCH_ERROR_AB_SUCCESS";

const fetchAbErrorSuccess = (data) => ({
  type: FETCH_ERROR_AB_SUCCESS,
  data,
});

const getMessages = () => (dispatch) => {
  abErrorService.getErrorStatus().then((data) => {
    dispatch(fetchAbErrorSuccess(data));
  });
};

export default {
  getMessages,
};
