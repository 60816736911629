import { vehicleConstants } from "../../../../../constants";

const {
  PERSONAL_VEHICLE,
  VETERAN_VEHICLE,
  RV_VEHICLE,
  WAGON_VEHICLE,
  CARAVAN_VEHICLE,
  MOTORCYCLE,
} = vehicleConstants;

export default {
  types: ["VIP"],
  coverage: [
    PERSONAL_VEHICLE,
    VETERAN_VEHICLE,
    RV_VEHICLE,
    WAGON_VEHICLE,
    CARAVAN_VEHICLE,
    MOTORCYCLE,
  ],
  isCoInsurable: true,
};
