const insuranceNameList = [
  "AIG Europé SA",
  "Aktsam",
  "Annat",
  "Atlantica",
  "Cigna",
  "Crowe Motor Policies",
  "Dial",
  "Dina Försäkringar",
  "Dpmann",
  "EIR Försäkringar",
  "Euro Försäkringar",
  "Folksam",
  "Folksam företagsmotor",
  "Gjensidige",
  "GRE Guardian försäkring",
  "Greenval Försäkring",
  "Holmia",
  "Ica Försäkring",
  "IF Märkesförsäkring",
  "IF Skadeförsäkring",
  "InsuranceCompanyName",
  "Kammarkollegiet",
  "Länsförsäkringar Bergslagen",
  "Länsförsäkringar Blekinge",
  "Länsförsäkringar Gotland",
  "Länsförsäkringar Gävleborg",
  "Länsförsäkringar Göinge-Kristianstad",
  "Länsförsäkringar Göteborg och Bohuslän",
  "Länsförsäkringar Halland",
  "Länsförsäkringar Jämtland",
  "Länsförsäkringar Jönköping",
  "Länsförsäkringar Kalmar län",
  "Länsförsäkringar Kronoberg",
  "Länsförsäkringar Norrbotten",
  "Länsförsäkringar Skaraborg",
  "Länsförsäkringar Skåne",
  "Länsförsäkringar Stockholm",
  "Länsförsäkringar Södermanland",
  "Länsförsäkringar Uppsala",
  "Länsförsäkringar Värmland",
  "Länsförsäkringar Västerbotten",
  "Länsförsäkringar Västernorrland",
  "Länsförsäkringar Älvsborg",
  "Länsförsäkringar Östgöta",
  "Länsförsäkringsbolagen",
  "Moderna Försäkringar",
  "Postens försäkringsbolag AB",
  "Protector Försäkring",
  "Royal",
  "SALUSansvar",
  "SALUSansvar, Sakförsäkrings AB",
  "Sensor",
  "Skandia",
  "Skandia/Volvia",
  "Sockenbolagens försäkrings AB",
  "Staten",
  "Svedea",
  "Tre Kronor Försäkring",
  "Trygg Hansa",
  "UNI Storebrand",
  "Volvia",
  "Wasa",
  "Wasa Lita",
  "Watercircles Försäkring",
  "Zurich Försäkring",
  "Zürich",
];

export default insuranceNameList;
