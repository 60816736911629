import { fromJS, Set } from "immutable";
import { insuranceConstants } from "../constants";
import { updateMap, updateDeepNestedMap } from "./functions";

const {
  GET_USER_INSURANCES_REQUEST,
  GET_USER_INSURANCES_SUCCESS,
  GET_USER_INSURANCES_FAILURE,
  GET_ALL_INSURANCES_REQUEST,
  GET_ALL_INSURANCES_SUCCESS,
  GET_ALL_INSURANCES_FAILURE,
  TOGGLE_INSURANCE_CARD,
  ADD_CO_INSURED_REQUEST,
  ADD_CO_INSURED_SUCCESS,
  ADD_CO_INSURED_FAILURE,
  SIGN_NEW_INSURANCE_REQUEST,
  SIGN_NEW_INSURANCE_SUCCESS,
  SIGN_NEW_INSURANCE_FAILURE,
  ORDER_KEY_TABS_REQUEST,
  ORDER_KEY_TABS_SUCCESS,
  ORDER_KEY_TABS_FAILURE,
  POST_CLAIM_INSURANCE_REQUEST,
  POST_CLAIM_INSURANCE_SUCCESS,
  POST_CLAIM_INSURANCE_FAILURE,
  POST_CLAIM_INSURANCE_CLEANUP,
  CO_INSURANCE_PRICE_LOOKUP_SUCCESS,
  CO_INSURANCE_PRICE_LOOKUP_REQUEST,
  CO_INSURANCE_PRICE_LOOKUP_FAILURE,
  VEHICLE_INSURANCE_REQUEST,
  VEHICLE_INSURANCE_SUCCESS,
  VEHICLE_INSURANCE_FAILURE,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  KLARNA_OPEN_BANKING_SESSION_REQUEST,
  KLARNA_OPEN_BANKING_SESSION_SUCCESS,
  KLARNA_OPEN_BANKING_SESSION_FAILURE,
  KLARNA_OPEN_BANKING_FETCH_RESULTS_REQUEST,
  KLARNA_OPEN_BANKING_FETCH_RESULTS_SUCCESS,
  KLARNA_OPEN_BANKING_FETCH_RESULTS_FAILURE,
  FETCH_ENDABLE_INSURANCES,
  FETCH_ENDABLE_INSURANCES_SUCCESS,
  CHECK_ENDABLE_INSURANCES,
  CHECK_ENDABLE_INSURANCES_SUCCESS,
  FETCH_TERMINATE_REASONS_REQUEST,
  FETCH_TERMINATE_REASONS_SUCCESS,
  END_INSURANCES_SUCCESS,
} = insuranceConstants;

const initialState = fromJS({
  updating: {},
  userInsurances: {},
  userInsuranceIds: Set(),
  userInsuranceIdGroups: {},
  allInsurances: {},
  allInsuranceIds: Set(),
  error: {},
  coInsurancePrice: {},
  vehicleInsurance: {},
  claimInsurance: {},
  fileUploadUrl: {},
  klarnaOpenBanking: { fetchFailed: false },
  endableInsurances: {},
  checkEndableInsurances: {},
  terminateReasons: {},
  hasEndedInsurances: { isDone: false },
});
const insurances = (state = initialState, action) => {
  const {
    items,
    insuranceId,
    error,
    type,
    cardId,
    articleCode,
    coInsurancePriceData,
    vehicleInsuranceCompany,
    claimInsuranceResponse,
    formContent,
    errorMessage,
    klarnaOpenBanking,
    accountDetails,
    endableInsurances,
    checkEndableInsurancesResponse,
    terminateReasons,
  } = action;
  let newState = state;
  switch (type) {
    case TOGGLE_INSURANCE_CARD: {
      const isOpen = newState.getIn(["allInsurances", cardId, "open"], false);
      newState = updateDeepNestedMap(
        newState,
        "allInsurances",
        cardId,
        "open",
        !isOpen
      );
      return newState;
    }
    case ADD_CO_INSURED_REQUEST:
      return updateDeepNestedMap(
        newState,
        "updating",
        insuranceId,
        articleCode,
        true
      );
    case ORDER_KEY_TABS_REQUEST:
      return updateMap(newState, "updating", "keyTab", true);
    case POST_CLAIM_INSURANCE_REQUEST:
      return updateMap(newState, "updating", "claimInsurance", true);
    case FILE_UPLOAD_REQUEST:
      return updateMap(newState, "updating", "fileUploadUrl", true);
    case KLARNA_OPEN_BANKING_SESSION_REQUEST:
      newState = updateMap(newState, "klarnaOpenBanking", "fetchFailed", false);
      newState = updateMap(
        newState,
        "updating",
        "klarnaOpenBankingSession",
        true
      );
      return newState;
    case KLARNA_OPEN_BANKING_FETCH_RESULTS_REQUEST:
      newState = updateMap(newState, "klarnaOpenBanking", "fetchFailed", false);
      newState = updateMap(
        newState,
        "updating",
        "klarnaOpenBankingFetchResults",
        true
      );
      return newState;
    case SIGN_NEW_INSURANCE_REQUEST:
      return updateMap(newState, "updating", "signNewInsurance", true);
    case GET_USER_INSURANCES_REQUEST:
      newState = updateMap(newState, "updating", "fetchUserInsurances", true);
      newState = updateMap(newState, "error", "getUserInsurances", "");
      return newState;
    case GET_ALL_INSURANCES_REQUEST:
      newState = updateMap(newState, "updating", "fetchShopInsurances", true);
      newState = updateMap(newState, "error", "getShopInsurances", "");
      return newState;
    case KLARNA_OPEN_BANKING_SESSION_SUCCESS:
      newState = updateMap(
        newState,
        "updating",
        "klarnaOpenBankingSession",
        false
      );
      newState = updateMap(
        newState,
        "klarnaOpenBanking",
        "sessionInfo",
        klarnaOpenBanking
      );
      return newState;
    case KLARNA_OPEN_BANKING_FETCH_RESULTS_SUCCESS:
      newState = updateMap(
        newState,
        "updating",
        "klarnaOpenBankingFetchResults",
        false
      );
      newState = updateMap(
        newState,
        "klarnaOpenBanking",
        "accountDetails",
        accountDetails
      );
      return newState;
    case GET_ALL_INSURANCES_SUCCESS:
      newState = updateMap(newState, "updating", "fetchShopInsurances", false);
      items.forEach((item) => {
        const { acf } = item;
        newState = newState.update("allInsuranceIds", (idList) =>
          idList.add(acf.article_code)
        );
        newState = updateMap(newState, "allInsurances", acf.article_code, acf);
      });
      return newState;
    case GET_USER_INSURANCES_SUCCESS:
      newState = updateMap(newState, "updating", "fetchUserInsurances", false);
      action.insurances.forEach((insurance) => {
        const { insuranceNumber, articleCode, isActive } = insurance;
        if (!isActive) return;
        newState = updateDeepNestedMap(
          newState,
          "userInsuranceIdGroups",
          insuranceNumber,
          articleCode,
          articleCode
        );
        newState = newState.update("userInsuranceIds", (idList) =>
          idList.add(`${insuranceNumber}`)
        );

        newState = updateDeepNestedMap(
          newState,
          "userInsurances",
          insuranceNumber,
          articleCode,
          insurance
        );
      });
      return newState;
    case ADD_CO_INSURED_FAILURE:
      return newState.deleteIn(["updating", insuranceId, articleCode]);
    case FILE_UPLOAD_FAILURE:
      return updateMap(newState, "updating", "fileUploadUrl", false);
    case KLARNA_OPEN_BANKING_SESSION_FAILURE:
      newState = updateMap(newState, "klarnaOpenBanking", "fetchFailed", true);
      newState = updateMap(
        newState,
        "updating",
        "klarnaOpenBankingSession",
        false
      );
      return newState;
    case KLARNA_OPEN_BANKING_FETCH_RESULTS_FAILURE:
      newState = updateMap(newState, "klarnaOpenBanking", "fetchFailed", true);
      newState = updateMap(
        newState,
        "updating",
        "klarnaOpenBankingFetchResults",
        false
      );
      return newState;
    case SIGN_NEW_INSURANCE_FAILURE:
      newState = updateMap(newState, "updating", "signNewInsurance", false);
      newState = updateMap(newState, "error", "signNewInsurance", error);
      return newState;
    case ORDER_KEY_TABS_FAILURE:
      return updateMap(newState, "updating", "keyTabs", false);
    case POST_CLAIM_INSURANCE_FAILURE:
      newState = updateMap(newState, "updating", "claimInsurance", false);
      newState = updateMap(
        newState,
        "claimInsurance",
        "statusMessage",
        errorMessage
      );
      newState = updateMap(
        newState,
        "claimInsurance",
        "formContent",
        formContent
      );
      return newState;
    case POST_CLAIM_INSURANCE_CLEANUP:
      newState = updateMap(newState, "updating", "claimInsurance", false);
      newState = updateMap(newState, "claimInsurance", "statusCode", "");
      newState = updateMap(newState, "claimInsurance", "statusMessage", "");
      return newState;
    case GET_USER_INSURANCES_FAILURE:
      newState = updateMap(newState, "updating", "fetchUserInsurances", false);
      newState = updateMap(newState, "error", "getUserInsurance", error);
      return newState;
    case GET_ALL_INSURANCES_FAILURE:
      newState = updateMap(newState, "updating", "fetchShopInsurances", false);
      newState = updateMap(newState, "error", "getShopInsurances", error);
      return newState;
    case ADD_CO_INSURED_SUCCESS:
      return newState.deleteIn(["updating", insuranceId, articleCode]);
    case SIGN_NEW_INSURANCE_SUCCESS:
      newState = updateMap(newState, "updating", "signNewInsurance", false);
      return newState;
    case ORDER_KEY_TABS_SUCCESS:
      return updateMap(newState, "updating", "keyTab", false);
    case POST_CLAIM_INSURANCE_SUCCESS:
      newState = updateMap(newState, "updating", "claimInsurance", false);
      newState = updateMap(
        newState,
        "claimInsurance",
        "statusCode",
        claimInsuranceResponse.statusCode
      );
      newState = updateMap(
        newState,
        "claimInsurance",
        "statusMessage",
        claimInsuranceResponse.statusMessage
      );
      return newState;
    case CO_INSURANCE_PRICE_LOOKUP_SUCCESS:
      const { annualPrice, remainingAnnualPrice } = coInsurancePriceData;
      newState = updateMap(newState, "updating", "coInsurancePrice", false);
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "annualPrice",
        annualPrice
      );
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "endDate",
        remainingAnnualPrice.end
      );
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "startDate",
        remainingAnnualPrice.start
      );
      newState = updateMap(
        newState,
        "coInsurancePrice",
        "totalPrice",
        remainingAnnualPrice.totalPrice
      );

      return newState;
    case CO_INSURANCE_PRICE_LOOKUP_REQUEST:
      return updateMap(newState, "updating", "coInsurancePrice", true);
    case CO_INSURANCE_PRICE_LOOKUP_FAILURE:
      return updateMap(newState, "updating", "coInsurancePrice", false);
    case FILE_UPLOAD_SUCCESS:
      return updateMap(newState, "updating", "fileUploadUrl", false);
    case VEHICLE_INSURANCE_SUCCESS:
      const { insuranceCompanyPopularName, insuranceCompanyName } =
        vehicleInsuranceCompany;
      newState = updateMap(newState, "updating", "vehicleInsurance", false);
      newState = updateMap(
        newState,
        "vehicleInsurance",
        "insuranceCompanyName",
        insuranceCompanyPopularName || insuranceCompanyName || ""
      );
      return newState;
    case VEHICLE_INSURANCE_REQUEST:
      newState = updateMap(newState, "vehicleInsurance", "noInsurance", false);
      newState = updateMap(newState, "updating", "vehicleInsurance", true);
      return newState;
    case VEHICLE_INSURANCE_FAILURE:
      newState = updateMap(newState, "updating", "vehicleInsurance", false);
      newState = updateMap(newState, "vehicleInsurance", "noInsurance", true);
      return newState;
    case FETCH_ENDABLE_INSURANCES:
      newState = updateMap(newState, "hasEndedInsurances", "isDone", false);
      newState = updateMap(newState, "updating", "endableInsurances", true);
      return newState;
    case FETCH_ENDABLE_INSURANCES_SUCCESS: {
      newState = updateMap(newState, "updating", "endableInsurances", false);
      newState = updateMap(
        newState,
        "endableInsurances",
        "data",
        endableInsurances
      );
      return newState;
    }
    case CHECK_ENDABLE_INSURANCES:
      newState = updateMap(
        newState,
        "updating",
        "checkEndableInsurances",
        true
      );
      return newState;
    case CHECK_ENDABLE_INSURANCES_SUCCESS: {
      newState = updateMap(
        newState,
        "updating",
        "checkEndableInsurances",
        false
      );
      newState = updateMap(
        newState,
        "checkEndableInsurances",
        "data",
        checkEndableInsurancesResponse
      );
      return newState;
    }
    case FETCH_TERMINATE_REASONS_REQUEST:
      newState = updateMap(newState, "updating", "terminateReasons", true);
      return newState;
    case FETCH_TERMINATE_REASONS_SUCCESS: {
      newState = updateMap(newState, "updating", "terminateReasons", false);
      newState = updateMap(
        newState,
        "terminateReasons",
        "data",
        terminateReasons
      );
      return newState;
    }
    case END_INSURANCES_SUCCESS:
      newState = updateMap(newState, "hasEndedInsurances", "isDone", true);
      newState = newState.set("userInsuranceIdGroups", fromJS({}));
      newState = newState.set("userInsuranceIds", fromJS(Set()));
      newState = newState.set("userInsurances", fromJS({}));
      return newState;
    default:
      return newState;
  }
};

export default insurances;
