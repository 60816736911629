import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

function Heading({ heading, isPrimary = true }) {
  return (
    <div
      className={classNames("heading", "flex column", "justify-center", {
        "align-center primary": isPrimary,
        "align-start secondary": !isPrimary,
      })}
    >
      {isPrimary ? (
        <h1 className="primary-heading">{heading}</h1>
      ) : (
        <h3 className="secondary-heading">{heading}</h3>
      )}
      {isPrimary && <div className={classNames("border", "center")} />}
    </div>
  );
}

Heading.defaultProps = {
  isPrimary: true,
};

Heading.propTypes = {
  heading: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
};

export default Heading;
