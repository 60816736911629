import React from "react";
import { connect } from "react-redux";
import Parser from "html-react-parser";
import RowContainer from "../helper/RowContainer";
import Button from "../helper/Button";
import { familySelectors } from "../../selectors";
import LoadingSpinner from "../helper/LoadingSpinner";
import lang from "../../helpers/lang-constants";

const { getFamilyInHouseHold, getUpdating } = familySelectors;
const language = lang.customer.components.insurance.show_co_insured_suggestion;

function ShowCoInsuredSuggestion({
  openModal,
  family,
  loading,
  coInsureds,
  articleCode,
  monthPrice,
}) {
  return (
    <div className="flex column">
      <RowContainer style={{ marginBottom: loading ? "0px" : "10px" }}>
        <span itemType="leftTitle" className="label-bold">
          {language.title_text}
        </span>
      </RowContainer>
      <LoadingSpinner isLoading={loading} />
      {family.toList().map((familyMember, index) => {
        let alreadyInsured = false;
        coInsureds.map((coInsured) => {
          if (
            coInsured.getIn(["fellowApplicant", "socialNumber"]) ===
            familyMember.get("socialNumber", "")
          )
            alreadyInsured = true;
        });
        return (
          <div key={familyMember.get("socialnumber", index)}>
            {!alreadyInsured && (
              <RowContainer className="un-co-insured-border row align-second-start-phone">
                <span
                  itemType="leftContent"
                  className="content"
                >{`${familyMember.get("firstName", "")} ${familyMember.get(
                  "lastName",
                  ""
                )}`}</span>
                <span itemType="centerContent" className="content">
                  {familyMember.get("socialNumber", "").slice(0, 6)}
                  -****
                </span>
                <Button
                  type="button"
                  itemType="rightContent"
                  btnType="primary"
                  onClick={() =>
                    openModal(articleCode, {
                      socialNumber: familyMember.get("socialNumber", ""),
                      firstName: familyMember.get("firstName", ""),
                    })
                  }
                  style={{ margin: "auto" }}
                >
                  <span className="flex row align-center justify-center button-span">
                    <i className="fas fa-user-plus button-icon" />
                    {Parser(lang.formatString(language.button.text, "<br />"))}
                  </span>
                </Button>
              </RowContainer>
            )}
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  family: getFamilyInHouseHold(state),
  loading: getUpdating(state, "fetchHouseHold"),
});

export default connect(mapStateToProps)(ShowCoInsuredSuggestion);
