import Modal from "@material-ui/core/Modal";
import React from "react";
import { connect } from "react-redux";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { modalActions } from "../../actions";
import { modalSelectors } from "../../selectors";
import CoInsuredModal from "./CoInsuredModal";
import DebitCardModal from "./DebitCardModal";
import InviteFriendModal from "./InviteFriendModal";
import MissingEmailModal from "./MissingEmailModal";
import OrderKeyTabsModal from "./OrderKeyTabsModal";
import BankIdModal from "./BankIdModal";
import AddNewVehicleModal from "./AddNewVehicleModal";
import EndInsuranceModal from "../insurance/EndInsuranceModal";

export const modalTypes = {
  coInsured: "coInsured",
  debitCard: "debitCard",
  missingEmail: "missingEmail",
  inviteFriend: "inviteFriend",
  orderKeyTabs: "orderKeyTabs",
  bankId: "bankId",
  addNewVehicleModal: "addNewVehicleModal",
  endInsuranceModal: "endInsuranceModal",
};

const modals = {
  [modalTypes.coInsured]: CoInsuredModal,
  [modalTypes.debitCard]: DebitCardModal,
  [modalTypes.missingEmail]: MissingEmailModal,
  [modalTypes.inviteFriend]: InviteFriendModal,
  [modalTypes.orderKeyTabs]: OrderKeyTabsModal,
  [modalTypes.bankId]: BankIdModal,
  [modalTypes.addNewVehicleModal]: AddNewVehicleModal,
  [modalTypes.endInsuranceModal]: EndInsuranceModal,
};

const { getOpen, getComponent } = modalSelectors;

class ModalContainer extends React.Component {
  render() {
    const { isOpen, component, closeModal } = this.props;
    const Component = modals[component];

    return (
      <div>
        {component !== "default" && (
          <Modal
            className="modal flex align-center justify-center"
            open={isOpen}
            onClose={component !== "bankId" ? closeModal : undefined}
          >
            <Component closeModal={closeModal} id="overlay-modal" />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: getOpen(state),
  component: getComponent(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
