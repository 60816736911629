import { vehicleConstants } from "../../../../../constants";

const { WAGON_VEHICLE } = vehicleConstants;

export default {
  types: ["SL"],
  coverage: [WAGON_VEHICLE],
  isCoInsurable: true,
  recommendationTitle: "Vägassistans Släp",
  recommendationText:
    "Vår vägassistansförsäkring Släpvagn gäller när du använder släpvagn med en totalvikt om högst 3,5 ton i originalutförande och som lägst har en halvförsäkring. Genom att kombinera flera försäkringar hos oss får du som kund 30% rabatt på den totala försäkringspremien.",
};
