import dateFormat from "dateformat";
import { themes } from "./config";

export default function formatDate(date) {
  let format;
  if (themes.isNorway()) {
    format = "dd.mm.yyyy";
  } else {
    format = "yyyy-mm-dd";
  }

  return dateFormat(date, format);
}

export const substringResponse = (accountNumber) => {
  const sanitizedAccountNumber = accountNumber.replace(/[\s-]+/g, "");

  return sanitizedAccountNumber.substring(
    sanitizedAccountNumber.indexOf(",") + 1
  );
};
