import React, { useEffect, useState } from "react";
import FormCard from "../../../components/formCard/FormCard";

import lang from "../../../helpers/lang-constants";
import damageTypes from "../constants/damageTypesObject";

const language = lang.customer.views.claim.questions.question_damage_type;

function QuestionDamageType(props) {
  const [damageTypeList, setDamageTypeList] = useState(damageTypes);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const sortedDamageTypes = damageTypes.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );

    setDamageTypeList(sortedDamageTypes);
  }, [damageTypeList]);

  const damageType = damageTypeList.map((alternative) => (
    <option value={alternative.value} key={alternative.value}>
      {alternative.description}
    </option>
  ));

  const onChangeDamageType = (event) => {
    const pickedDamageType = event.target.value;
    props.setState(pickedDamageType);
    if (pickedDamageType === "800003") {
      setShowWarning(true);
      props.showNextQuestion(props.name);
    } else {
      props.showNextQuestion(props.name);
      setShowWarning(false);
    }
  };

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <div className="damage-type-select-container">
        <label htmlFor="QuestionDamageTypeGroup">
          <select
            className="damage-type-select"
            name="QuestionDamageType"
            id="QuestionDamageType"
            onChange={onChangeDamageType}
            defaultValue="initialValue"
          >
            <option value="initialValue" disabled hidden>
              {language.options}
            </option>
            {damageType}
          </select>
        </label>
      </div>
    </FormCard>
  );
}

export default QuestionDamageType;
