import PropTypes from "prop-types";
import React from "react";

function RadioInput(props) {
  const { selected, onChange, text, value, disabled = false } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`modern-radio-container ${disabled && " disabled"}`}
      role="radio"
      aria-checked={value}
      tabIndex="0"
      onClick={() => {
        onChange(value);
      }}
    >
      <div
        className={`radio-outer-circle ${value !== selected && "unselected"}`}
      >
        <div
          className={`radio-inner-circle ${
            value !== selected && "unselected-circle"
          }`}
        />
      </div>
      <div className="helper-text">{text}</div>
    </div>
  );
}

RadioInput.defaultProps = {
  disabled: false,
};

RadioInput.propTypes = {
  text: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default RadioInput;
