import { Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { invoiceSelectors, userSelectors } from "../../selectors";
import InvoiceInsuranceCard from "./InvoiceInsuranceCard";
import InvoicePersonalCard from "./InvoicePersonalCard";
import BannerDirectDebit from "../banner/BannerDirectDebit";
import lang from "../../helpers/lang-constants";

const { getInvoice, getPaymentMethod } = invoiceSelectors;
const { getUserData } = userSelectors;
const language = lang.customer.components.invoice.invoice_card;

function InvoiceCard({ item, userData, paymentMethod }) {
  return (
    <div className="container column">
      <div className="invoice-card flex column">
        <h2 className="flex justify-start align-text-left">
          {language.title_text}
          <span>{`${item.get("invoiceNumber", "")}`}</span>
        </h2>
        <InvoicePersonalCard item={item} userData={userData} />
        <InvoiceInsuranceCard item={item} />
      </div>
      {paymentMethod.toLowerCase() === "faktura" && (
        <BannerDirectDebit
          heading={`${userData.get("firstName", "")} ${userData.get(
            "lastName",
            ""
          )}, ${language.heading_text}`}
          content={language.content_text}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state, { id }) => ({
  item: getInvoice(state, id),
  userData: getUserData(state),
  paymentMethod: getPaymentMethod(state),
});

InvoiceCard.defaultProps = {
  userData: Map(),
  item: Map(),
};

InvoiceCard.propTypes = {
  userData: ImmutablePropTypes.mapContains({
    city: PropTypes.string,
    customerCode: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mobilePhone: PropTypes.string,
    phone: PropTypes.string,
    statusCode: PropTypes.number,
    statusMessage: PropTypes.string,
    streetAddress: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  item: ImmutablePropTypes.mapContains({
    collectionDate: PropTypes.string,
    dueDate: PropTypes.string,
    fee: PropTypes.number,
    invoiceAmount: PropTypes.number,
    invoiceDate: PropTypes.string,
    invoiceNumber: PropTypes.string,
    lastPaymentDate: PropTypes.string,
    nextInvoiceDate: PropTypes.number,
    ocr: PropTypes.string,
    orderNumber: PropTypes.number,
    paymentStatus: PropTypes.string,
    remainingAmount: PropTypes.string,
    remindDate1: PropTypes.number,
    remindDate2: PropTypes.number,
    remindDate3: PropTypes.number,
    reminderCount: PropTypes.number,
    sentToCollectionAgency: PropTypes.bool,
    termsOfPayment: PropTypes.string,
    termsOfPaymentText: PropTypes.string,
    toPay: PropTypes.number,
  }),
};

export default connect(mapStateToProps)(InvoiceCard);
