import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { insuranceActions } from "../../actions";
import { insuranceSelectors, userSelectors } from "../../selectors";
import Button from "../helper/Button";
import LoadingSpinner from "../helper/LoadingSpinner";
import SelectComponent from "../helper/SelectComponent";
import { modalConstants } from "../../constants";
import lang from "../../helpers/lang-constants";

const { MODAL_NOT_FOCUSED } = modalConstants;
const { getUpdating } = insuranceSelectors;
const { getKeyTabs } = userSelectors;
const language = lang.customer.components.modal.order_key_tabs_modal;

const MAX_KEY_TABS = 5;

class OrderKeyTabsModal extends PureComponent {
  render() {
    const { orderKeyTabs, isOrdering, closeModal, keyTabs } = this.props;
    const isNotRenewalTags = keyTabs.filter((keyTab) => !keyTab.isRenewalTag);
    const isNotRenewalTagsLength = isNotRenewalTags.length;
    const selectValues = Array(MAX_KEY_TABS - isNotRenewalTagsLength)
      .fill()
      .map((x, i) => ({
        value: i + 1,
        name: `${i + 1} +${(i + 1) * 10} kr/år`,
      }));

    return (
      <div className="modal content" tabIndex={MODAL_NOT_FOCUSED}>
        <h4 className="dialog-title flex row justify-between">
          {language.title}
          <i
            className="fal fa-times-circle modal-close color-gold"
            onClick={closeModal}
            onKeyPress={closeModal}
            tabIndex={0}
            role="button"
          />
        </h4>
        <div className="dialog-content">
          <span className="content-description dialog-form">
            {language.description}
          </span>

          <div className="flex row justify-center align-center">
            <p className="color-white">{language.item_count_text}</p>
            <Field
              name="keyTabs"
              component={SelectComponent}
              options={selectValues}
            />
          </div>
        </div>
        <div className="dialog-actions flex justify-center">
          <Button type="button" onClick={orderKeyTabs}>
            <LoadingSpinner
              text={language.submit_button.text}
              isLoading={isOrdering}
            />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOrdering: getUpdating(state, "keyTab"),
  keyTabs: getKeyTabs(state),
});
const mapDispatchToProps = (dispatch) => ({
  orderKeyTabs: () => dispatch(insuranceActions.orderKeyTabs()),
});

OrderKeyTabsModal.defaultProps = {
  isOrdering: false,
};

OrderKeyTabsModal.propTypes = {
  orderKeyTabs: PropTypes.func.isRequired,
  isOrdering: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  keyTabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default reduxForm({
  form: "orderKeyTabsForm",
  initialValues: {
    keyTabs: 1,
  },
})(connect(mapStateToProps, mapDispatchToProps)(OrderKeyTabsModal));
