import React from "react";
import { connect } from "react-redux";

import { userSelectors } from "../../selectors";

import { getInsuranceIconByVehicleType } from "../insurance/utils";

const { getVehicle } = userSelectors;

function VehicleClaimCard({ vehicleIcon, vehicleRegno, onClick, isSelected }) {
  return (
    <div
      className={`vehicle-claim-card${isSelected ? " selected" : ""}`}
      role="carousel-button"
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div className="vehicle-claim-background-image">
        <i className={`${vehicleIcon} vehicle-claim-icon`} />
      </div>
      <div className="vehicle-claim-regnr">{vehicleRegno}</div>
    </div>
  );
}

const mapStateToProps = (state, { id, vehicleIcon, vehicleRegno }) => {
  const vehicle = getVehicle(state, id);
  return {
    vehicleRegno: vehicleRegno ?? vehicle.getIn(["data", "regno"], ""),
    vehicleIcon:
      vehicleIcon ?? getInsuranceIconByVehicleType(vehicle.get("type")),
  };
};

export default connect(mapStateToProps)(VehicleClaimCard);
