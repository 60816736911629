import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { userActions } from "../../../../actions";
import Form from "../../../../components/helper/Form";
import Input from "../../../../components/helper/Input";
import { validation } from "../../../../helpers";
import { userSelectors } from "../../../../selectors";
import lang from "../../../../helpers/lang-constants";

const { number } = validation;
const { getUpdating } = userSelectors;
const language = lang.customer.views.details.details_form;

function DetailsForm({ handleSubmit, updateUser, isUpdating }) {
  return (
    <Form
      showButton
      className="details-form flex column"
      text={language.save_button.text}
      handleSubmit={handleSubmit(updateUser)}
      isLoading={isUpdating}
    >
      <Field
        id="email"
        label={language.email.text}
        name="email"
        component={Input}
        type="email"
        validate={validation.email}
        placeholder={language.email.placeholder_text}
        className="mobile-input-full-length"
      />
      <Field
        id="mobilePhone"
        label={language.phone.text}
        name="mobilePhone"
        component={Input}
        type="tel"
        validate={number}
        placeholder={language.phone.placeholder_text}
        className="mobile-input-full-length"
      />
      <Field
        id="born"
        label={language.born.text}
        name="born"
        component={Input}
        type="text"
        placeholder={language.born.placeholder_text}
        className="mobile-input-full-length"
      />
    </Form>
  );
}

DetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  userData: ImmutablePropTypes.mapOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  updateUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { userData }) => ({
  isUpdating: getUpdating(state, "updateProfile"),
  initialValues: {
    email: userData.get("email", ""),
    mobilePhone: userData.get("mobilePhone", ""),
    born: userData.get("born", ""),
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: () => dispatch(userActions.updateUser("detailsForm")),
});

DetailsForm = reduxForm({
  form: "detailsForm",
  enableReinitialize: true,
})(DetailsForm);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsForm);
