import React, { PureComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import { routesConstants } from "../../../constants";
import { device } from "../../../helpers";
import BankIdLogin from "./BankIdLogin";
import BankIdMobileLogin from "./BankIdMobileLogin";
import BankIdCollect from "./BankIdCollect";
import BankIdRedirect from "./BankIdRedirect";

const { LOGIN, BANKID_COLLECT, BANKID_SIGN, BANKID_REDIRECT } = routesConstants;

class BankIdContainer extends PureComponent {
  render() {
    return (
      <div>
        <Route path={BANKID_SIGN} component={BankIdLogin} />
        <Route
          exact
          path={`${BANKID_COLLECT}/:orderRef`}
          component={BankIdCollect}
        />
        <Route
          exact
          path={`${BANKID_REDIRECT}/:orderRef`}
          component={BankIdRedirect}
        />
        {device.isPhone() ? (
          <Route exact path={LOGIN} component={BankIdMobileLogin} />
        ) : (
          <Route
            exact
            path={LOGIN}
            render={() => <Redirect to={BANKID_SIGN} />}
          />
        )}
      </div>
    );
  }
}

export default BankIdContainer;
