import { Map } from "immutable";

const getBenefit = ({ benefits }, id) => benefits.getIn(["items", id], Map());
const getBenefitIds = ({ benefits }) => benefits.get("itemIds", Map());
const getLoading = ({ benefits }) => benefits.get("loading", false);

export default {
  getBenefitIds,
  getLoading,
  getBenefit,
};
