import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

function Input({
  input,
  errorText,
  label,
  meta: { error, submitFailed, touched },
  children,
  icon,
  showErrorOnSubmit,
  id,
  ...rest
}) {
  return (
    <div
      className={classNames(
        "input-container flex column campaign",
        rest.className
      )}
    >
      <label htmlFor={id} className="form-label flex column align-start">
        {Boolean(label) && (
          <div
            className={classNames({
              "flex row align-center justify-between form-label-container":
                Boolean(children),
            })}
          >
            {label}
            {children}
          </div>
        )}
        <div className="flex row">
          <input
            id={id}
            {...rest}
            {...input}
            className={classNames({
              "invalid-field": Boolean(error) && touched,
            })}
          />
          <i className={icon} />
        </div>
      </label>
      <span className="error-message align-text-left">
        {showErrorOnSubmit
          ? submitFailed && Boolean(error || errorText) && (error || errorText)
          : touched && Boolean(error || errorText) && (error || errorText)}
      </span>
    </div>
  );
}

Input.defaultProps = {
  label: "",
  children: "",
  error: "",
  icon: "",
  id: "",
  showErrorOnSubmit: false,
};

Input.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  showErrorOnSubmit: PropTypes.bool,
};

export default Input;
