import { themes } from "../../../helpers/config";
import CoInsuredSe from "./CoInsuredSe";
import CoInsuredNorway from "./CoInsuredNorway";

let CoInsuredComponent;

if (themes.isNorway()) {
  CoInsuredComponent = CoInsuredNorway;
} else if (themes.isAssistans()) {
  CoInsuredComponent = CoInsuredSe;
} else {
  CoInsuredComponent = CoInsuredSe;
}

export { CoInsuredComponent };
