import React from "react";
import FormCard from "../../../components/formCard/FormCard";
import { getQuestionErrorMessage } from "../../../helpers/utils";

import lang from "../../../helpers/lang-constants";

const language = lang.customer.views.claim.questions.question_date_of_accident;

const dateValidator = (value) => {
  let errorMessage;

  const validDate = /\d{4}-\d{2}-\d{2}/gm;
  const valid = validDate.test(value);
  if (!valid) {
    errorMessage = language.error_message;
  }
  return errorMessage;
};

function QuestionDateOfAccident(props) {
  const onChangeDateHandler = (event) => {
    const { value } = event.target;
    const validationMessage = dateValidator(value);
    props.setState((prevState) => ({ date: value, time: prevState.time }));
    if (props.state.time) {
      props.showNextQuestion(props.name, validationMessage);
    }
  };

  const onChangeTimeHandler = (event) => {
    const { value } = event.target;
    props.setState((prevState) => ({ date: prevState.date, time: value }));
    if (props.state.date) {
      props.showNextQuestion(props.name, "");
    }
  };

  const errorMessage = getQuestionErrorMessage(props.showList, props.name);

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <label htmlFor="QuestionDateOfAccident">
        <div className="date-time-select-container">
          <input
            className="input-date-claims"
            type="date"
            max="2100-12-31"
            name="QuestionDateOfAccident"
            id="QuestionDateOfAccident"
            onChange={onChangeDateHandler}
          />
          <input
            className="input-time-claims"
            type="time"
            step="60"
            name="QuestionTimeOfAccident"
            id="QuestionTimeOfAccident"
            onChange={onChangeTimeHandler}
          />
        </div>
        <span className="questions-error-message">{errorMessage || " "}</span>
      </label>
    </FormCard>
  );
}

export default QuestionDateOfAccident;
