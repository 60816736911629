import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import FormCard from "../../../components/formCard/FormCard";
import lang from "../../../helpers/lang-constants";
import { getQuestionErrorMessage } from "../../../helpers/utils";
import { insuranceSelectors } from "../../../selectors";
import { insuranceActions } from "../../../actions";
import AddAttachments from "../../../components/attachments/AddAttachments";

const language = lang.customer.views.claim.questions.question_add_attachment;

const { getUpdating } = insuranceSelectors;

const isIncorrectFileSize = (filesArray) => {
  const sizeLimit = 20000000;
  const illegalSize = filesArray.some((file) => file.size > sizeLimit);

  return illegalSize;
};

const findExistingNameInArray = (storedFiles, checkedName) => {
  return storedFiles.some((storedFile) => {
    return storedFile.name === checkedName;
  });
};

const checkForExistingFileName = (files, storedFiles) => {
  let doubleName = false;
  files.every((file) => {
    if (findExistingNameInArray(storedFiles, file.name)) {
      doubleName = true;
      return false;
    }
    return true;
  });
  return doubleName;
};

function QuestionAddAttachment(props) {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const guidFileName = uuidv4();
    props.setState((prevState) => ({
      ...prevState,
      guid: guidFileName,
    }));
  }, []);

  const postToAwsS3 = async (file) => props.fileUpload(file, props.state.guid);

  const createAttachedArray = async (files) => {
    const newAttachedFilesArray = [];

    await Promise.all(
      files.map(async (file) => {
        const awsBucketUrl = await postToAwsS3(file);
        const attachedFileObject = { name: file.name, awsBucketUrl };
        newAttachedFilesArray.push(attachedFileObject);
      })
    );

    return newAttachedFilesArray;
  };

  const onAddFileHandler = async (event) => {
    const addedFiles = [...event.target.files];
    const attachedFilesUpdate = [...props.state.attachedFiles];
    if (addedFiles.length + attachedFilesUpdate.length > 5) {
      setErrorMessage(language.error_message_files);
    } else if (isIncorrectFileSize(addedFiles)) {
      setErrorMessage(language.error_message_size);
    } else if (
      checkForExistingFileName(addedFiles, props.state.attachedFiles)
    ) {
      setErrorMessage("kan inte ha flera filer med samma namn");
    } else if (addedFiles.length > 0) {
      setErrorMessage("");
      const modifiedAddedFiles = await createAttachedArray(addedFiles);

      attachedFilesUpdate.push(...modifiedAddedFiles);
      props.setState((prevState) => ({
        ...prevState,
        attachedFiles: attachedFilesUpdate,
      }));
      props.showNextQuestion(props.name);
    }
  };

  const removeFile = (fileName) => {
    const fileIndex = props.state.attachedFiles.findIndex((fileObject) => {
      return fileObject.name === fileName;
    });

    const updateState = [...props.state.attachedFiles];
    updateState.splice(fileIndex, 1);
    props.setState((prevState) => ({
      ...prevState,
      attachedFiles: updateState,
    }));

    if (updateState.length <= 0) {
      props.showNextQuestion(props.name, language.error_message);
    }
    setErrorMessage("");
  };
  const tags = props.state.attachedFiles.map((file) => {
    return (
      <div className="attached-file-tag" key={file.name}>
        {file.name}
        <span onClick={() => removeFile(file.name)}> X</span>
      </div>
    );
  });

  const missingFilesErrorMessage = getQuestionErrorMessage(
    props.showList,
    props.name
  );

  return (
    <FormCard
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <div className="add-claim-info-container">
        <AddAttachments
          onAddFileHandler={onAddFileHandler}
          title={language.attachment_3.title}
          description={language.attachment_3.description}
        />
        <AddAttachments
          onAddFileHandler={onAddFileHandler}
          title={language.attachment_1.title}
          description={language.attachment_1.description}
        />
        <AddAttachments
          onAddFileHandler={onAddFileHandler}
          title={language.attachment_2.title}
          description={language.attachment_2.description}
        />
        <p className="questions-error-message">{errorMessage}</p>
      </div>
      <div className="attached-files-tags-container">{tags}</div>
      <span style={{ display: "block", color: "red" }}>
        {missingFilesErrorMessage || " "}
      </span>
    </FormCard>
  );
}

const mapStateToProps = (state) => ({
  isLoading: getUpdating(state, "fileUploadUrl"),
});
const mapDispatchToProps = (dispatch) => ({
  fileUpload: (file, guid) => dispatch(insuranceActions.fileUpload(file, guid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionAddAttachment);
