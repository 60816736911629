import React from "react";
import classNames from "classnames";
import lang from "../../helpers/lang-constants";

const language = lang.customer.components.navbar.hamburger_menu_icon;

function HamburgerMenuIcon({ ...rest }) {
  return (
    <div
      className={classNames("hamburger-icon", rest.className || "")}
      onClick={rest.onClick || undefined}
      onKeyPress={rest.onClick || undefined}
      role={rest.onClick ? "button" : ""}
    >
      <span className="hamburger-line" />
      <span className="hamburger-line" />
      <span className="hamburger-line" />
      <span className="hamburger-name">{language.menu_text}</span>
    </div>
  );
}

export default HamburgerMenuIcon;
