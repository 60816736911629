import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";

function Form({
  children,
  handleSubmit,
  text = "",
  showButton = true,
  isLoading,
  isRow = true,
  ...rest
}) {
  return (
    <form onSubmit={handleSubmit} id="form" {...rest}>
      <div className={classNames({ flex: isRow }, { "flex column": !isRow })}>
        {children}
      </div>
      {showButton && (
        <Button
          type="submit"
          btnType="primary"
          style={{ alignSelf: "flex-end" }}
        >
          <LoadingSpinner size={14} isLoading={isLoading} text={text} />
        </Button>
      )}
    </form>
  );
}
Form.defaultProps = {
  text: "",
  showButton: true,
  isLoading: false,
  isRow: true,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
  showButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRow: PropTypes.bool,
};

export default Form;
