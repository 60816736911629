import classNames from "classnames";
import { Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { insuranceSelectors } from "../../selectors";
import RowContainer from "../helper/RowContainer";
import lang from "../../helpers/lang-constants";
import formatDate from "../../helpers/formatter";

const { getItemGroupByOrderNumberState } = insuranceSelectors;
const mapPaymentPeriod = {
  12: "Helår",
  6: "Halvår",
  3: "Kvartal",
  1: "Månadsvis",
};
const langauge = lang.customer.components.invoice.invoice_personal_card;

function InvoicePersonalCard({ item, userData, insuranceGroup }) {
  const invoiceDate = item.get("invoiceDate", "");
  const insuranceNumber = item.get("orderNumber", "");
  const name = `${userData.get("firstName", "")} ${userData.get(
    "lastName",
    ""
  )}`;
  const customerCode = userData.get("customerCode");
  const lastPaymentDate = item.get("dueDate", "");
  const paymentMethod = item.get("termsOfPaymentText", "");
  const paymentStatus = item.get("paymentStatus", "");
  const isStatusOk = paymentStatus.toLowerCase() !== "ej betald";
  const insurancePeriod = insuranceGroup.size
    ? `${formatDate(insuranceGroup.first().get("startDate", ""))} 
        - 
        ${formatDate(insuranceGroup.first().get("endDate", ""))}`
    : ``;
  const paymentPeriod = insuranceGroup.size
    ? insuranceGroup.first().get("paymentInterval", "")
    : "";
  return (
    <div className="personal-data flex column">
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.insuree_text}
        </span>
        <span itemType="leftContent" className="content-primary">
          {name}
        </span>
        <span itemType="rightTitle" className="label-bold align-text-right">
          {langauge.issue_date_text}
        </span>
        <span
          itemType="rightContent"
          className="content-primary align-text-right"
        >
          {formatDate(invoiceDate)}
        </span>
      </RowContainer>
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.customer_identifier}
        </span>
        <span itemType="leftContent" className="content-primary">
          {userData.get("born", "")}
        </span>
        <span itemType="rightTitle" className="label-bold align-text-right">
          {langauge.due_date_text}
        </span>
        <span
          itemType="rightContent"
          className="content-primary align-text-right"
        >
          {formatDate(lastPaymentDate)}
        </span>
      </RowContainer>
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.customer_code}
        </span>
        <span itemType="leftContent" className="content-primary">
          {customerCode}
        </span>
      </RowContainer>
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.insurance_code}
        </span>
        <span itemType="leftContent" className="content-primary">
          {insuranceNumber}
        </span>
      </RowContainer>
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.insurance_period}
        </span>
        <span itemType="leftContent" className="content-primary">
          {insurancePeriod}
        </span>
      </RowContainer>
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.payment_method}
        </span>
        <span itemType="leftContent" className="content-primary">
          {paymentMethod}
        </span>
      </RowContainer>
      <RowContainer>
        <span itemType="leftTitle" className="label-bold">
          {langauge.payment_period}
        </span>
        <span itemType="leftContent" className="content-primary">
          {mapPaymentPeriod[paymentPeriod]}
        </span>
        <span itemType="rightTitle" className="label-grey align-text-right">
          {langauge.payment_status}
        </span>
        <span
          itemType="rightContent"
          className={classNames("align-text-right uppercase", {
            "color-red": !isStatusOk,
            "color-green": isStatusOk,
          })}
          style={{ fontSize: "16px" }}
        >
          <semi-bold>{paymentStatus}</semi-bold>
        </span>
      </RowContainer>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getInsurance = getItemGroupByOrderNumberState();
  return (state, { item }) => ({
    insuranceGroup: getInsurance(
      state,
      item.get("orderNumber") ? item.get("orderNumber").toString() : ""
    ),
  });
};

InvoicePersonalCard.defaultProps = {
  userData: Map(),
  item: Map(),
};

InvoicePersonalCard.propTypes = {
  userData: ImmutablePropTypes.mapContains({
    city: PropTypes.string,
    customerCode: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mobilePhone: PropTypes.string,
    phone: PropTypes.string,
    statusCode: PropTypes.number,
    statusMessage: PropTypes.string,
    streetAddress: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  item: ImmutablePropTypes.mapContains({
    collectionDate: PropTypes.string,
    dueDate: PropTypes.string,
    fee: PropTypes.number,
    invoiceAmount: PropTypes.number,
    invoiceDate: PropTypes.string,
    invoiceNumber: PropTypes.string,
    lastPaymentDate: PropTypes.string,
    nextInvoiceDate: PropTypes.number,
    ocr: PropTypes.string,
    orderNumber: PropTypes.number,
    paymentStatus: PropTypes.string,
    remainingAmount: PropTypes.string,
    remindDate1: PropTypes.number,
    remindDate2: PropTypes.number,
    remindDate3: PropTypes.number,
    reminderCount: PropTypes.number,
    sentToCollectionAgency: PropTypes.bool,
    termsOfPayment: PropTypes.string,
    termsOfPaymentText: PropTypes.string,
    toPay: PropTypes.number,
  }),
};

export default connect(makeMapStateToProps)(InvoicePersonalCard);
