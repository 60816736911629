import { Map } from "immutable";

const getUpdating = ({ family }, type) =>
  family.getIn(["updating", type], false);

const getStatusMessage = ({ family }, type) =>
  family.getIn(["statusMessage", type], "");
const getFamilyInHouseHold = ({ family }) =>
  family.get("familyInHouseHoldData", Map());

export default {
  getUpdating,
  getStatusMessage,
  getFamilyInHouseHold,
};
