import api_dk from "./dk/api";
import api_se from "./se/api";
import api_sb from "./sb/api";
import api_no from "./no/api";
import { themes } from "../../helpers/config";

let base_api;
if (themes.isAssistans()) {
  base_api = api_se;
} else if (themes.isNorway()) {
  base_api = api_no;
} else if (themes.isDenmark()) {
  base_api = api_dk;
} else if (themes.isSbf()) {
  base_api = api_sb;
}

export default base_api;
