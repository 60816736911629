import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { bannerActions } from "../../actions";
import { routesConstants } from "../../constants";
import { bannerSelectors } from "../../selectors";
import { history } from "../../helpers";

const { SHOP, CUSTOMER_SERVICE } = routesConstants;

const { getLoading, getBanner } = bannerSelectors;

class VerticalPromotionalBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      path: history.location,
      hideSnackBar: false,
    };
  }

  componentDidMount() {
    const { fetchBanner } = this.props;
    fetchBanner();
    history.listen((location) => this.setState({ path: location }));
  }

  render() {
    const { isLoading, banner, ...rest } = this.props;
    const backgroundImage = banner.get("backgroundImage", "");
    const linkUrl = banner.getIn(["link", "url"], "");
    const linkTitle = banner.getIn(["link", "title"], "");
    const { path, hideSnackBar } = this.state;
    const style = hideSnackBar ? { display: "none" } : {};
    const isActive = banner.get("active", "");
    const isShopPageOrCustomerService = () =>
      path.pathname === SHOP || path.pathname === CUSTOMER_SERVICE;

    return (
      !isShopPageOrCustomerService() && (
        <div className={classNames(rest.className || "")} style={style}>
          <div className="container">
            {isActive && (
              <a href={linkUrl} alt={linkTitle}>
                <div
                  className="content flex column background-image"
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                  }}
                />
              </a>
            )}
          </div>
        </div>
      )
    );
  }
}

VerticalPromotionalBanner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchBanner: PropTypes.func.isRequired,
  banner: ImmutablePropTypes.mapContains({
    active: PropTypes.bool,
    backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    link: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: getLoading(state),
  banner: getBanner(state, "verticalPromotionalBanner"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBanner: () => dispatch(bannerActions.fetchBanners()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalPromotionalBanner);
