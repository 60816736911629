import { env } from "../../../helpers/config";
import { COUNTRY_CODES } from "../constants";
import BaseApi from "../base_urls.skeleton";

const base_urls = new BaseApi();
const country_code = COUNTRY_CODES.SE;

if (env.isDevelopment()) {
  base_urls.API = "https://ab.dev.assi.nu";
  base_urls.WP = "http://orig.test-cluster.assistansbolaget.nu";
  base_urls.ETRACK =
    "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=151cfd94-b556-497b-b852-79f9ccf0f3c0&catondetail=1&group=hemsida";
  base_urls.COUNTRY_CODE = country_code;
  base_urls.POLICY_LINK =
    "http://orig.test-cluster.assistansbolaget.nu/villkor";
} else if (env.isStaging()) {
  base_urls.API = "https://ab.stg.assi.nu";
  base_urls.WP = "https://assistansbolaget.nu";
  base_urls.ETRACK =
    "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=151cfd94-b556-497b-b852-79f9ccf0f3c0&catondetail=1&group=hemsida";
  base_urls.COUNTRY_CODE = country_code;
  base_urls.POLICY_LINK = "https://assistansbolaget.nu/villkor";
} else if (env.isProduction()) {
  base_urls.API = "https://ab.assi.nu";
  base_urls.WP = "https://assistansbolaget.nu";
  base_urls.ETRACK =
    "https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=151cfd94-b556-497b-b852-79f9ccf0f3c0&catondetail=1&group=hemsida";
  base_urls.COUNTRY_CODE = country_code;
  base_urls.POLICY_LINK = "https://assistansbolaget.nu/villkor";
}

export default base_urls;
