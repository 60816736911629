import { vehicleConstants } from "../../../../../constants";

const { PERSONAL_VEHICLE } = vehicleConstants;

export default {
  articleCode: "300001",
  // Shadow type is our internal type of this insurance since AB have 1 type for 3 insurances
  shadowType: "SJF300001",
  types: ["SJF"],
  coverage: [PERSONAL_VEHICLE],
  isCoInsurable: false,
};
