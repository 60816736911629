import React from "react";
import { connect } from "react-redux";
import {
  insuranceSelectors,
  invoiceSelectors,
  userSelectors,
} from "../../selectors";
import BannerDirectDebit from "../banner/BannerDirectDebit";
import RowContainer from "../helper/RowContainer";
import lang from "../../helpers/lang-constants";
import { themes } from "../../helpers/config";

const { getItemGroupState } = insuranceSelectors;
const language = lang.customer.components.insurance.summation_card;
const { getUserData } = userSelectors;

function SummationCard({ itemGroup, userData }) {
  let customerCode = "";
  if (themes.isAssistans()) {
    customerCode = `${itemGroup
      .first()
      .get("customerCode", "")
      .slice(0, 6)}-${itemGroup.first().get("customerCode", "").slice(6, 10)}`;
  } else {
    customerCode = itemGroup.first().get("customerCode", "");
  }

  return (
    <div className="container summation">
      <RowContainer className="summation_wrapper">
        <span itemType="leftTitle" className="label-bold">
          {language.insured_text}
        </span>
        <span itemType="leftContent" className="content">
          {itemGroup.first().get("subscriberName", "")}
        </span>
        <span itemType="centerTitle" className="label-bold">
          {language.customer_identifier}
        </span>
        <span itemType="centerContent" className="content">
          {themes.isNorway() ? userData.get("born", "") : customerCode}
        </span>
        <span itemType="rightTitle" className="label-bold align-text-right">
          {language.customer_code}
        </span>
        <span itemType="rightContent" className="content align-text-right">
          {customerCode}
        </span>
      </RowContainer>
      {!itemGroup
        .first()
        .get("paymentMethodName")
        .toLowerCase()
        .includes("autogiro") && (
        <BannerDirectDebit
          heading={`${itemGroup.first().get("subscriberName")}, ${
            language.banner_direct_debit.heading_text
          }`}
        />
      )}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getItemGroup = getItemGroupState();
  return (state, { id }) => ({
    itemGroup: getItemGroup(state, id),
    userData: getUserData(state),
  });
};

export default connect(makeMapStateToProps)(SummationCard);
