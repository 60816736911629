import { snackbarConstants } from "../constants";

const { OPEN_SNACKBAR, CLOSE_SNACKBAR } = snackbarConstants;

const openSnackbar = (snackbarType, title, message) => ({
  type: OPEN_SNACKBAR,
  snackbarType,
  title,
  message,
});

const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

export default {
  openSnackbar,
  closeSnackbar,
};
