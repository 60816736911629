import { func } from "prop-types";
import { authHeader, history } from "../helpers";
import { apiConstants, routesConstants } from "../constants";
import handleInsuranceResponse from "./handlers/insurances/insuranceHandler";
import handleVehicleResponse from "./handlers/vehicle/vehicleHandler";

const { LOGIN } = routesConstants;

function logout() {
  localStorage.removeItem("user");
  history.push(LOGIN);
}

function handleResponse(response) {
  if (process.env.NODE_ENV === "development") console.log("response", response);
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (response.ok) return data;
    if (response.status === 401) {
      logout();
      return Promise.reject({
        unauthorized: true,
      });
    }
    const error = data || response.statusText;
    return Promise.reject(error);
  });
}

function getInsurances() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(apiConstants.API.USER.INSURANCES, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return handleInsuranceResponse(data);
    });
}

function startKlarnaOpenBankingSession() {
  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/x-www-form-urlencoded"),
  };

  return fetch(apiConstants.API.INSURANCE.KLARNA_START_SESSION, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return data;
    });
}

function fetchKlarnaOpenBankingData(sessionId, flowId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/x-www-form-urlencoded"),
  };

  return fetch(
    apiConstants.API.INSURANCE.KLARNA_FETCH_DATA(sessionId, flowId),
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return data;
    });
}

function getVehicleInsurance(regNr) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiConstants.API.INSURANCE.VEHICLE(regNr), requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return data;
    });
}

async function putInBucket(signedBucketUrl, file, acl) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "x-amz-acl": acl,
      "Content-Type": file.type,
    },
    body: file,
  };

  return fetch(signedBucketUrl, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return data;
    });
}

function getSignedUrlFromAWS(file, guidFileName, folderName, acl) {
  const body = {
    keyName: guidFileName,
    folder: folderName,
    acl,
    contentType: file.type,
  };

  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(apiConstants.API.INSURANCE.FILE_UPLOAD_URL, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return data.url;
    });
}

function addCoInsured(payload) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiConstants.API.USER.COINSURANCES, requestOptions)
    .then((response) =>
      response.text().then((text) => text && JSON.parse(text))
    )
    .then((data) => {
      if (process.env.NODE_ENV === "development") console.log("response", data);
      return data;
    });
}
// Add coinsured with details for Norway
function addCoInsuredDetailed(payload) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiConstants.API.USER.COINSURANCES, requestOptions)
    .then((response) =>
      response.text().then((text) => text && JSON.parse(text))
    )
    .then((data) => {
      if (process.env.NODE_ENV === "development")
        console.log("test data", data);
      return data;
    });
}

const signNewInsurance = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiConstants.API.USER.INSURANCES, requestOptions)
    .then((response) =>
      response.text().then((text) => text && JSON.parse(text))
    )
    .then((data) => data);
};

const orderKeyTabs = (numberOfKeyTabs) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(numberOfKeyTabs),
  };
  return fetch(apiConstants.API.USER.ORDER_KEY_TABS, requestOptions)
    .then(handleResponse)
    .then((data) => data);
};

const postClaimInsurance = (formContent) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(formContent),
  };
  return fetch(apiConstants.API.INSURANCE.CLAIM, requestOptions)
    .then(handleResponse)
    .then((data) => data);
};

function coInsurancePriceLookup(articleCode, to) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = apiConstants.API.INSURANCE.PRICEINFO(articleCode, to);
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

function checkEndableInsurances() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = apiConstants.API.INSURANCE.CHECK_ENDABLE_INSURANCES;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

function controlEndInsurances(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const url = apiConstants.API.INSURANCE.CONTROL_END_INSURANCES;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

function fetchTerminateReasons() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = apiConstants.API.INSURANCE.TERMINATE_INSURANCE_REASONS;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

function endInsurances(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  const url = apiConstants.API.INSURANCE.END_INSURANCES;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => data);
}

// TODO - build new endpoints here

export default {
  getInsurances,
  startKlarnaOpenBankingSession,
  fetchKlarnaOpenBankingData,
  addCoInsured,
  orderKeyTabs,
  signNewInsurance,
  postClaimInsurance,
  addCoInsuredDetailed,
  coInsurancePriceLookup,
  getVehicleInsurance,
  putInBucket,
  getSignedUrlFromAWS,
  checkEndableInsurances,
  controlEndInsurances,
  fetchTerminateReasons,
  endInsurances,
};
