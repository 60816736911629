import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { destroy } from "redux-form";
import { modalActions } from "../../actions";
import {
  insuranceSelectors,
  familySelectors,
  policySelector,
} from "../../selectors";
import Button from "../helper/Button";
import RowContainer from "../helper/RowContainer";
import ShowCoInsured from "./ShowCoInsured";
import ShowCoInsuredSuggestion from "./ShowCoInsuredSuggestion";
import lang from "../../helpers/lang-constants";
import { apiConstants } from "../../constants";
import formatDate from "../../helpers/formatter";
import pdfIcon from "../../assets/images/pdf_ikon.png";
import policyActions from "../../actions/policy.actions";
import LoadingSpinner from "../helper/LoadingSpinner";

const { getUserInsurance, getInsuranceIcon } = insuranceSelectors;
const { getFamilyInHouseHold } = familySelectors;
const {
  getCurrentInsurancePolicy,
  getUpcomingInsurancePolicy,
  getDateInsurancePolicy,
  getBlobInsurancePolicy,
  getLoading,
  getUpdating,
} = policySelector;

const ONE_YEAR = 12;
const language = lang.customer.components.insurance.insurance_card;

function InsuranceCard({
  userInsurance,
  openModal,
  family,
  currentInsurancePolicy,
  upcomingInsurancePolicy,
  dateInsurancePolicy,
  fetchInsurancePolicy,
  isLoadingupcoming,
  isLoadingCurrent,
}) {
  const list = userInsurance.valueSeq().map((item, i) => {
    const termsAndConditions = item.get("termsAndConditions");
    const itemName = item.get("productName");
    const endDate = item.get("endDate");
    const insuranceNumber = item.get("insuranceNumber");
    const monthPrice = item.get("monthPriceIncludingVAT");
    const annualCost = monthPrice * ONE_YEAR;
    const startDate = item.get("startDate");
    const coInsurances = item.get("coInsurances");
    let allFamilyinsured = true;
    const currentpdf = currentInsurancePolicy.get(insuranceNumber, "");
    const upcomingpdf = upcomingInsurancePolicy.get(insuranceNumber, "");
    const dateActive = dateInsurancePolicy.get(insuranceNumber, "");

    if (coInsurances.size === 0) {
      allFamilyinsured = false;
    } else {
      allFamilyinsured = !coInsurances.some((coInsured) => {
        return family
          .toList()
          .some(
            (fm) =>
              fm.get("socialNumber", "") !== coInsured.get("customerCode", "")
          );
      });
    }
    let coInsuredTotalCost = 0;
    coInsurances.map((coInsured) => {
      if (coInsured.get("isActive")) {
        coInsuredTotalCost +=
          coInsured.get("monthPriceIncludingVAT") * ONE_YEAR;
      }
    });

    // const getPdf = (order, fil) => {
    //   const promise = new Promise((resolve) => {
    //     fetchInsurancePolicy(order, fil);

    //     resolve("success");
    //   }).then(() => new Promise((resolve) => setTimeout(resolve, 3000)));
    //   promise.then((result) => {
    //     console.log("sesam öppnadig");
    //     const file = new Blob([blobInsurance], {
    //       method: "GET",
    //       type: "application/pdf",
    //       responseType: "arrayBuffer",
    //       headers: authPdf(),
    //     });

    //     window.open(URL.createObjectURL(file), "_blank");
    //     console.log(URL.createObjectURL(file), "hej");
    //     return result;
    //   });
    // };
    const isCoInsurable = item.get("isCoInsurable");
    const showCoInsuranceSuggestion =
      family.size > 0 && !allFamilyinsured && isCoInsurable;
    const totalCost = coInsuredTotalCost
      ? annualCost + coInsuredTotalCost
      : annualCost;
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${i}-_${insuranceNumber}`}>
        {item.get("isActive") && item.get("articleType") !== "NB1" && (
          <div className="insurance-card flex column align-center justify-between">
            <div className="insurance-name flex row align-center insurance-description">
              <h4>{itemName}</h4>
              <i
                className={`${getInsuranceIcon(
                  item.get("articleType")
                )} insurance-icon`}
              />
            </div>
            <RowContainer>
              <span itemType="leftTitle" className="label-bold">
                {language.insurance_period_text}
              </span>
              <span itemType="leftContent" className="content">
                {`${formatDate(startDate)} till ${formatDate(endDate)}`}
              </span>
              <span itemType="centerTitle" className="label-bold" />
              <span itemType="centerContent" className="content" />
              <span
                itemType="rightTitle"
                className="label-bold align-text-right"
              >
                {language.insurance_number_text}
              </span>
              <span
                itemType="rightContent"
                className="content align-text-right"
              >
                {insuranceNumber}
              </span>
            </RowContainer>
            {coInsurances.size > 0 && (
              <ShowCoInsured coInsureds={coInsurances} />
            )}

            {showCoInsuranceSuggestion && (
              <>
                <hr className="insurance-divider" />
                <ShowCoInsuredSuggestion
                  openModal={openModal}
                  coInsureds={coInsurances}
                  monthPrice={monthPrice}
                  articleCode={item.get("articleCode")}
                />
              </>
            )}

            {isCoInsurable && (
              <>
                <hr className="insurance-divider" />
                <RowContainer className="text-block">
                  <span
                    itemType="leftTitle"
                    className="label-bold"
                    style={{ marginBottom: "5px" }}
                  >
                    {language.co_insurance_card.title_text}
                  </span>
                  <span
                    itemType="leftContent"
                    className="content text"
                    style={{ maxWidth: "400px" }}
                  >
                    {`${language.co_insurance_card.description_text} `}
                    <span className="no-white-space">
                      {language.co_insurance_card.cost}
                      {language.co_insurance_card.annual_fee_text}.
                    </span>
                  </span>
                  <Button
                    type="submit"
                    itemType="rightContent"
                    btnType="co-insured"
                    onClick={() => openModal(item.get("articleCode", 0))}
                    style={{ margin: "auto" }}
                  >
                    {language.co_insurance_card.button.text}
                  </Button>
                </RowContainer>
              </>
            )}
            <hr className="insurance-divider" />

            <RowContainer className="row align-second-end-phone">
              <span
                itemType="leftTitle"
                className="label-bold"
                style={{ marginBottom: "5px" }}
              >
                {language.insurance_terms_text}
              </span>
              <span
                itemType="leftContent"
                className="flex row align-center insurance-terms"
              >
                {termsAndConditions.length > 0 && (
                  <a
                    href={apiConstants.POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {language.insurance_terms_text} {termsAndConditions}
                    <i className="fas fa-chevron-right" />
                  </a>
                )}
              </span>
              <span
                itemType="rightTitle"
                className="label-bold align-text-right"
              >
                {language.total_anual_payment_text}
              </span>
              <span
                itemType="rightContent"
                className="content align-text-right"
              >
                {Math.round(totalCost)} {language.currency_display_text}
              </span>
            </RowContainer>
            <hr className="insurance-divider" />

            <RowContainer className="row align-second-end-phone">
              <span
                itemType="rightTitle"
                className="label-bold align-text-right insurance-spinner"
              >
                <LoadingSpinner isLoading={isLoadingCurrent} size={20} />
              </span>
              <span
                itemType="leftTitle"
                className="label-bold"
                style={{ marginBottom: "5px" }}
              >
                {language.insurance_letters_text}
              </span>

              <span
                itemType="leftContent"
                className="flex row align-center insurance-terms"
              >
                <div className="insurance-letters_wrapper">
                  {currentpdf.length > 0 && (
                    <div className="insurance-letters_content">
                      <div className="insurance-letters_image-container">
                        <img className="pdf-logo" src={pdfIcon} alt="pdfLogo" />
                      </div>
                      <Button
                        type="button"
                        onClick={() =>
                          fetchInsurancePolicy(insuranceNumber, currentpdf)
                        }
                        className="insurnace-letters_link"
                        id="current"
                      >
                        {language.insurance_letters_text}
                        <div className="insurance-letters_date">{`${formatDate(
                          startDate
                        )}`}</div>
                      </Button>
                    </div>
                  )}
                  {upcomingpdf.length > 0 && (
                    <div className="insurance-letters_content">
                      <div className="insurance-letters_image-container">
                        <img className="pdf-logo" src={pdfIcon} alt="pdfLogo" />
                      </div>
                      <Button
                        type="button"
                        onClick={() =>
                          fetchInsurancePolicy(insuranceNumber, upcomingpdf)
                        }
                        className="insurnace-letters_link"
                      >
                        {language.insurance_letters_text}
                        <div className="insurance-letters_date">{`${formatDate(
                          dateActive
                        )}`}</div>
                      </Button>
                    </div>
                  )}{" "}
                </div>
              </span>
            </RowContainer>
          </div>
        )}
      </div>
    );
  });
  return <div>{Boolean(list.size) && <div>{list}</div>}</div>;
}

InsuranceCard.propTypes = {
  openModal: PropTypes.func.isRequired,
  openEndInsuranceModal: PropTypes.func.isRequired,
  currentInsurancePolicy: PropTypes.oneOfType([
    ImmutablePropTypes.setOf(PropTypes.oneOfType([PropTypes.string])),
    ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.string)),
    ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    PropTypes.object,
  ]).isRequired,
  upcomingInsurancePolicy: PropTypes.oneOfType([
    ImmutablePropTypes.setOf(PropTypes.oneOfType([PropTypes.string])),
    ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.string)),
    ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    PropTypes.object,
  ]).isRequired,
  dateInsurancePolicy: PropTypes.oneOfType([
    ImmutablePropTypes.setOf(PropTypes.oneOfType([PropTypes.string])),
    ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.string)),
    ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    PropTypes.object,
  ]).isRequired,
  fetchInsurancePolicy: PropTypes.func.isRequired,
  family: ImmutablePropTypes.mapOf(ImmutablePropTypes.listOf(PropTypes.object))
    .isRequired,
  isLoadingCurrent: PropTypes.bool.isRequired,
  isLoadingupcoming: PropTypes.bool.isRequired,
  userInsurance: ImmutablePropTypes.mapContains(
    ImmutablePropTypes.mapContains({
      articleCode: PropTypes.string,
      articleType: PropTypes.string,
      isActive: PropTypes.bool,
      endDate: PropTypes.string,
      insuranceNumber: PropTypes.string,
      monthPriceExcludingVAT: PropTypes.number,
      monthPriceIncludingVAT: PropTypes.number,
      monthlyVAT: PropTypes.null,
      paymentInterval: PropTypes.number,
      paymentIntervalName: PropTypes.string,
      paymentMethod: PropTypes.string,
      paymentMethodName: PropTypes.string,
      productName: PropTypes.string,
      registrationNumber: PropTypes.string,
      customerCode: PropTypes.string,
      startDate: PropTypes.string,
      subscriberName: PropTypes.string,
      monthPriceExcludingVATString: PropTypes.string,
      monthPriceIncludingVATString: PropTypes.string,
      coInsurances: PropTypes.object,
      born: PropTypes.string,
    }).isRequired
  ).isRequired,
};

const mapStateToProps = (state, { id }) => ({
  userInsurance: getUserInsurance(state, id),
  family: getFamilyInHouseHold(state),
  currentInsurancePolicy: getCurrentInsurancePolicy(state),
  upcomingInsurancePolicy: getUpcomingInsurancePolicy(state),
  dateInsurancePolicy: getDateInsurancePolicy(state),
  blobInsurance: getBlobInsurancePolicy(state),
  isLoadingCurrent: getLoading(state).get("current", false),
  isLoadingupcoming: getLoading(state).get("upcoming", false),
});

const mapDispatchToProps = (dispatch, { id }) => ({
  openModal: (articleCode, familyMember) => {
    dispatch(
      modalActions.openModal("coInsured", articleCode, id, null, familyMember)
    );
    dispatch(destroy("getUserForm"));
  },
  openEndInsuranceModal: (item) => {
    dispatch(modalActions.openModal("endInsuranceModal", item));
  },
  fetchInsurancePolicy: (orderNumber, filename) =>
    dispatch(policyActions.fetchInsurancePolicy(orderNumber, filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceCard);
