import React from "react";
import FormCard from "../../../components/formCard/FormCard";
import lang from "../../../helpers/lang-constants";
import { getQuestionErrorMessage } from "../../../helpers/utils";

const language =
  lang.customer.views.claim.questions.question_add_additional_info;

const messageValidator = (value) => {
  let errorMessage;
  const validValue = /^[a-zA-Z]{1,}/gm;
  const valid = validValue.test(value);
  if (!valid) {
    errorMessage = language.error_message;
  }
  return errorMessage;
};

function QuestionAddAdditionalInfo(props) {
  const onChangeHandler = (event) => {
    const { value } = event.target;
    const validationMessage = messageValidator(value);
    props.showNextQuestion(props.name, validationMessage);
    props.setState(event.target.value);
  };

  const errorMessage = getQuestionErrorMessage(props.showList, props.name);

  return (
    <FormCard
      order={props.index}
      showList={props.showList}
      name={props.name}
      title={language.title_text}
      description={language.description_text}
    >
      <div className="textarea-container">
        <label htmlFor="QuestionAddAdditionalInfo">
          <textarea
            rows="7"
            type="text"
            name="QuestionAddAdditionalInfo"
            id="QuestionAddAdditionalInfo"
            onChange={onChangeHandler}
            value={props.state}
          />
          <span className="questions-error-message">{errorMessage || " "}</span>
        </label>
      </div>
    </FormCard>
  );
}

export default QuestionAddAdditionalInfo;
