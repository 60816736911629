import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bankIdActions } from "../../../actions";
import BankIdLoginForm from "./BankIdLoginForm";
import BankIdQrCode from "./BankIdQrCode";
import abErrorService from "../../../services/abError.service";
import abErrorActions from "../../../actions/abError.actions";

const isMobile = window.innerWidth < 768;
function BankIdLogin({
  requestBankIdAuth,
  requestBankIdQrCodeAuth,
  fetchErrorStatusOnMount,
  openBankidApplication,
}) {
  return isMobile ? (
    <BankIdLoginForm
      fetchErrorStatusOnMount={fetchErrorStatusOnMount}
      openBankidApplication={openBankidApplication}
      requestBankIdQrCodeAuth={requestBankIdQrCodeAuth}
    />
  ) : (
    <BankIdQrCode
      requestBankIdAuth={requestBankIdAuth}
      requestBankIdQrCodeAuth={requestBankIdQrCodeAuth}
      fetchErrorStatusOnMount={fetchErrorStatusOnMount}
    />
  );
}

const mapDispatchToProps = (dispatch) => {
  const { bankIdQrCodeAuthenticate, openBankidApplication } = bankIdActions;
  const { getMessages } = abErrorActions;

  return {
    openBankidApplication: () => {
      dispatch(openBankidApplication());
    },
    requestBankIdQrCodeAuth: () => {
      dispatch(bankIdQrCodeAuthenticate());
    },
    fetchErrorStatusOnMount: () => {
      dispatch(getMessages());
    },
  };
};

BankIdLogin.propTypes = {
  openBankidApplication: PropTypes.func.isRequired,
  requestBankIdQrCodeAuth: PropTypes.func.isRequired,
  fetchErrorStatusOnMount: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(BankIdLogin);
