import { Button } from "@material-ui/core";
import { React, useEffect } from "react";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import lang from "../../helpers/lang-constants";
import { userSelectors } from "../../selectors";
import { routesConstants } from "../../constants";
import { history } from "../../helpers";

const { getUserData } = userSelectors;
const language = lang.customer.views.claim.contact_info;

function ContactInformation(props) {
  const { email, mobilePhone } = props;
  const { DETAILS } = routesConstants;

  useEffect(() => {
    const { fetchUserData } = props;
    fetchUserData();
  }, []);

  const btnClick = () => {
    history.push(DETAILS);
  };

  return (
    <div className="claim">
      <div className="form-card">
        <h4>{language.header}</h4>
        <h6>{language.details}</h6>
        <span className="bold">{language.mail}</span>
        <span>{email}</span>
        <br />
        <span className="bold">{language.phone}</span>
        <span>{mobilePhone}</span>
        <br />
        <br />
        <Button
          type="button"
          className="button button-primary sign-insurance btn-gray"
          onClick={() => btnClick()}
        >
          {language.update}
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: getUserData(state).get("email", ""),
  mobilePhone: getUserData(state).get("mobilePhone", ""),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: () => dispatch(userActions.getUserData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
