export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getEpochTime() {
  return new Date().getTime();
}

export const getQuestionErrorMessage = (questionList, questionName) => {
  const errorMessage = questionList.find(
    (item) => item.name === questionName
  )?.errorMessage;
  return errorMessage;
};
