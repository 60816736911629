import classNames from "classnames";
import React from "react";
import ReactIframeResizer from "react-iframe-resizer-super";
import Parser from "html-react-parser";
import Heading from "../../components/helper/Heading";
import LoadingSpinner from "../../components/helper/LoadingSpinner";
import lang, { formatString } from "../../helpers/lang-constants";
import { apiConstants } from "../../constants";

const iframeResizerOptions = { checkOrigin: false };
const language = lang.customer.views.customer_service.index;

function CustomerService() {
  return (
    <div className={classNames("container", "customer-service")}>
      <Heading heading={language.heading_text} />
      <div className="container customer-greeting">
        <p>{language.greeting_text}</p>
        <p>
          <span className="semi-bold">{language.open_times_text}: </span>
          {Parser(
            formatString(
              language.schedule_text,
              `<a href="tel:${language.phone_number.replace(/ /g, "")}">
            ${language.phone_number}
          </a>`
            )
          )}
        </p>
      </div>
      <LoadingSpinner id="iframe" isLoading size={25} />
      <div
        id="iframe"
        onLoad={() => {
          // eslint-disable-next-line no-undef
          document.getElementById("iframe").style.display = "none";
        }}
      >
        <ReactIframeResizer
          id="iframe"
          src={apiConstants.ETRACK_URL}
          style={{ width: "100vw", maxWidth: "100%" }}
          id="frmContact"
          iframeResizerOptions={iframeResizerOptions}
        />
      </div>
    </div>
  );
}

export default CustomerService;
