export default {
  GET_USER_INSURANCES_REQUEST: "GET_USER_INSURANCES_REQUEST",
  GET_USER_INSURANCES_SUCCESS: "GET_USER_INSURANCES_SUCCESS",
  GET_USER_INSURANCES_FAILURE: "GET_USER_INSURANCES_FAILURE",
  GET_ALL_INSURANCES_REQUEST: "GET_ALL_INSURANCES_REQUEST",
  GET_ALL_INSURANCES_SUCCESS: "GET_ALL_INSURANCES_SUCCESS",
  GET_ALL_INSURANCES_FAILURE: "GET_ALL_INSURANCES_FAILURE",
  ADD_CO_INSURED_REQUEST: "ADD_CO_INSURED_REQUEST",
  ADD_CO_INSURED_SUCCESS: "ADD_CO_INSURED_SUCCESS",
  ADD_CO_INSURED_FAILURE: "ADD_CO_INSURED_FAILURE",
  SIGN_NEW_INSURANCE_REQUEST: "SIGN_NEW_INSURANCE_REQUEST",
  SIGN_NEW_INSURANCE_SUCCESS: "SIGN_NEW_INSURANCE_SUCCESS",
  SIGN_NEW_INSURANCE_FAILURE: "SIGN_NEW_INSURANCE_FAILURE",
  TOGGLE_INSURANCE_CARD: "TOGGLE_INSURANCE_CARD",
  ORDER_KEY_TABS_REQUEST: "ORDER_KEY_TABS_REQUEST",
  ORDER_KEY_TABS_SUCCESS: "ORDER_KEY_TABS_SUCCESS",
  ORDER_KEY_TABS_FAILURE: "ORDER_KEY_TABS_FAILURE",
  POST_CLAIM_INSURANCE_REQUEST: "POST_CLAIM_INSURANCE_REQUEST",
  POST_CLAIM_INSURANCE_SUCCESS: "POST_CLAIM_INSURANCE_SUCCESS",
  POST_CLAIM_INSURANCE_FAILURE: "POST_CLAIM_INSURANCE_FAILURE",
  POST_CLAIM_INSURANCE_CLEANUP: "POST_CLAIM_INSURANCE_CLEANUP",
  ADD_CO_INSURED_NORWAY_REQUEST: "ADD_CO_INSURED_NORWAY_REQUEST",
  ADD_CO_INSURED_NORWAY_SUCCESS: "ADD_CO_INSURED_NORWAY_SUCCESS",
  ADD_CO_INSURED_NORWAY_FAILURE: "ADD_CO_INSURED_NORWAY_FAILURE",
  CO_INSURANCE_PRICE_LOOKUP_REQUEST: "CO_INSURANCE_PRICE_LOOKUP_REQUEST",
  CO_INSURANCE_PRICE_LOOKUP_SUCCESS: "CO_INSURANCE_PRICE_LOOKUP_SUCCESS",
  CO_INSURANCE_PRICE_LOOKUP_FAILURE: "CO_INSURANCE_PRICE_LOOKUP_FAILURE",
  VEHICLE_INSURANCE_REQUEST: "VEHICLE_INSURANCE_REQUEST",
  VEHICLE_INSURANCE_SUCCESS: "VEHICLE_INSURANCE_SUCCESS",
  VEHICLE_INSURANCE_FAILURE: "VEHICLE_INSURANCE_FAILURE",
  FILE_UPLOAD_REQUEST: "FILE_UPLOAD_REQUEST",
  FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_REQUEST_SUCCESS",
  FILE_UPLOAD_FAILURE: "FILE_UPLOAD_REQUEST_FAILURE",
  KLARNA_OPEN_BANKING_SESSION_REQUEST: "KLARNA_OPEN_BANKING_SESSION_REQUEST",
  KLARNA_OPEN_BANKING_SESSION_SUCCESS: "KLARNA_OPEN_BANKING_SESSION_SUCCESS",
  KLARNA_OPEN_BANKING_SESSION_FAILURE: "KLARNA_OPEN_BANKING_SESSION_FAILURE",
  KLARNA_OPEN_BANKING_FETCH_RESULTS_REQUEST:
    "KLARNA_OPEN_BANKING_FETCH_RESULTS_REQUEST",
  KLARNA_OPEN_BANKING_FETCH_RESULTS_SUCCESS:
    "KLARNA_OPEN_BANKING_FETCH_RESULTS_SUCCESS",
  KLARNA_OPEN_BANKING_FETCH_RESULTS_FAILURE:
    "KLARNA_OPEN_BANKING_FETCH_RESULTS_FAILURE",
  FETCH_ENDABLE_INSURANCES: "FETCH_ENDABLE_INSURANCES",
  FETCH_ENDABLE_INSURANCES_SUCCESS: "FETCH_ENDABLE_INSURANCES_SUCCESS",
  FETCH_ENDABLE_INSURANCES_FAILURE: "FETCH_ENDABLE_INSURANCES_FAILURE",
  CHECK_ENDABLE_INSURANCES: "CHECK_ENDABLE_INSURANCES",
  CHECK_ENDABLE_INSURANCES_SUCCESS: "CHECK_ENDABLE_INSURANCES_SUCCESS",
  CHECK_ENDABLE_INSURANCES_FAILURE: "CHECK_ENDABLE_INSURANCES_FAILURE",
  FETCH_TERMINATE_REASONS_REQUEST: "FETCH_TERMINATE_REASONS_REQUEST",
  FETCH_TERMINATE_REASONS_SUCCESS: "FETCH_TERMINATE_REASONS_SUCCESS",
  FETCH_TERMINATE_REASONS_FAILURE: "FETCH_TERMINATE_REASONS_FAILURE",
  END_INSURANCES: "END_INSURANCES",
  END_INSURANCES_SUCCESS: "END_INSURANCES_SUCCESS",
  END_INSURANCES_FAILURE: "END_INSURANCES_FAILURE",
};
