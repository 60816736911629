import { apiConstants, policyConstants } from "../constants";
import { policyService } from "../services";

const {
  GET_INSURANCE_POLICY_REQUEST,
  GET_INSURANCE_POLICY_SUCCESS,
  GET_INSURANCE_POLICY_FAILURE,
  ADD_INSURANCE_POLICY_DATA_REQUEST,
  ADD_INSURANCE_POLICY_DATA_SUCCESS,
  ADD_INSURANCE_POLICY_DATA_FAILURE,
} = policyConstants;
const { SUCCESS_CODE_200 } = apiConstants;

const fetchInsurancePolicyRequest = () => ({
  type: GET_INSURANCE_POLICY_REQUEST,
});
const fetchInsurancePolicySuccess = (payload, orderNumber, filename, blob) => ({
  type: GET_INSURANCE_POLICY_SUCCESS,
  payload,
  orderNumber,
  filename,
  blob,
});
const fetchInsurancePolicyFailure = () => ({
  type: GET_INSURANCE_POLICY_FAILURE,
});

const fetchInsurancePolicy = (orderNumber, filename) => (dispatch) => {
  dispatch(fetchInsurancePolicyRequest());
  policyService
    .fetchInsurancePolicy(orderNumber, filename)
    .then((response, payload) => {
      if (response) {
        dispatch(
          fetchInsurancePolicySuccess(response, orderNumber, filename, payload)
        );
      } else {
        dispatch(fetchInsurancePolicyFailure());
      }
    });
};

const addInsurancePolicyDataRequest = () => ({
  type: ADD_INSURANCE_POLICY_DATA_REQUEST,
});
const addInsurancePolicyDataSuccess = (data, orderNumber) => ({
  type: ADD_INSURANCE_POLICY_DATA_SUCCESS,
  data,
  orderNumber,
});
const addInsurancePolicyDataFailure = () => ({
  type: ADD_INSURANCE_POLICY_DATA_FAILURE,
});

const addInsurancePolicyData = (orderNumber) => (dispatch) => {
  dispatch(addInsurancePolicyDataRequest());
  policyService.addInsurancePolicyData(orderNumber).then((response) => {
    if (response.statusCode === SUCCESS_CODE_200) {
      dispatch(addInsurancePolicyDataSuccess(response, orderNumber));
    } else {
      dispatch(addInsurancePolicyDataFailure());
    }
  });
};

const getAllInsurancePolicyData = (orderNumbers) => (dispatch) => {
  orderNumbers.map((item) => {
    dispatch(addInsurancePolicyData(item));
  });
};

export default {
  fetchInsurancePolicy,
  getAllInsurancePolicyData,
};
