import apiConstants from "./api.constants";

export default {
  HOME: "/",
  LOGIN: "/login",
  BANKID_SIGN: "/login/sign",
  BANKID_COLLECT: "/login/collect",
  BANKID_REDIRECT: "/login/redirect",
  INSURANCES: "/minaforsakringar",
  DETAILS: "/minauppgifter",
  CUSTOMER_SERVICE: "/kontakt",
  INVOICES: "/minbetalning",
  CLAIM: "/skadeanmalan",
  BENEFITS: "/minaformaner",
  SHOP: "/teckna",
  SHOPACCEPT: "/teckna/minasidor/accept",
  SHOPACCEPTDIRECTDEBIT: "/teckna/minasidor/accept/direct_debit",
  SHOPACCEPTCARD: "/teckna/minasidor/accept/card",
  SHOPCANCEL: "/teckna/minasidor/cancel",
  SCRIVEACCEPT: `${apiConstants.MY_BASE_URL}/teckna/minasidor/accept/direct_debit`,
  SCRIVECANCEL: `${apiConstants.MY_BASE_URL}/teckna/minasidor/cancel`,
  ROADSIDE_ASSISTANCE: "/vaghjalp",
  PRODUCTS: "/produkter",
  COMPANY: "/foretag",
  TERMS: "/villkor",
  ABOUT_US: "/om-oss",
};

export const externalRoutesConstants = {
  MAGAZINES: `${apiConstants.WP_BASE_URL}/destination/`,
  HOME: apiConstants.WP_BASE_URL,
  EXT_ROADSIDE_ASSISTANCE: `${apiConstants.WP_BASE_URL}/vaghjalp/`,
  EXT_GET_INSURANCE: `${apiConstants.WP_BASE_URL}/teckna-forsakring/`,
  EXT_TERMS: `${apiConstants.WP_BASE_URL}/villkor/`,
  EXT_ABOUT_US: `${apiConstants.WP_BASE_URL}/om-oss/`,
  EXT_PRODUCTS: `${apiConstants.WP_BASE_URL}/produkter/`,
  EXT_CONTACT: `${apiConstants.WP_BASE_URL}/kontakt/`,
};
